@use "sass:math";

.u-object-position-center-2\/3 {
  object-position: 50% percentage(math.div(2, 3));
}

.u-object-position-center-bottom {
  object-position: center bottom;
}

.u-object-position-top-right {
  object-position: right top;
}
