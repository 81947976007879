@use "sass:math";
// Settings
// MODE Front-end

@import '../../base/common';

.service-grid {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  @include mq('lt-md') {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .service-grid__item {
    border-bottom: 1px solid rgba(151, 151, 151, 0.12);

    div {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .c-heading--alt {
        margin-bottom: 0;
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}

#product-list {
  display: grid;
  row-gap: 7.5px;
  column-gap: 7.5px;
  grid-template-columns: 1fr;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 200ms ease-in, transform 300ms ease-in;
  width: 96%;

  &.hide {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 200ms ease-out, transform 300ms ease-out;
  }

  .product-list__title {
    padding-left: 3vw;
    font-size: em(36) !important;
    text-align: left;
    display: flex;
    justify-content: space-between;

    &:focus {
      outline: none;
    }

    >span {
      pointer-events: none;
    }

    .cross {
      height: 38px;
      width: 38px;
      position: relative;
      transform: translateX(-12px); // visually center with arrows

      &::before {
        background: currentColor;
        content: "";
        height: 100%;
        left: calc(50% - 1px);
        position: absolute;
        top: 0;
        width: 2px;
      }

      &::after {
        background: currentColor;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        top: 19px;
        width: 38px;
      }
    }

    &.active {
      .cross:before {
        display: none;
      }
    }
  }

  @include mq('md') {


    .product-list__title {
      grid-column: 1 / span 2;
    }
  }

  .product-list__item {
    padding: $gutter-xxs $gutter-xs;
    border-bottom: 1px solid rgba(151, 151, 151, 0.12);

    h3 {
      line-height: 1.2;
    }
  }

  .product-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    align-items: center;

    img {
      max-width: none;
      object-fit: contain;
      font-family: "object-fit: contain;";
      background-color: black;

      &.lazyloaded {
        opacity: 1 !important;
      }
    }

    h3 {
      line-height: 1.2;
    }

  }

  .product-list__container{
    display:grid;
    grid-template-columns:1fr;
    @include mq('md'){
      grid-template-columns: 1fr 1fr;
    }
  }
}

.tooltip--heading {
  display: inline-block;
  cursor: pointer;

  >span {
    display: inline-block;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(175%, -50%, 0) rotate(0deg);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $color-brand-gold;
    }

    &.is-active {
      &:after {
        content: '';
        transform: translate3d(175%, -50%, 0) rotate(180deg);
      }
    }
  }
}

.tooltip {
  display: none;
  top: auto;
  border-radius: 10px;
  transform: translate3d(-50%, 105%, 0);
  z-index: 9;
  box-shadow: 1px 1px 26px rgba(0, 0, 0, 0.5);

  .c-copy--md {
    padding: 10px;

    @include mq('md') {
      padding: 10px 5px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid;
    }
  }

  .c-heading--md-alt {
    font-size: em(10);
    letter-spacing: 2.54px;
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
  }



  &.is-active {
    display: block;
  }
}

.c-heading--alt {
  font-size: em(41);
  letter-spacing: 2px;
}

.c-heading--md-alt {
  font-size: em(16);
  letter-spacing: 2.54px;
}

.servicecase__alert {
  z-index: 9;
  position: relative;
  border-radius: 10px;

  @include mq('lt-md') {
    margin-top: -20%;
  }
}

.c-servicecase_detail {
  @include mq('lt-md') {
    padding-right: 0;
    padding-left: 0;

  }

}



.c-servicecase_wrap {
  @include mq('lt-md') {
    padding-right: $gutter-xs;
    padding-left: $gutter-xs;
  }
}

.c-copy--alt {
  letter-spacing: 2.25px;
}

.slice-guide {
  border-top: solid 1px rgba($color-gray-14, 0.12);
}

.servicecase__back {
  position: fixed;
  bottom: auto;
  top: $gutter-md;

  @include mq('md') {
    top: $gutter-xxl;
  }

  left: $gutter-xs;
  right: auto;
  z-index: 99;
  display: block;
  border-radius: 10px;
}

.servicecase__tips {
  position: fixed;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  z-index: 99;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  background-color: $color-brand-gold;

  &.js-accordion__container {
    transform: translate(0, 90%);
    transition: transform 150ms ease-in;

    &.open {
      transform: translate(0, 0);
      transition: transform 150ms ease-out;
    }
  }

  &.slice-guide {
    transform: translate(0, 90%);
    transition: transform 150ms ease-in;

    @include mq('md') {
      transform: translate(0, 90%);
    }

    .js-accordion__item {
      max-width: 425px;
      margin: 0 auto;
      background-color: $color-black-1;
    }
  }

  .accordion-title {
    position: relative;
    letter-spacing: tracking(10);
    padding-bottom: 5px;

    .pattern {
      background-image: url(/img/servicecase/card_pattern.png);
      background-repeat: repeat;
    }
  }

  .order_number {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 74px;
    opacity: 0.44;
    transform: translate3d(-71%, -50%, 0);
    display: block;
    color: #DEC88D;

    &:after {
      content: '';
      height: 1.5px;
      width: 22px;
      background-color: #DEC88D;
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      transform: translate(-6px, 0);
    }
  }
}

.c-servicecase__add-on {
  opacity: 1 !important;
  display: flex !important;
  position: fixed;
  left: 0;
  background: #161614;
  top: 0;



  ._service-bar__quality {
    position: relative;
    width: 100%;
    display: block;
    background: #8a7449;
    z-index: 1001;
    height: auto;
    width: 100%;

    .u-display-grid {
      display: grid;
      grid-template-columns: 2fr 7fr;
      grid-template-rows: 1fr;
      align-items: center;

      @include mq('md') {
        grid-template-columns: 1fr 6fr;
      }

      .c-heading {
        @include mq('md') {
          white-space: nowrap;
        }

        padding-right: 20px;
        border-right: 1.5px solid #DEC88D;
      }

      .c-subheading--sm {
        margin-left: 20px;
      }
    }
  }

  ._service-bar__container {
    // Fix scrolling
    height: 0;
    visibility: hidden;
    width: 100vw;
    max-width: 100vw;
  }

  ._servicecase-bar__dropdown {
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    button {
      background-color: $color-black-1;
      border-radius: 10px;
      border: 2px solid transparent;

      >span {
        pointer-events: none;
      }

      &.selected {
        border: 2px solid $color-brand-gold;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// Temporary
[data-filter]:disabled {
  opacity: 0.5;
  pointer-events: none;
}


.servicecase-landing {
  padding-top: 8.5em;
}

.c-health-attributes {
  &.c-servicecase_wrap {

    display: grid;

    grid-column: 1 / span 2;
    grid-template-rows: 1fr;
    grid-template-columns:none;
    @include mq('lt-md') {
      grid-column: 1;
      grid-template-columns:1fr;
      grid-row-gap: 20px;
      grid-template-rows:none;
    }

    .c-health-attribute {
      @include mq('md') {
      grid-row:1;
      }
      max-width: none;
      margin: 0 $gutter-xxs $gutter-xxs 0;

    }
  }

  .c-health-attribute {

    &.c-health-attribute-feingold-food-list,
    &.c-health-attribute-no-caramel-color,
    &.c-health-attribute-no-msg {
      display: none;
    }
  }
}


.service-case--grid {
  .c-product-detail__content {

    grid-template-columns: 3fr 2fr;
    display: grid;

    justify-content: center;
    align-items: center;

    @include mq('lt-md') {
      padding:30px 0;
      grid-template-columns: 1fr;
    }
  }
}
