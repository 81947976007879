.o-section {
  overflow: visible;
  position: relative;
}

// .o-section__wrapper {
// }

.o-section__content {
  // // Mobile
  @include mq('lt-md') {
    padding-right: $gutter-xs;
    padding-left: $gutter-xs;
  }

  // Desktop
  @include mq('md') {
    padding-right: $gutter-md;
    padding-left: $gutter-md;
  }
}

.o-section__content\@1040 {

  @include mq('1040') {
    padding-right: $gutter-md;
    padding-left: $gutter-md;
  }
}

// Large Desktop
$bp-content-max-width: $content-max-width + ($gutter-md * 2);
@media (min-width: $bp-content-max-width) {
  // Use padding to set content `max-width`
  .o-section__content {
    padding-right: calc((100% - #{$content-max-width}) / 2);
    padding-left: calc((100% - #{$content-max-width}) / 2);
  }
  .o-section__content--full {
    padding-right: $gutter-md;
    padding-left: $gutter-md;
  }
}

.background-wrap{
  background-size:cover;
  background-repeat:no-repeat;
  background-position:bottom center;
}

// ------------------------------
// Modifiers
// ------------------------------

.o-section__content--full {
  @include mq('lt-md') {
    padding-right: 0;
    padding-left: 0;
  }
}

.o-section__content--right\@md {
  @include mq('md') {
    padding-left: 0;
  }
  @include mq('1500') {
    padding-left: 0;
  }
}

.o-section__content--left\@md {
  @include mq('md') {
    padding-right: 0;
  }
  @include mq('1500') {
    padding-right: 0;
  }
}
