@use "sass:math";

.c-product-grid {
  display: grid;
  column-gap: $spacing-element-horizontal-min;
  row-gap: $spacing-element-horizontal-min;

  column-gap: scale-vw-mobile($spacing-element-horizontal-min * .8);
  row-gap: scale-vw-mobile($spacing-element-vertical-min * .8);

  @include mq('xl') {
    column-gap: scale-1200($spacing-element-horizontal-min);
    row-gap: scale-1200($spacing-element-vertical-min);
  }
}


.c-product-grid--alt {
  counter-reset: recipe-counter 1;
  justify-content: center;
}

.c-product-grid__item {
  // display: flex; // Note: This breaks IE11
  position: relative;
  // margin-bottom: em(30);
  padding-bottom: scale-vw-mobile($spacing-element-vertical-min * .5);

  @include mq('xl') {
    padding-bottom: scale-1200($spacing-element-vertical-min * .45);
  }

  .c-product-grid--alt & {
    counter-increment: recipe-counter;

    &::after {
      content: none;
    }
  }
}

.c-product-grid__link {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
  color: var(--product-grid-link);

  &.c-link {
    color: var(--product-grid-link);
  }

  @include hover {
    color: $color-gold-3;
  }

  // TODO - Need to adjust the display of this at some point
  .c-video-badge {
    display: none;
  }

  .c-product-grid__figure {
    &::after {
      background: var(--product-grid-link-hover-bg-lt);
    }
  }

  &:not(.c-product-grid__link--no-label) {
    .c-product-grid__figure {
      &::after {
        background: var(--product-grid-link-hover-bg);
      }
    }
  }

  &.c-product-grid__link--old-label {
    .c-product-label {
      left: 0;
      width: 100%;
      transform: translate3d(0, -2%, 0);
    }

    &.c-link:hover .c-link__figure {
      opacity: 1;

      .c-product-label {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .c-product-label {
    object-fit: contain;
    width: 65%;
    left: 50%;
    transform: translate3d(-50%, -2%, 0) !important;
  }

  &.c-link:hover .c-link__figure {
    opacity: 1;

    .c-product-label {
      transform: translate3d(-50%, 0, 0);
    }

    &::after {
      opacity: 1;
    }
  }
}

.c-product-grid__figure {
  width: 100%;

  margin-bottom: 24px;
  @include mq('xl') {
    margin-bottom: scale-1200(24px);
  }
}

.c-product-grid__caption {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @include body--sm();

  // @include body--md();

  // @include mq('xl') {

  // }

  padding: 0 30px;

  @include mq('lg') {
    padding: 0;
  }

  > p {
    display: block;
    width: 100%;
    text-align: center;
  }

  .c-product-grid--alt & {
    display: block;

    > .c-heading {

      &::before {
        @include reset-small-caps;
        content: counter(recipe-counter, decimal-leading-zero);
        display: block;
        color: $color-gold-1;
        font-style: italic;
      }
    }
  }
}


// Featured Badge
// Note: Uses `.u-small-caps`
.c-badge {
  $size: em(94, 18);
  @include circle($size);
  @include font-button;
  display: inline-flex;
  position: absolute;
  top: em(14);
  left: em(14);
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  color: $color-brand-gold;
  text-decoration: none;
  z-index: 10;
  pointer-events: none;

  &::after {
    background: rgba($color-black-1, 0.7);
    z-index: -1;
  }

  // HACK: Shrink badge when product card gets cramped
  @media (min-width: map-get($breakpoints, '360')) and (max-width: em(510, 16)) {
    top: 5%;
    left: 5%;
    transform: scale(0.75);
    transform-origin: 0 0;
  }
}

.c-product-grid__product-flag {
  position: absolute;
  top: 0px;
  right:0;
  width: 43px;
  height:43px;
  background-color: transparent;
  color: $color-brand-gold;
  z-index: 2;
  border-radius:50%;
  border: 1.25px solid $color-brand-gold;
  text-align: center;

  @include cta--lg();
  font-size: 9px;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mq('lg'){
    top: scale-1200(11px);
    right: scale-1200(11px);
  }

  &--lto {
    width: 65px;
    height: 65px;
    padding: 2px;
  }
}
