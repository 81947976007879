.c-bold-teaser {
  .c-bold-teaser__figure {
    &::after { // for firefox per our story styles
      content: '';
      display: block;
      padding: 0 0 80%;
      height: 0;

      @include mq('lt-lg') {
        display: none;
      }
    }

    > img {
      width: 130%;
      max-width: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: -1;
    }
  }

  .c-bold-teaser__figure-img {
    &--lg {
      display: none;
    }

    @include mq('lt-lg') {
      &--full { display: none; }
      &--lg { display: block; }
    }
  }

  .c-bold-teaser__figure-img--lg {
    // overrride overly-specific selector
    width: 100% !important;
    position: static !important;
    transform: none !important;
  }

  .c-bold-teaser__carousel-header {
    position: relative;
    z-index: 1;  // bring above carousel
  }

  $carousel-breakpoint: 'lt-md';
  $carousel-offset: 60px;

  .c-bold-teaser__carousel {
    $background-start: #000;
    $background-end: rgba($background-start, 0);

    margin-top: -50px;//-$carousel-offset;

    &::before,
    &::after {
      content: '';
      width: 25%;
      position: absolute;
      top: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 1;

      @include mq($carousel-breakpoint) { width: 15%; }
      @include mq('lt-sm')              { width: 10%; }
    }

    &::before {
      background: linear-gradient(to right, $background-start, $background-end);
      left: 0;
    }

    &::after {
      background: linear-gradient(to left, $background-start, $background-end);
      right: 0;
    }

    .flickity-prev-next-button {
      $offset: 25%;

      $size: 32px;
      width: $size;
      height: $size;
      background: center / $size no-repeat;
      opacity: 0.75;
      position: absolute;
      top: 50%;
      z-index: 3;
      transform: translate3d(0, -50%, 0);
      transition: opacity 125ms ease-in-out;

      @include mq('lt-sm') {
        top: 100%;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }

      svg {
        display: none;  // hide default arrows
      }

      &.previous {
        background-image: url('/img/journeyboldly/teaser/carousel/arrow-left@2x.png');
        left: $offset;
      }

      &.next {
        right: $offset;
        background-image: url('/img/journeyboldly/teaser/carousel/arrow-right@2x.png');
      }

      // @include mq($carousel-breakpoint) {
      //   $offset--sm: 10%;
      //   &.previous { left: $offset--sm; }
      //   &.next     { right: $offset--sm; }
      // }

      @media (max-width: 920px) {
        $offset: 20%;
        &.previous { left: $offset; }
        &.next     { right: $offset; }
      }

      //@media (max-width: $carousel-breakpoint) {
      @include mq($carousel-breakpoint) {
        $offset: 10%;
        &.previous { left: $offset; }
        &.next     { right: $offset; }
      }

      @media (max-width: 580px) {
        $offset: 10px;
        &.previous { left: $offset; }
        &.next     { right: $offset; }
      }
    }

  .c-product-label {
      max-width: 380px;
      height: auto;
      top: 115px;//$carousel-offset;
      right: 0;
      left: auto;
      opacity: 1;
      transform: none;

      @include mq('1500') {
        top: 200px;
        right: 40px;
      }

      @include mq('lt-870') {
        top: 0;
        right: 40px;
      }

      @include mq('lt-md') {
        top: 115px;
        right: 40px;
      }
    }
  }

  .c-bold-teaser__carousel-item {
    width: 50%;

    @include mq('lt-md') {
      width: 100%;
    }

    .c-link__img {
      width: 80%;
      height: 80%;
      left: 12%;
      bottom: 0;
      top: auto;
    }
  }

  .c-bold-teaser__carousel-content {
    padding: 0 0 10px;  // account for `overflow: hidden` masking part of `display: inline-flex` button
    position: relative;
    z-index: 1;  // bring above gradient overlays
  }

  .c-heading--lg {
    @include mq('lt-1240') {
      font-size: em(39.75);
    }

    @include mq('lt-870') {
     font-size: em(20);
    }
  }

  .c-heading {
    @include mq('lt-870') {
      font-size: em(28);
    }
  }

  .c-button--play {
    margin-bottom: 20px;
    @include mq('lt-870') {
      .c-button--play {
        transform: scale(0.8);
        margin-bottom: 10px;
      }
    }
  }

  .c-button--play {
    min-height: 60px;
  }
}

.c-learn-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  > span:first-child {
    padding-bottom: 17px;
  }
}
