@use "sass:math";

.c-hero {
  position: relative;
  background: center / cover no-repeat;
  overflow: hidden;

  // // Crop heroes on tall-enough devices
  // @media (min-height: em(600)) {
  //   max-height: calc(100vh - #{$header-total-height-md});
  // }

  .c-button--box:focus, .c-button--box:active {
    outline: none;
  }
  .c-button--box:focus-within::after, .c-button--box:active::after {
    content: "-";
    display: none;
  }
}

// TODO: Merge with `.o-preserve-aspect-ratio`
.c-hero__preserve-aspect-ratio {
  width: 100%;
  height: 0;
}

.c-hero__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);

  &--brown-sugar{
    top:auto;
    bottom:0;
    transform: translate(-50%, 0);
    @include mq('lt-md'){
    max-width:none;
    }
  }
}

.c-hero__content {
  position: relative;
  z-index:99;
  padding: ($hero-margin + em(50)) $gutter-sm;
  color: $color-gray-4;
  z-index: 20;

  // HACK: Override default heading colors so headings are more legible
  // scss-lint:disable ImportantRule
  .c-hero__subheading,
  .c-hero__heading {
    color: #fff !important;
  }
  // scss-lint:enable ImportantRule
  &--brown-sugar{
    position:absolute;
    top: 0;
    transform:translateY(0);
  }

}

.is-active{
  .c-hero__content{
    z-index:0;
  }
}

.c-hero__scroll-button {
  position: absolute;
  bottom: -1px; // fix for android phones
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;

  // HACK: When videos are not active, place button above iframe
  body:not(.has-video-playing) & {
    z-index: 60;
  }
}

// For Health views (e.g., AHA "This sandwich contains...")
.c-hero__disclaimer {
  width: 100%;
  margin-top: $gutter-xs;
  text-align: right;

  @include mq('lt-md') {
    padding-right: $gutter-xs;
  }
}



// ------------------------------
// Mobile
// ------------------------------

@include mq('lt-md') {
  .c-hero__preserve-aspect-ratio {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw * math.div($hero-width, $hero-height);
    height: 100%;
    transform: translateX(-50%);
  }
}



// ------------------------------
// Desktop
// ------------------------------

@include mq('md') {
  .c-hero__preserve-aspect-ratio {
    padding: 0 0 percentage(math.div($hero-height, $hero-width));
  }

  .c-hero__content {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index:99;
    padding-top: ($hero-margin + $gutter-sm);
    padding-bottom: ($hero-margin + $gutter-sm);
    transform: translateY(-50%);

    &.c-hero__content--bottom-align {
      top: 70%;
    }

    &--brown-sugar{
      top: 0;
      transform:translateY(0);
    }
  }
  .is-active{
    .c-hero__content{
      z-index:0;
    }
  }
}



// ------------------------------
// Themes
// ------------------------------

// Red Outline
@mixin c-hero--red-outline($at-breakpoint: null) {
  @include breakpoints('c-hero--red-outline', $at-breakpoint) {
    &::after {
      position: absolute;
      top: $hero-margin;
      right: $hero-margin;
      bottom: $hero-margin;
      left: $hero-margin;
      border: $border-width-md solid $color-brand-red;
      content: '';
      z-index: 10;
      pointer-events: none;
    }
  }
}

@include c-hero--red-outline();
@include c-hero--red-outline('md');


// Pink Outline
@mixin c-hero--pink-outline($at-breakpoint: null) {
  @include breakpoints('c-hero--pink-outline', $at-breakpoint) {
    &::after {
      position: absolute;
      top: $hero-margin;
      right: $hero-margin;
      bottom: $hero-margin;
      left: $hero-margin;
      border: $border-width-md solid $color-pink-1;
      content: '';
      z-index: 10;
      pointer-events: none;
    }
  }
}

@include c-hero--pink-outline();
@include c-hero--pink-outline('md');


// ------------------------------
// TODO: Clean up these temporary hero styles
// ------------------------------

.c-hero__logo {
  width: 78px;
  height: 53px;
}

.c-hero__header {
  margin-bottom: $gutter-xs;
  // HACK: Disable clicks on plain text, but allow clickthrough for anchors and buttons
  // E.g., `.c-hero__header-button` is assigned `z-index: -1;` to avoid its shadow overlapping the heading
  // but that pushes it behind the header, making it unclickable on tablet (at least—haven't tested elsewhere).
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }
}

// HACK: Keep buttons behind headings so their shadow doesn't overlap
.c-hero__header-button {
  z-index: -1;
}

.c-hero__header--push-up {
  @include mq('md') {
    position: absolute;
    top: 50%;
    left: percentage(math.div(1, 2));
    width: 100%;
    padding: ($hero-margin + em(50)) $gutter-sm;
    transform: translate(-50%, -50%);
  }
  @include mq('900') {
    top: percentage(math.div(1, 3));
  }
}

.c-hero__header--push-down {
  @include mq('md') {
    position: absolute;
    top: 50%;
    left: percentage(math.div(1, 2));
    width: 100%;
    padding: ($hero-margin + em(50)) $gutter-sm;
    transform: translate(-50%, -33.333%);
  }
  @include mq('900') {
    top: percentage(math.div(2, 3));
  }
}

.c-hero__header--tint {
  &::before {
    $color: #0d0806;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 200%;
    transform: translate(-50%, -50%);
    background: radial-gradient(
      ellipse at center,
      #{rgba($color, 1)} 0%,
      #{rgba($color, 0)} 100%
      );
    content: '';
    opacity: 0.5;
    z-index: -1;
    pointer-events: none;
  }
}

.c-hero__heading {
  margin-top: em(6, 44);
  margin-bottom: em(10, 44);
}

// TODO: Merge with generic typography rules
.c-hero__subheading {
  font-size: em(15);
  letter-spacing: tracking(200);
  text-transform: uppercase;

  .c-hero__subheading-pipe {
    color: #524e4a;
  }

  text-shadow: -1px -1px 13px rgba(150, 150, 150, 1);
}



.c-hero__banner {
  position: relative;
  z-index: 11;
  max-width: 535px;
  width: 60%;
  top: 15px;
  left: percentage(math.div(-40,1107));

  @include mq('md') {
    position: absolute;
    top: em(60);
    width: percentage(math.div(536,1107));
    left: 0.875em;
    transform: translateX(percentage(math.div(-54,1895)));
  }

  @media(min-width: 1200px) {
    margin-left: 0;
  }
}
