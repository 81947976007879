@use "sass:math";

// MODE Front-end
@import '../../../../../node_modules/mode-front-end/resources/assets/sass/common';

// Settings
@import '../settings/animations';
@import '../settings/colors';
@import '../settings/gutters';
@import '../settings/responsive';
@import '../settings/typography';
@import '../settings/mixins';

@import '../mixins/scaling';
@import '../mixins/containers';
@import '../mixins/typography';
@import '../mixins/circle';
@import '../mixins/object-fit';
@import '../mixins/full-area-cta';
@import '../mixins/body';

// Import caching functions
@import '../cache/functions';

// TODO: Move dimension values to separate settings partial

// Header - Mobile
$header-height-sm: em(72);
$header-logo-width-sm: em(151);
$logo-height-sm: em(39.5);

// Header - Desktop
$header-height-md: em(86);
$header-logo-width-md: em(202);
$header-logo-height-md: em(54);
$header-margin-bottom-md: em(27);
$header-padding-top-md: $header-height-md - $header-logo-height-md;
$header-total-height-md: $header-height-md + $header-margin-bottom-md + $gutter-sm;
$nav-secondary-max-width-md: 24em;

// Hero
$hero-width  : 1104;
$hero-height : 662;
$hero-margin : em(14);

// Border
$border-width-sm: 3px;
$border-width-md: 4px;
