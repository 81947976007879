[data-promo-id="counterculture"] {
  .c-modal__content-container {
    max-width: 375px;
    height: 525px;
  }

  .c-icon--close  {
    svg {
      fill: #fff;
    }
  }

  .c-hero__logo {
    width: 305px;
    height: 120px;
    display: block;
    margin: 126px 0 60px 0;
    flex-shrink: 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -26px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 50px;
      height: 2px;
      background: $color-brand-gold;
    }
  }

  .c-stamp {
    width: 111px;
    height: 115px;
    transform: translate3d(-20px, -33%, 0px) rotate(17deg);
    opacity: .2;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .c-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('/img/counterculture/modal-bg.jpg') no-repeat;
  }

  p {
    padding: 0 40px;
    text-align: center;

    font-size: 16px;
    line-height: 1.5;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #fff;
    margin: 0 0 40px 0;
    width: 100%;
  }

  .btn--gold {
    display: inline-flex;
    align-items: center;

    font-family:bureau-grot-wide, sans-serif;
    text-transform: uppercase;
    border: solid 2px $color-brand-gold;
    padding: 17px 39px 16px 39px;
    text-decoration: none;
    font-size: em(12);
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;

    margin-bottom: 56px;
    z-index: 1;


    &:after{
      height: 500%;
    }
  }
}

// @import '../counterculture/motion/index';


[data-promo-id="pitcraft-modal"] {
  .c-modal__content{
    height:380px;
    @include mq('md'){
      height:500px;
    }
    .c-button--box{
      transform: translate(40%, -115%);
      @include mq('md'){
        transform:translate(40%, -160%);
      }
    }
  }
}

.ticket-link {
  &:focus {

    .ticket-wrap {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
      }
    }
  }
}
