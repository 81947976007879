@include o-media;
@include o-media__figure();
@include o-media__body();


// TODO: Refactor. Currently only used for Bold promo in `03-product-detail.mustache`
.o-media--gutter-sm {
  > .o-media__figure,
  > .o-media__body {
    &:first-child {
      margin-right: 32px;
    }
  }
}
.o-media--top\@lt-620 {
  align-items: flex-start;
}
.o-media--wrap\@lt-620 {
  @include mq('lt-620') {
    flex-wrap: wrap;

    > .o-media__figure,
    > .o-media__body {
      &:first-child {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}

