@charset "UTF-8";
/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
@keyframes come-in {
  to {
    opacity: 1;
  }
}
@keyframes come-in-scale {
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
@keyframes slight-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}
/*! Flickity v1.2.1
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.js-sticky::before,
.js-sticky::after {
  display: table;
  content: "";
}

.o-background {
  background: center/cover no-repeat;
}

.o-carousel .flickity-page-dots {
  display: flex;
  justify-content: flex-end;
  margin: 1.25em 0 0;
  height: 12px;
}
.o-carousel .flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  margin: 0 0.3125em;
  transform: rotate(45deg);
  border-radius: 0;
  background: #AB965D;
  cursor: pointer;
  opacity: 0.5;
}
.o-carousel .flickity-page-dots .dot:hover, .o-carousel .flickity-page-dots .dot:focus, .o-carousel .flickity-page-dots .dot:active {
  opacity: 0.75;
}
.o-carousel .flickity-page-dots .dot.is-selected {
  border: 2px #AB965D solid;
  background: none;
  cursor: default;
  opacity: 1;
}

.o-carousel > figure:not(:first-child) {
  position: absolute;
  left: -999em;
}

.o-center-block {
  margin-right: auto;
  margin-left: auto;
}

@media all and (max-width: 56.1875em) {
  .o-center-block\@lt-900 {
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 48em) {
  .o-center-block\@lt-md {
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 38.6875em) {
  .o-center-block\@lt-620 {
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 29.9375em) {
  .o-center-block\@lt-sm {
    margin-right: auto;
    margin-left: auto;
  }
}

.o-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.o-circle--xs {
  -webkit-transform-style: preserve-3d;
  width: 30px;
  height: 30px;
}
.o-circle--xs > * {
  -webkit-backface-visibility: hidden;
}
.o-circle--xs::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 200ms ease;
  border: 2px solid rgba(171, 150, 93, 0.5);
  border-radius: 50%;
  content: "";
  pointer-events: none;
  width: 30px;
  height: 30px;
}
.o-circle--xs:hover::after, .o-circle--xs:focus::after, .o-circle--xs:active::after {
  background: rgba(195, 170, 134, 0.1);
}
.o-circle--xs.is-active::after {
  background: rgba(195, 170, 134, 0.1);
}

.o-circle--sm {
  -webkit-transform-style: preserve-3d;
  width: 60px;
  height: 60px;
}
.o-circle--sm > * {
  -webkit-backface-visibility: hidden;
}
.o-circle--sm::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 200ms ease;
  border: 2px solid rgba(171, 150, 93, 0.5);
  border-radius: 50%;
  content: "";
  pointer-events: none;
  width: 60px;
  height: 60px;
}
.o-circle--sm:hover::after, .o-circle--sm:focus::after, .o-circle--sm:active::after {
  background: rgba(195, 170, 134, 0.1);
}
.o-circle--sm.is-active::after {
  background: rgba(195, 170, 134, 0.1);
}

.o-darken__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}
.o-darken__overlay::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  content: "";
  opacity: 0.15;
}
.o-darken__overlay::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(ellipse at center, black 0%, rgba(0, 0, 0, 0) 33.3333333333%);
  content: "";
  opacity: 0.4;
}
.o-darken__overlay.o-darken__overlay--darker::before {
  opacity: 0.4;
}

.o-darken__button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 266.67%;
  height: 266.67%;
  transform: translate(-50%, -50%);
  background: radial-gradient(ellipse at center, #0d0806 0%, rgba(13, 8, 6, 0) 50%);
  content: "";
  opacity: 0.2;
  z-index: -1;
  pointer-events: none;
}

@media all and (min-width: 48.0625em) {
  .o-darken\@lt-md .o-darken__overlay {
    display: none;
  }
}

.o-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

@media all and (min-width: 48.0625em) {
  .o-grid\@md {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
@media all and (min-width: 65em) {
  .o-grid\@1040 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
@media all and (min-width: 64em) {
  .o-grid\@1024 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
.o-grid__item {
  display: block;
  position: relative;
}

.o-grid--gutters-xxs {
  margin-right: 0;
  margin-left: -0.625em;
}
.o-grid--gutters-xxs > .o-grid__item {
  padding-right: 0;
  padding-left: 0.625em;
}

.o-grid--gutters-xs {
  margin-right: 0;
  margin-left: -1.25em;
}
.o-grid--gutters-xs > .o-grid__item {
  padding-right: 0;
  padding-left: 1.25em;
}

.o-grid--gutters-sm {
  margin-right: 0;
  margin-left: -1.875em;
}
.o-grid--gutters-sm > .o-grid__item {
  padding-right: 0;
  padding-left: 1.875em;
}

.o-grid--gutters-md {
  margin-right: 0;
  margin-left: -3em;
}
.o-grid--gutters-md > .o-grid__item {
  padding-right: 0;
  padding-left: 3em;
}

.o-grid--gutters-lg {
  margin-right: 0;
  margin-left: -4em;
}
.o-grid--gutters-lg > .o-grid__item {
  padding-right: 0;
  padding-left: 4em;
}

.o-grid--gutters-xl {
  margin-right: 0;
  margin-left: -5em;
}
.o-grid--gutters-xl > .o-grid__item {
  padding-right: 0;
  padding-left: 5em;
}

@media all and (min-width: 48.0625em) {
  .o-grid--gutters-sm\@md {
    margin-right: 0;
    margin-left: -1.875em;
  }
  .o-grid--gutters-sm\@md > .o-grid__item {
    padding-right: 0;
    padding-left: 1.875em;
  }
}
.o-grid--gutters-0 {
  margin-right: 0;
  margin-left: 0;
}
.o-grid--gutters-0 > .o-grid__item {
  padding-right: 0;
  padding-left: 0;
}

.o-grid--center {
  justify-content: center;
}

/**
 * Reverse grid.
 * @param  {String}  $at-breakpoint
 */
.o-grid--reverse {
  flex-direction: row-reverse;
}

@media all and (min-width: 30em) {
  .o-grid--reverse\@sm {
    flex-direction: row-reverse;
  }
}
/**
 * Grid with inner borders (and vertical).
 * Based on http://codepen.io/dalgard/pen/Dbnus?editors=0100
 *
 * Note: Requires an extra div with overflow hidden to hide the right borders:
 * ```
 * <div class="u-padding-horizontal-md">
 *     <div class="o-section  /  u-overflow-hidden">
 *         <div class="o-grid  o-grid--inner-border">
 *             ...
 *         </div>
 *     </div>
 * </div>
 * ```
 */
.o-grid--inner-border {
  margin: 0 -4px 0 0;
  border-top: 4px solid #A6192E;
}
.o-grid--inner-border > .o-grid__item {
  border-right: 4px solid #A6192E;
  border-bottom: 4px solid #A6192E;
}
@media all and (max-width: 48em) {
  .o-grid--inner-border {
    border-width: 3px;
  }
  .o-grid--inner-border > .o-grid__item {
    border-width: 3px;
  }
}

.o-grid--inner-border--sm {
  padding: 0;
  border: 1px #A6192E solid;
  border-width: 1px 0;
}
.o-grid--inner-border--sm > .o-grid__item {
  border-top: 1px #A6192E solid;
}
.o-grid--inner-border--sm > .o-grid__item:first-child {
  border-top: 0;
}
@media all and (min-width: 38.75em) {
  .o-grid--inner-border--sm > .o-grid__item {
    border-top: 0;
  }
  .o-grid--inner-border--sm > .o-grid__item + .o-grid__item {
    border-left: 1px #A6192E solid;
  }
  .o-grid--inner-border--sm > .o-grid__item:first-child {
    padding-left: 0;
  }
  .o-grid--inner-border--sm > .o-grid__item:last-child {
    padding-right: 0;
  }
}

.gold-grid {
  background-color: rgba(138, 116, 73, 0.1);
}
.gold-grid svg {
  height: 67px;
}
.gold-grid.u-width-1\/1 {
  margin: 0.5% 0;
}
@media all and (min-width: 30em) {
  .gold-grid.u-width-1\/2\@sm {
    width: 48%;
    margin: 0.5%;
  }
}
@media all and (min-width: 48.0625em) {
  .gold-grid.u-width-1\/3\@md {
    width: 30.5%;
    margin: 0.5%;
  }
}
@media all and (min-width: 61.25em) {
  .gold-grid.u-width-1\/4\@lg {
    width: 22.75%;
    margin: 0.5%;
  }
}

.o-lazyload__placeholder {
  transform: scale(1.05);
  filter: blur(5px);
}

.o-lazyload__image {
  transition: opacity 400ms ease-out;
}
.o-lazyload__image.lazyload {
  opacity: 0;
}
.o-lazyload__image.lazyloaded {
  opacity: 1;
}
.o-lazyload__image--no-transition {
  transition: none;
}

.o-list {
  padding-left: 0;
  list-style: none;
}

.o-list__item {
  break-inside: avoid;
}

.o-list__item {
  break-inside: avoid;
}

.o-list--bullets > .o-list__item {
  position: relative;
  margin-left: 15px;
}
.o-list--bullets > .o-list__item::before {
  position: absolute;
  top: -2px;
  left: -15px;
  content: "• ";
}

.o-list--bullets-alt {
  padding-left: 1.125em;
  list-style: disc;
}
.o-list--bullets-alt > li {
  padding-bottom: 0.375em;
}

.o-list--numbers > .o-list__item {
  counter-increment: list-numbers;
}
.o-list--numbers .o-list__counter::before {
  display: inline-block;
  content: counter(list-numbers) ". ";
}

.o-list--numbers-roman > .o-list__item {
  margin-left: 1.25em;
  counter-increment: list-numbers;
}
.o-list--numbers-roman > .o-list__item::before {
  display: inline-block;
  margin-right: 0.3125em;
  content: counter(list-numbers, lower-roman) ". ";
}
.o-list--numbers-roman > .o-list__item ol > .o-list__item {
  counter-increment: list-numbers-2;
  margin-left: 1.25em;
}
.o-list--numbers-roman > .o-list__item ol > .o-list__item::before {
  display: inline-block;
  content: counter(list-numbers-2, lower-alpha) ". ";
  margin-right: 0.3125em;
}

.o-list--leading-zero > .o-list__item {
  counter-increment: list-numbers-zero-pad;
}
.o-list--leading-zero .o-list__counter::before {
  display: inline-block;
  content: counter(list-numbers-zero-pad, decimal-leading-zero);
}
.o-list--leading-zero .o-list__item--instructions {
  position: relative;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  margin-bottom: clamp(1.7708333333rem, 2.3611111111vw, 2.4375rem);
}
@media all and (min-width: 48.0625em) {
  .o-list--leading-zero .o-list__item--instructions {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .o-list--leading-zero .o-list__item--instructions {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.o-list--leading-zero .o-list__item--instructions .o-list__counter {
  display: grid;
  grid-template-columns: clamp(2.125rem, 2.6388888889vw, 2.75rem) 1fr;
}
.o-list--leading-zero .o-list__item--instructions .o-list__counter::before {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  line-height: 1.8;
  color: #AB965D;
}
@media all and (min-width: 48.0625em) {
  .o-list--leading-zero .o-list__item--instructions .o-list__counter::before {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .o-list--leading-zero .o-list__item--instructions .o-list__counter::before {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}

.o-list--inline > .o-list__item {
  display: inline-block;
}

.o-list--commas > .o-list__item {
  display: inline-block;
}
.o-list--commas > .o-list__item:not(:last-child)::after {
  margin-left: -2px;
  content: ", ";
}

.o-list--outline .o-list__content {
  padding-left: 1em;
}
.o-list--outline .o-list__counter {
  display: table;
}
.o-list--outline .o-list__counter::before {
  display: table-cell;
  width: 1em;
}

.o-list--borders > .o-list__item {
  border-bottom: 1px solid #211f1c;
}
.o-list--borders > .o-list__item:nth-last-of-type(1), .o-list--borders > .o-list__item:nth-last-of-type(2) {
  border-bottom: none;
}
.o-list--borders > .o-list__item:nth-of-type(even) .o-list__item-inner {
  border-left: 1px solid #211f1c;
}

._list-with-more-vertical-space .o-list__item {
  margin-bottom: 0.25em;
}
._list-with-more-vertical-space a {
  display: inline-block;
  line-height: 1.15;
}

.o-media {
  display: flex;
  align-items: flex-start;
}

.o-media__figure {
  flex: 0 1 auto;
}

.o-media__body {
  flex: 1 1 auto;
}

.o-media--gutter-sm > .o-media__figure:first-child,
.o-media--gutter-sm > .o-media__body:first-child {
  margin-right: 32px;
}

.o-media--top\@lt-620 {
  align-items: flex-start;
}

@media all and (max-width: 38.6875em) {
  .o-media--wrap\@lt-620 {
    flex-wrap: wrap;
  }
  .o-media--wrap\@lt-620 > .o-media__figure:first-child,
  .o-media--wrap\@lt-620 > .o-media__body:first-child {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

@media all and (max-width: 77.4375em) {
  .o-overflow-horizontal\@lt-1240 {
    width: 100%;
    overflow-x: scroll;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .o-overflow-horizontal\@lt-1240 .o-overflow-horizontal__inner {
    flex-wrap: nowrap;
    min-width: 100%;
    white-space: nowrap;
  }
}
.o-preserve-aspect-ratio {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

@media all and (max-width: 42.4375em) {
  .o-preserve-aspect-ratio\@lt-680 {
    width: 100%;
    height: 0;
    padding: 0 0 100%;
  }
}

.o-preserve-aspect-ratio--1x1 {
  padding: 0 0 100%;
}

.o-preserve-aspect-ratio--71x106 {
  padding: 0 0 66.9811320755%;
}

.o-preserve-aspect-ratio--69x52 {
  padding: 0 0 132.6923076923%;
}

.o-preserve-aspect-ratio--5x12 {
  padding: 0 0 41.6666666667%;
}

.o-preserve-aspect-ratio--25x42 {
  padding: 0 0 59.5238095238%;
}

@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--5x12\@680 {
    padding: 0 0 41.6666666667%;
  }
}

@media all and (max-width: 42.4375em) {
  .o-preserve-aspect-ratio--217x188\@lt-680 {
    padding: 0 0 115.4255319149%;
  }
}

@media all and (max-width: 42.4375em) {
  .o-preserve-aspect-ratio--360x375\@lt-680 {
    padding: 0 0 96%;
  }
}

.o-preserve-aspect-ratio--51x29 {
  padding: 0 0 175.8620689655%;
}

@media all and (max-width: 42.4375em) {
  .o-preserve-aspect-ratio--51x29\@lt-680 {
    padding: 0 0 175.8620689655%;
  }
}

@media all and (max-width: 48em) {
  .o-preserve-aspect-ratio--51x29\@lt-md {
    padding: 0 0 175.8620689655%;
  }
}

.o-preserve-aspect-ratio--2x1 {
  padding: 0 0 50%;
}

.o-preserve-aspect-ratio--1x2 {
  padding: 0 0 200%;
}

.o-preserve-aspect-ratio--16x9 {
  padding: 0 0 56.25%;
}

.o-preserve-aspect-ratio--3x2 {
  padding: 0 0 66.6666666667%;
}

.o-preserve-aspect-ratio--10x7 {
  padding: 0 0 70%;
}

.o-preserve-aspect-ratio--4x3 {
  padding: 0 0 75%;
}

.o-preserve-aspect-ratio--3x4 {
  padding: 0 0 133.3333333333%;
}

.o-preserve-aspect-ratio--3x5 {
  padding: 0 0 166.6666666667%;
}

.o-preserve-aspect-ratio--13x10 {
  padding: 0 0 130%;
}

.o-preserve-aspect-ratio--77x125 {
  padding: 0 0 61.6%;
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--25x13\@md {
    padding: 0 0 52%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--14x4\@md {
    padding: 0 0 28.5714285714%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--3x2\@md {
    padding: 0 0 66.6666666667%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--17x5\@md {
    padding: 0 0 29.4117647059%;
  }
}

@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--5x3\@680 {
    padding: 0 0 60%;
  }
}

@media all and (min-width: 65em) {
  .o-preserve-aspect-ratio--22x5\@1040 {
    padding: 0 0 22.7272727273%;
  }
}

@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--22x5\@680 {
    padding: 0 0 22.7272727273%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--22x5\@md {
    padding: 0 0 22.7272727273%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--24x7\@md {
    padding: 0 0 29.1666666667%;
  }
}

.o-preserve-aspect-ratio--5x3 {
  padding: 0 0 60%;
}

.o-preserve-aspect-ratio--1600x733 {
  padding: 0 0 45.8125%;
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--40x19\@md {
    padding: 0 0 47.5% !important;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--16x7\@md {
    padding: 0 0 43.75% !important;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--16x8\@md {
    padding: 0 0 50% !important;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--4x3\@md {
    padding: 0 0 75%;
  }
}

@media all and (min-width: 65em) {
  .o-preserve-aspect-ratio--3x1\@1040 {
    padding: 0 0 33.3333333333%;
  }
}

@media all and (min-width: 61.25em) {
  .o-preserve-aspect-ratio--3x1\@lg {
    padding: 0 0 33.3333333333%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--3x1\@md {
    padding: 0 0 33.3333333333%;
  }
}

.o-preserve-aspect-ratio--89x116 {
  padding: 0 0 130.3370786517%;
}

.o-preserve-aspect-ratio--552x432 {
  padding: 0 0 78.2608695652%;
}

.o-preserve-aspect-ratio--629x449 {
  padding: 0 0 71.3831478537%;
}

.o-preserve-aspect-ratio--440x593 {
  padding: 0 0 91.2307692308%;
}

.o-preserve-aspect-ratio--1200x700 {
  padding: 0 0 58.3333333333%;
}

.o-preserve-aspect-ratio--500x370 {
  padding: 0 0 74%;
}

.o-preserve-aspect-ratio--245x370 {
  padding: 0 0 151.0204081633%;
}

.o-preserve-aspect-ratio--419x632 {
  padding: 0 0 150.8353221957%;
}

.o-preserve-aspect-ratio--339x406 {
  padding: 0 0 119.7640117994%;
}

@media all and (min-width: 61.25em) {
  .o-preserve-aspect-ratio--20x9\@lg {
    padding: 0 0 45%;
  }
}

@media all and (min-width: 61.25em) {
  .o-preserve-aspect-ratio--35x33\@lg {
    padding: 0 0 94.2857142857%;
  }
}

.o-preserve-aspect-ratio--9x4 {
  padding: 0 0 44.4444444444%;
}

.o-preserve-aspect-ratio--9x4 {
  padding: 0 0 44.4444444444%;
}

.cc-preserve-aspect-ratio--rule-one {
  display: none;
}
@media all and (min-width: 75em) {
  .cc-preserve-aspect-ratio--rule-one {
    display: block;
    padding: 0 0 56%;
  }
}
@media all and (min-width: 81.25em) {
  .cc-preserve-aspect-ratio--rule-one {
    display: block;
    padding: 0 0 51.1904761905%;
  }
}
@media all and (min-width: 93.75em) {
  .cc-preserve-aspect-ratio--rule-one {
    display: block;
    padding: 0 0 44%;
  }
}

.cc-preserve-aspect-ratio--video {
  padding: 0 0 192.2666666667%;
}
@media all and (min-width: 42.5em) {
  .cc-preserve-aspect-ratio--video {
    padding: 0 0 117.1875%;
  }
}
@media all and (min-width: 64em) {
  .cc-preserve-aspect-ratio--video {
    padding: 0 0 56.25%;
  }
}

.cc-preserve-aspect-ratio--header {
  padding: 0 0 204.8%;
}
@media all and (min-width: 42.5em) {
  .cc-preserve-aspect-ratio--header {
    padding: 0 0 117.1875%;
  }
}
@media all and (min-width: 64em) {
  .cc-preserve-aspect-ratio--header {
    padding: 0 0 56.25%;
  }
}

@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--3x4\@680 {
    padding: 0 0 133.3333333333%;
  }
}

@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--16x9\@680 {
    padding: 0 0 56.25%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--16x9\@md {
    padding: 0 0 56.25%;
  }
}

@media all and (min-width: 64em) {
  .o-preserve-aspect-ratio--16x9\@1024 {
    padding: 0 0 56.25%;
  }
}

@media all and (min-width: 65em) {
  .o-preserve-aspect-ratio--16x9\@1040 {
    padding: 0 0 56.25%;
  }
}

@media (min-width: 30em) and (max-width: 48em) {
  .o-preserve-aspect-ratio--3x2\@btwn-sm-and-md {
    padding: 0 0 66.6666666667%;
  }
}

@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--18x10\@md {
    padding: 0 0 55.5555555556%;
  }
}

@media all and (min-width: 64em) {
  .o-preserve-aspect-ratio--3x1\@1024 {
    padding: 0 0 33.3333333333%;
  }
}

@media all and (min-width: 65em) {
  .o-preserve-aspect-ratio--147x16\@1040 {
    padding: 0 0 10.8843537415%;
  }
}

@media all and (min-width: 65em) {
  .o-preserve-aspect-ratio--18x10\@1040 {
    padding: 0 0 55.5555555556%;
  }
}

.o-preserve-aspect-ratio--covid19 {
  padding: 0 0 52.5%;
}

.o-preserve-aspect-ratio--pitcraft-craftsmanship-smoker {
  padding: 0 0 217.3333333333%;
}
@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--pitcraft-craftsmanship-smoker {
    padding: 0 0 100%;
  }
}
@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--pitcraft-craftsmanship-smoker {
    padding: 0 0 110.5263157895%;
  }
}

.o-preserve-aspect-ratio--pitcraft-header-video {
  padding: 0 0 56.25%;
}

.o-preserve-aspect-ratio--pitcraft-activity-state {
  padding: 0 0 46.6666666667%;
}
@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--pitcraft-activity-state {
    padding: 0 0 26.7857142857%;
  }
}

.o-preserve-aspect-ratio--pitcraft-success-state {
  padding: 0 0 61.3333333333%;
}
@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--pitcraft-success-state {
    padding: 0 0 29.6428571429%;
  }
}

.o-preserve-aspect-ratio--pitcraft-video-activity {
  padding: 0 0 150.7246376812%;
}
@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--pitcraft-video-activity {
    padding: 0 0 66.6666666667%;
  }
}

.o-preserve-aspect-ratio--pitcraft-recipe-activity {
  padding: 0 0 150.7246376812%;
}
@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--pitcraft-recipe-activity {
    padding: 0 0 52.803030303%;
  }
}

.o-preserve-aspect-ratio--pitcraft-recipe-image {
  padding: 0 0 95.9409594096%;
}
@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--pitcraft-recipe-image {
    padding: 0 0 106.0606060606%;
  }
}

.o-preserve-aspect-ratio--pitcraft-mobile-form {
  padding: 0 0 95.9409594096%;
}

@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--1x1\@680 {
    padding: 0 0 100%;
  }
}

@media all and (min-width: 61.25em) {
  .o-preserve-aspect-ratio--1x1\@lg {
    padding: 0 0 100%;
  }
}

.o-preserve-aspect-ratio--pitcraft-smokey-meat {
  padding: 0 0 46.875%;
}
@media all and (min-width: 22.5em) {
  .o-preserve-aspect-ratio--pitcraft-smokey-meat {
    padding: 0 0 57.3333333333%;
  }
}
@media (min-height: 812px) and (max-width: 375px) {
  .o-preserve-aspect-ratio--pitcraft-smokey-meat {
    padding: 0 0 70.9333333333%;
  }
}
@media (max-width: 414px) and (min-height: 896px) {
  .o-preserve-aspect-ratio--pitcraft-smokey-meat {
    padding: 0 0 70.9333333333%;
  }
}
@media (orientation: portrait) and (min-width: 1024px) {
  .o-preserve-aspect-ratio--pitcraft-smokey-meat {
    padding: 0 0 70.9333333333%;
  }
}

.o-preserve-aspect-ratio--pitcraft-success-mobile-meat {
  padding: 0 0 60%;
}

.o-preserve-aspect-ratio--pitcraft-success-meat {
  padding: 0 0 76.5106382979%;
}

.o-preserve-aspect-ratio--pitcraft-apron {
  padding: 0 0 134.1333333333%;
}
@media all and (min-width: 42.5em) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 90.8912830558%;
  }
}
@media (orientation: portrait) and (min-width: 680px) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 84.6354166667%;
  }
}
@media all and (min-width: 48.0625em) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 73.046875%;
  }
}
@media (orientation: portrait) and (min-device-width: 1024px) and (max-device-width: 1024px) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 66%;
  }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 62.5%;
  }
}
@media all and (min-width: 65em) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 80.6451612903%;
  }
}
@media all and (min-width: 81.25em) {
  .o-preserve-aspect-ratio--pitcraft-apron {
    padding: 0 0 62.8472222222%;
  }
}

.o-preserve-aspect-ratio--pitcraft-bonus-entry {
  padding: 0 0 30.9322033898%;
}

.o-section {
  overflow: visible;
  position: relative;
}

@media all and (max-width: 48em) {
  .o-section__content {
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .o-section__content {
    padding-right: 3em;
    padding-left: 3em;
  }
}

@media all and (min-width: 65em) {
  .o-section__content\@1040 {
    padding-right: 3em;
    padding-left: 3em;
  }
}

@media (min-width: 93.5em) {
  .o-section__content {
    padding-right: calc((100% - 87.5em) / 2);
    padding-left: calc((100% - 87.5em) / 2);
  }
  .o-section__content--full {
    padding-right: 3em;
    padding-left: 3em;
  }
}
.background-wrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

@media all and (max-width: 48em) {
  .o-section__content--full {
    padding-right: 0;
    padding-left: 0;
  }
}

@media all and (min-width: 48.0625em) {
  .o-section__content--right\@md {
    padding-left: 0;
  }
}
@media all and (min-width: 93.75em) {
  .o-section__content--right\@md {
    padding-left: 0;
  }
}

@media all and (min-width: 48.0625em) {
  .o-section__content--left\@md {
    padding-right: 0;
  }
}
@media all and (min-width: 93.75em) {
  .o-section__content--left\@md {
    padding-right: 0;
  }
}

.sidebar-layout--template #m-wrapper {
  padding: 0;
}
.sidebar-layout--template main {
  background-image: url("/img/pinnacle/backgrounds/mobile-dark-map-short.1743442783504.webp");
  background-repeat: no-repeat;
  background-position: right -10px;
  background-size: 100vw;
  scroll-behavior: smooth;
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template main {
    background-image: url("/img/pinnacle/backgrounds/map-top-dk.1743442783504.webp");
  }
}
.sidebar-layout--template .section-header {
  margin-bottom: 70px;
}
@media all and (min-width: 61.25em) {
  .sidebar-layout--template .section-header {
    margin-bottom: clamp(5.2083333333rem, 6.9444444444vw, 7.25rem);
  }
}
.sidebar-layout--template .section-header picture img {
  font-family: "object-fit: cover;";
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.sidebar-layout--template .o-section {
  padding-right: var(--page-margins-outer);
  padding-bottom: clamp(5.2083333333rem, 6.9444444444vw, 7.25rem);
  padding-left: var(--page-margins-outer);
  padding-top: var(--header-full-height);
}
.sidebar-layout--template .o-section .breadcrumbs {
  padding-left: 0;
}
.sidebar-layout--template .o-section .o-sidebar-layout {
  align-items: start;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  padding-top: 78px;
}
@media all and (min-width: 61.25em) {
  .sidebar-layout--template .o-section .o-sidebar-layout {
    text-align: left;
    grid-template-columns: 4fr 2fr;
    padding-top: clamp(4.0625rem, 5.4166666667vw, 5.6875rem);
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content {
  padding-bottom: 70px;
}
@media all and (min-width: 61.25em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content {
    padding-right: var(--page-margins-outer);
    padding-bottom: clamp(5.2083333333rem, 6.9444444444vw, 7.25rem);
  }
}
@media all and (min-width: 61.25em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header {
    grid-column: span 2;
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header h2 {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;
  font-size: 28px;
  color: #AB965D;
  margin-bottom: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem);
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header h2 {
    font-size: clamp(1.875rem, 2.5vw, 2.625rem);
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header h2 {
    font-size: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
  }
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header h2 .u-type-subscripts__sub--tm {
    font-size: 30%;
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  line-height: 1.4em !important;
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header p {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .content-header p {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .copy--wrapper {
  text-align: left;
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .copy--wrapper p {
  margin-bottom: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .copy--wrapper ol {
  color: #F7EEDF;
  margin-top: clamp(20px, 2.6666666667vw, 40px);
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__content .copy--wrapper ol li {
  margin-bottom: clamp(1.3020833333rem, 1.7361111111vw, 1.8125rem);
  text-align: left;
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar {
  text-align: left;
  align-self: start;
  top: calc(var(--header-full-height) + clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
@media all and (min-width: 61.25em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar {
    position: sticky;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar {
    padding-left: var(--page-margins-outer);
  }
}
@media all and (min-width: 61.25em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block {
    grid-column: span 2;
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block h2 {
  color: #AB965D;
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 1.28;
  letter-spacing: 0.06em;
  font-size: 14px;
  margin-bottom: clamp(1.3020833333rem, 1.7361111111vw, 1.8125rem);
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block h2 {
    font-size: 16px;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block h2 {
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  line-height: 1.4em !important;
  margin-bottom: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem);
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block p {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block p {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block a {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  color: #AB965D;
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block a {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .o-sidebar-layout .o-sidebar-layout__sidebar .o-sidebar-layout__sidebar-block a {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.sidebar-layout--template .o-section .hiring--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  grid-row-gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  align-items: center;
  justify-items: center;
  margin: clamp(2.6041666667rem, 3.4722222222vw, 3.625rem);
}
.sidebar-layout--template .o-section .hiring--grid li {
  padding: 20px;
}
.sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner {
  text-align: center;
}
.sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner h3 {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 1.28;
  letter-spacing: 0.06em;
  font-size: 14px;
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner h3 {
    font-size: 16px;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner h3 {
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner a {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
}
@media all and (min-width: 48.0625em) {
  .sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner a {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .sidebar-layout--template .o-section .hiring--grid li .o-list__item-inner a {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}

.o-video {
  position: relative;
}

.o-video__wrapper {
  position: relative;
  background: #000;
}

.o-video__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.o-video__element iframe,
.o-video__element object,
.o-video__element embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-video__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.o-video {
  position: relative;
}
.o-video iframe {
  visibility: hidden;
}
.o-video.is-active iframe {
  display: block;
  visibility: visible;
  opacity: 1;
}
.o-video > a.o-video__full-link {
  cursor: none;
}
@media all and (min-width: 48.0625em) {
  .o-video > a.o-video__full-link::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.o-video__preserve-aspect-ratio {
  width: 100%;
  height: 0;
}
.o-video__preserve-aspect-ratio video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media all and (max-width: 74.9375em) {
  .o-video__preserve-aspect-ratio {
    padding: 0 0 100%;
  }
}
@media all and (min-width: 75em) {
  .o-video__preserve-aspect-ratio {
    padding: 0 0 56.25%;
  }
}
.o-video__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.o-video__figure {
  background: #000;
}

.o-video__figure .c-video-controls__button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.o-video__media {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden;
}
.o-video__media iframe,
.o-video__media object,
.o-video__media embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-video__play-button--center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.o-video__play-button--center:after {
  display: none;
}

.o-video--overlay .o-video__full-link span:not(.screen-reader-only) {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.o-video--overlay #hero-video {
  visibility: hidden;
}
.o-video--overlay .o-video__figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.o-video--overlay .o-video__media {
  z-index: 100;
}
.o-video--overlay .o-video__play-button,
.o-video--overlay .o-video__figure {
  transition: opacity 300ms ease;
  opacity: 0;
}
.o-video--overlay .o-video__play-button {
  transition: opacity 300ms ease, color 300ms ease;
}
@media all and (max-width: 48em) {
  .o-video--overlay .o-video__play-button {
    opacity: 1;
    z-index: 100;
  }
}
.o-video--overlay.is-ready .o-video__play-button {
  opacity: 1;
  z-index: 100;
}
.o-video--overlay.is-loading .o-video__media {
  transition: none;
  opacity: 0;
}
.o-video--overlay:not(.is-loading) .o-video__media {
  transition: opacity 300ms ease;
  opacity: 1;
}
.o-video--overlay:not(.is-active) .o-video__figure {
  visibility: hidden;
}
.o-video--overlay:not(.is-active) .o-video__controls {
  visibility: hidden;
}
.o-video--overlay.is-active #hero-video {
  display: block;
  visibility: visible;
}
.o-video--overlay.is-active .o-video__play-button {
  opacity: 1;
  z-index: 0;
}
.o-video--overlay.is-active .o-video__figure {
  opacity: 1;
  display: block;
  visibility: visible;
}
.o-video--overlay.is-active > *:not(.o-video__figure, .c-hero__preserve-aspect-ratio, .o-preserve-aspect-ratio, .o-carousel) {
  visibility: hidden;
}

@media all and (min-width: 48.0625em) {
  .o-video--condensed-hover .c-hero__content {
    z-index: 50;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .o-video--condensed-hover .c-hero__content .c-hero__header,
  .o-video--condensed-hover .c-hero__content .c-button--play {
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
  .o-video--condensed-hover .c-hero__content .c-button--play span {
    max-width: 80px;
  }
  .o-video--condensed-hover.is-active .c-hero__content {
    z-index: 10;
  }
  .o-video--condensed-hover .c-button--play.is-active {
    color: #8a7449 !important;
  }
}
@media all and (max-width: 61.1875em) {
  .o-video--condensed-hover .c-hero__header {
    font-size: 12px;
  }
}
.o-video--overlay.is-active .o-video__play-button {
  color: transparent;
}
.o-video--overlay.is-active .o-video__play-button:hover::before,
.o-video--overlay.is-active .o-video__play-button:focus::before,
.o-video--overlay.is-active .o-video__play-button:active::before,
.o-video--overlay.is-active .o-video__play-button.is-active::before {
  width: 5.2222222222em;
  height: 5.2222222222em;
}

@keyframes pulse-scale-center {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
.o-video__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: -20vh;
  left: 0;
  transition: opacity 300ms ease, visibility 300ms linear 0s;
  background: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

body.has-video-backdrop .o-video__backdrop {
  opacity: 0.5;
  visibility: visible;
}

body.is-fullscreen .c-header,
body.is-fullscreen .c-footer {
  z-index: -1 !important;
}
body.is-fullscreen [class*=js-fade] {
  animation: none !important;
}

video[data-lazyload] {
  transition: opacity 300ms ease, visibility 300ms linear 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
video[data-lazyload].has-loaded {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.o-video {
  position: relative;
}

.o-video__wrapper {
  position: relative;
  background: #000;
}

.o-video__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.o-video__element iframe,
.o-video__element object,
.o-video__element embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-video__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.o-video {
  --video-play-btn-color: var(--color-bone);
  --video-aspect-ratio: 326 / 398;
  position: relative;
}
@media all and (min-width: 42.5em) {
  .o-video {
    --video-aspect-ratio: 1280 / 720 ;
  }
}

.o-video__content {
  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.o-video__background {
  aspect-ratio: var(--video-aspect-ratio);
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
}
.o-video__background::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
}
.o-video__background video, .o-video__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.o-video__background video {
  z-index: 1;
}

.o-video__details {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 18px;
}
@media all and (min-width: 42.5em) {
  .o-video__details {
    padding: clamp(2.8125rem, 3.75vw, 3.9375rem);
  }
}

.o-video__subheading {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 0.81;
  letter-spacing: 0.08em;
  font-size: 18px;
  margin-bottom: clamp(0.4166666667rem, 0.5555555556vw, 0.5625rem);
}
@media all and (min-width: 48.0625em) {
  .o-video__subheading {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .o-video__subheading {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}

.o-video__heading {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: 0.025em;
  font-size: 20px;
  margin-bottom: clamp(0.625rem, 0.8333333333vw, 0.875rem);
}
@media all and (min-width: 48.0625em) {
  .o-video__heading {
    font-size: 26px;
  }
}
@media all and (min-width: 75em) {
  .o-video__heading {
    font-size: 2.0833333333vw;
    line-height: 1.28;
  }
}

.o-video__play-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: clamp(2.9947916667rem, 3.9930555556vw, 4.1875rem);
  height: clamp(2.9947916667rem, 3.9930555556vw, 4.1875rem);
  text-decoration: none;
}
.o-video__play-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-bone);
  transform: rotate(-45deg);
  transition: transform 0.2s var(--motion-easing);
  position: absolute;
  top: 0;
  left: 0;
}
.o-video__play-btn span {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  color: var(--color-bone);
}
.o-video__play-btn:hover, .o-video__play-btn:focus {
  outline: none;
}
.o-video__play-btn:hover::after, .o-video__play-btn:focus::after {
  transform: scale(1.1) rotate(-45deg);
}

/**
 * Wrap overlapping elements to avoid extra horizontal space in Safari—the
 * only browser that doesn't correctly hide `overflow-x` on the `body`.
 */
._fix-safari-overflow {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

/**
 * Wrap inline SVGs with an `object` to fix Safari's broken height calculations.
 * http://benfrain.com/attempting-to-fix-responsive-svgs-in-desktop-safari
 */
._fix-safari-svg-height {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

._fix-safari-svg-height__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-zorro-svg {
  position: relative;
  width: 100%;
  height: 0;
  margin: -2px 0;
  padding: 0 0 100%;
  overflow: visible;
  z-index: 1;
}

.o-zorro-svg__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  pointer-events: none;
}

.o-zorro-svg__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * Full-width overlay backgrounds.
 * @param  {Number} $img-width
 * @param  {Number} $img-height
 * @param  {Number} $offset
 * @param  {String} $overlay-direction
 */
.btn {
  --btn-background: var(--color-brand-red);
  --btn-copy: var(--color-bone);
  --btn-hover-copy: var(--color-bone);
  cursor: pointer;
  display: block;
}
@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    color: var(--btn-hover-copy);
  }
}

.btn__content {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
  overflow: hidden;
  background: var(--btn-background);
  color: var(--btn-copy);
}

.btn__icon, .btn__copy {
  position: relative;
}

.btn--primary {
  --btn-background: var(--color-brand-red);
  --btn-copy: var(--color-bone);
  text-decoration: none;
}
.btn--primary .btn__content {
  padding: clamp(0.8333333333rem, 1.1111111111vw, 1.125rem);
}
.btn--primary .btn__copy {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  letter-spacing: 0.12em;
  line-height: 1;
  transition: transform 0.2s ease-out;
  flex-basis: 100%;
}
.btn--primary .btn__icon.btn-arrow {
  width: 12px;
  height: 10px;
}
.btn--primary .btn__icon.btn-arrow .c-icon {
  width: 12px;
  height: 10px;
  display: block;
}
.btn--primary .btn__icon.btn-plus {
  width: 10px;
  height: 10px;
}
.btn--primary .btn__icon.btn-plus .c-icon {
  width: 10px;
  height: 10px;
  display: block;
}
.btn--primary .btn__icon:first-child {
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  position: absolute;
}
.btn--primary .btn__icon:last-child {
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.btn--primary .btn__content::before {
  content: "";
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  transform: translate3d(-5px, -5px, 0);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), url("/img/pinnacle/global/btn-gold-texture.1743442783504.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: clip-path 0.3s var(--motion-easing);
}
.btn--primary:focus-visible .btn__icon:first-child, .btn--primary.active .btn__icon:first-child {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.btn--primary:focus-visible .btn__icon:last-child, .btn--primary.active .btn__icon:last-child {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
.btn--primary:focus-visible .btn__copy, .btn--primary.active .btn__copy {
  transform: translate3d(24px, 0, 0);
}
.btn--primary:focus-visible .btn__content::before, .btn--primary.active .btn__content::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
@media (hover: hover) and (pointer: fine) {
  .btn--primary:hover .btn__icon:first-child {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .btn--primary:hover .btn__icon:last-child {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  .btn--primary:hover .btn__copy {
    transform: translate3d(24px, 0, 0);
  }
  .btn--primary:hover .btn__content::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.btn--primary-line {
  --btn-background: transparent;
  --btn-copy: var(--color-bone);
}
.btn--primary-line .btn__content::before {
  content: "";
  background: var(--color-brand-red);
}
.btn--primary-line .btn__content::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-bone);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.btn--centered .btn__copy {
  text-align: center;
  width: 100%;
}

.btn--no-icon .btn__copy {
  transition: none !important;
  transform: none !important;
}

.btn--secondary {
  --btn-background: transparent;
  --btn-copy: var(--color-bone);
  align-self: flex-start;
  display: inline-flex;
  text-decoration: none;
}
.btn--secondary .btn__content {
  overflow: visible;
}
.btn--secondary .btn__content::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -10px;
  left: 0;
  background: var(--color-bone);
  transition: clip-path 0.3s 0.2s var(--motion-easing);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}
.btn--secondary .btn__content::after {
  display: none;
}
.btn--secondary .btn__copy {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.12em;
  line-height: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-out;
  white-space: nowrap;
}
@media all and (min-width: 48.0625em) {
  .btn--secondary .btn__copy {
    font-size: 12.5px;
  }
}
@media all and (min-width: 75em) {
  .btn--secondary .btn__copy {
    font-size: clamp(0.6510416667rem, 0.8680555556vw, 0.875rem);
  }
}
.btn--secondary .btn__icon {
  width: 12px;
  height: 10px;
}
@media all and (min-width: 42.5em) {
  .btn--secondary .btn__icon {
    width: clamp(0.625rem, 0.8333333333vw, 0.875rem);
    height: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem);
  }
}
.btn--secondary .btn__icon:first-child {
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  position: absolute;
}
.btn--secondary .btn__icon:last-child {
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.btn--secondary .btn__icon .c-icon {
  width: 12px;
  height: 10px;
  display: block;
}
@media all and (min-width: 42.5em) {
  .btn--secondary .btn__icon .c-icon {
    width: clamp(0.625rem, 0.8333333333vw, 0.875rem);
    height: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem);
  }
}
.btn--secondary:focus-visible .btn__icon:first-child, .btn--secondary.active .btn__icon:first-child {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.btn--secondary:focus-visible .btn__icon:last-child, .btn--secondary.active .btn__icon:last-child {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
.btn--secondary:focus-visible .btn__copy, .btn--secondary.active .btn__copy {
  transform: translate3d(24px, 0, 0);
}
.btn--secondary:focus-visible .btn__content::before, .btn--secondary.active .btn__content::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
@media (hover: hover) and (pointer: fine) {
  .btn--secondary:hover .btn__icon:first-child {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .btn--secondary:hover .btn__icon:last-child {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  .btn--secondary:hover .btn__copy {
    transform: translate3d(24px, 0, 0);
  }
  .btn--secondary:hover .btn__content::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.btn--link {
  --btn-background: transparent;
}
.btn--link .btn__copy {
  text-align: center;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
}

.c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.c-button--box:focus, .c-button--box:active {
  outline: none;
}

.c-button--box:focus-within::after, .c-button--box:active::after {
  content: "-";
  display: block;
  width: 100%;
  border-bottom: 6px solid #54504b;
  position: absolute;
  line-height: 0;
  margin-top: 3rem;
  color: #54504b;
}

.c-button--box {
  font-size: 1.125em;
  letter-spacing: 0.05em;
  border: 1px solid currentColor;
  text-decoration: none;
  font-family: "minion-pro";
}
@media all and (max-width: 42.4375em) {
  .c-button--box {
    font-size: 1em;
  }
}

.c-button--box-rounded {
  border-radius: 20px;
}

[class*=c-button--box] {
  padding: 0 0.8em;
}
.supports-font-features.wf-active [class*=c-button--box] > span:not(.c-icon) {
  padding: 0.3333333333em 0 0.5em;
  line-height: 0.9;
}

[class*=c-button--box] .c-icon--arrow,
[class*=c-button--box] .c-icon--arrow-reverse {
  width: 0.6666666667em;
}
[class*=c-button--box] .c-icon--arrow {
  margin-left: 0.6em;
}
[class*=c-button--box] .c-icon--arrow-reverse {
  margin-right: 0.6em;
}
[class*=c-button--box] .u-display-inline-flex .c-icon--arrow {
  margin-top: 0.1875em;
}
[class*=c-button--box] .c-icon--download {
  margin-left: 0.8em;
}

.c-button--box-fill {
  font-size: 1.125em;
  letter-spacing: 0.05em;
  border: 0;
  background: #AB965D;
  color: #000;
  text-decoration: none;
}
@media all and (max-width: 42.4375em) {
  .c-button--box-fill {
    font-size: 1em;
  }
}
.c-button--box-fill:hover, .c-button--box-fill:focus, .c-button--box-fill:active {
  background: #c3aa86;
  color: #000;
}

.c-button--box-fill-red {
  font-size: 1.125em;
  letter-spacing: 0.05em;
  border: 0;
  background: #A6192E;
  color: #fff;
  text-decoration: none;
}
@media all and (max-width: 42.4375em) {
  .c-button--box-fill-red {
    font-size: 1em;
  }
}
.c-button--box-fill-red:hover, .c-button--box-fill-red:focus, .c-button--box-fill-red:active {
  background: #a9271b;
  color: #fff;
}

.c-button--box-md {
  padding: 0.3333333333em 0.7777777778em;
}

.c-button--box-lg {
  padding: 0.5em 0.8888888889em;
}

@media all and (min-width: 61.25em) {
  .c-button--box-lg\@lg {
    padding: 0.5em 0.8888888889em;
  }
}

.c-button--box-wide {
  min-width: 200px;
}

@media all and (max-width: 29.9375em) {
  .c-button--box-full-width\@lt-sm {
    justify-content: space-between;
    width: 100%;
  }
}

.c-button--box-border-red {
  position: relative;
}
.c-button--box-border-red::after {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border: 2px solid #A6192E;
  content: "";
}
.c-button--box-border-red:hover::after {
  border-color: #a9271b;
}

.c-button--salmon {
  background-color: #ef896c;
  position: relative;
  border-radius: 5px;
  padding: 0.1em 0;
  font-size: 18px;
}

#pitcraft-modal .bold-button--double-border {
  border: 1px solid #AB965D;
  outline: 1px solid #AB965D;
  outline-offset: 2px;
  padding: 0.2em 1em;
}

.c-button--circle {
  -webkit-transform-style: preserve-3d;
  font-size: 1.125em;
  letter-spacing: 0.05em;
  position: relative;
  width: 5.2222222222em;
  height: 5.2222222222em;
  color: #AB965D;
  line-height: 0.8333333333;
  display: flex;
  text-decoration: none;
  text-align: center;
}
.c-button--circle > * {
  -webkit-backface-visibility: hidden;
}
.c-button--circle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 200ms ease;
  border: 2px solid rgba(171, 150, 93, 0.5);
  border-radius: 50%;
  content: "";
  pointer-events: none;
  width: 4.4444444444em;
  height: 4.4444444444em;
}
.c-button--circle:hover::after, .c-button--circle:focus::after, .c-button--circle:active::after {
  transform: translate(-50%, -50%) scale(1.175);
  background: rgba(195, 170, 134, 0.1);
}
.c-button--circle.is-active::after {
  transform: translate(-50%, -50%) scale(1.175);
  background: rgba(195, 170, 134, 0.1);
}
@media all and (max-width: 42.4375em) {
  .c-button--circle {
    font-size: 1em;
  }
}

.c-button--circle-icon {
  background: var(--btn-circle-icon-bg);
  color: var(--btn-circle-icon-color);
  width: clamp(3.4375rem, 5.2083333333vw, 5.4375rem);
  height: clamp(3.4375rem, 5.2083333333vw, 5.4375rem);
  border-radius: 50%;
}

._button-caption {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 200%;
  height: 175.5555555556%;
  transform: translateX(-50%);
  border: 0;
  text-align: center;
  text-decoration: none;
}
@media (max-width: 55em) {
  ._button-caption {
    display: none;
  }
}

.c-button--scroll, .c-button--scroll-pink, .c-button--scroll-arrow {
  position: relative;
  width: 5.625em;
  height: 2.8125em;
  color: #8a7449;
}
.c-button--scroll::after, .c-button--scroll-pink::after, .c-button--scroll-arrow::after, .c-button--scroll::before, .c-button--scroll-pink::before, .c-button--scroll-arrow::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: "";
  pointer-events: none;
}
.c-button--scroll::before, .c-button--scroll-pink::before, .c-button--scroll-arrow::before {
  transform: translate(-50%, 0);
  transition: border-width 200ms ease;
  border-width: 2.1875em;
  border-bottom-color: #000;
}
.c-button--scroll::after, .c-button--scroll-pink::after, .c-button--scroll-arrow::after {
  margin-left: -0.40625em;
  transform: none;
  transition: border-color 200ms ease;
  border-width: 0.40625em;
  border-top-color: currentColor;
  animation: slight-bounce 2000ms ease infinite;
}
.c-button--scroll:hover, .c-button--scroll-pink:hover, .c-button--scroll-arrow:hover, .c-button--scroll:focus, .c-button--scroll-pink:focus, .c-button--scroll-arrow:focus, .c-button--scroll:active, .c-button--scroll-pink:active, .c-button--scroll-arrow:active {
  color: #c3aa86;
}
.c-button--scroll:hover::before, .c-button--scroll-pink:hover::before, .c-button--scroll-arrow:hover::before, .c-button--scroll:focus::before, .c-button--scroll-pink:focus::before, .c-button--scroll-arrow:focus::before, .c-button--scroll:active::before, .c-button--scroll-pink:active::before, .c-button--scroll-arrow:active::before {
  border-width: 2.8125em;
}
.c-button--scroll:hover::after, .c-button--scroll-pink:hover::after, .c-button--scroll-arrow:hover::after, .c-button--scroll:focus::after, .c-button--scroll-pink:focus::after, .c-button--scroll-arrow:focus::after, .c-button--scroll:active::after, .c-button--scroll-pink:active::after, .c-button--scroll-arrow:active::after {
  animation: none;
}

.c-button--scroll-arrow {
  font-variant: small-caps;
  text-transform: lowercase;
  display: inline-block;
  width: 5.625em;
  height: 2.8125em;
  color: #8a7449;
  font-size: 1.25em;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
}
.supports-font-features.wf-active .c-button--scroll-arrow {
  font-variant: normal;
  font-variant-caps: small-caps;
  font-feature-settings: "smcp";
}
.c-button--scroll-arrow sup {
  vertical-align: top;
  margin-top: 0.2em;
  font-size: 70%;
  display: inline-block;
}
.c-button--scroll-arrow sup.u-reset-small-caps-sup {
  top: -0.1em;
  font-size: 36%;
}
.c-button--scroll-arrow::before {
  content: none;
}

.c-button--scroll-pink::after {
  border-top-color: #e371ac;
}

.c-button--pill {
  background-color: var(--btn-pill-background);
  transition: background-color 0.6s ease-in-out;
  overflow: hidden;
  position: static;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  letter-spacing: 2.15px;
  height: var(--pill-height);
  border: 1px solid var(--btn-pill-border);
  border-radius: 50px;
  color: var(--btn-pill-txt-color);
  text-decoration: none;
  line-height: normal;
}
@media all and (min-width: 48.0625em) {
  .c-button--pill {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .c-button--pill {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.c-button--pill .inside-wrapper {
  white-space: nowrap;
  display: flex;
  align-items: center;
  column-gap: 17px;
  padding-left: 15px;
  padding-right: 15px;
}
.c-button--pill .inside-wrapper .c-icon {
  color: var(--btn-pill-icon-color);
  transition: color 0.4s ease-in-out;
}
.microsoft .c-button--pill .btn--text {
  line-height: 0;
  padding-top: 0.17em;
}
.c-button--pill.pill--icon-only {
  min-width: clamp(3.3125rem, 4.8611111111vw, 5.0625rem);
}
.c-button--pill.pill--arrow:hover {
  color: var(--btn-pill-txt-color);
}
.c-button--pill.pill--arrow:hover .c-icon {
  color: var(--btn-pill-txt-color);
  transition: color 0.6s ease-in-out;
  animation: arrow-move 0.8s ease-in-out forwards;
}
.c-button--pill.pill--arrow:hover.prev {
  color: var(--btn-pill-txt-color);
}
.c-button--pill.pill--arrow:hover.prev .c-icon {
  color: var(--btn-pill-txt-color);
  transition: color 0.6s ease-in-out;
  animation: arrow-move 0.8s ease-in-out forwards;
}
@media all and (min-width: 75em) {
  .c-button--pill {
    height: clamp(2.2916666667rem, 3.0555555556vw, 3.1875rem);
  }
  .c-button--pill .inside-wrapper {
    column-gap: clamp(0.78125rem, 1.0416666667vw, 1.0625rem);
    padding-left: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
    padding-right: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}

.c-button--pill-dk {
  --btn-pill-border: #AB965D;
  --btn-pill-txt-color: #34302B;
  --btn-pill-background: transparent;
}
.c-button--pill-dk:hover {
  --btn-pill-background: var(--btn-pill-border);
}

.c-button--pill-lt {
  --btn-pill-border: #AB965D;
  --btn-pill-txt-color: #F7EEDF;
  --btn-pill-background: transparent;
}
.c-button--pill-lt:hover {
  --btn-pill-background: var(--btn-pill-border);
}

@keyframes arrow-move {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  25% {
    opacity: 1;
    transform: scale(0) translate3d(-200px, 0, 0);
    transform-origin: center;
  }
  40% {
    opacity: 1;
    transform: scale(0) translate3d(-200px, 0, 0);
    transform-origin: center;
  }
  75% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
}
@keyframes arrow-move--opposite {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  25% {
    opacity: 1;
    transform: scale(0) translate3d(200px, 0, 0);
    transform-origin: center;
  }
  40% {
    opacity: 1;
    transform: scale(0) translate3d(200px, 0, 0);
    transform-origin: center;
  }
  75% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
}
.c-counter {
  width: 220px;
  height: 60px;
  background: url("/img/october-taste-of-giving/count-bg.png") no-repeat center center;
  font-size: 36px;
  line-height: 58px;
  text-align: right;
  letter-spacing: 19px;
  color: #e371ac;
  margin: 5px auto;
  font-style: italic;
}

.dropdown__group {
  position: relative;
}

.dropdown {
  --dropdown-content-height: 300px;
  --dropdown-min-width: 200px;
  --dropdown-width: 200px;
  --dropdown-max-width: 600px;
  --dropdown-hover: rgba(0, 0, 0, 0.5);
  --dropdown-outline: rgba(247, 238, 223, 0.25);
  --dropdown-selected-background: var(--color-brand-red);
  --dropdown-option-vertical-padding: clamp(0.4166666667rem, 0.5555555556vw, 0.5625rem);
  --dropdown-option-horizontal-padding: clamp(0.8333333333rem, 1.1111111111vw, 1.125rem);
  --dropdown-btn-padding: clamp(0.8333333333rem, 1.1111111111vw, 1.125rem);
  --dropdown-button-text-color: var(--color-bone);
  --dropdown-button-outline: var(--color-bone);
  --dropdown-content-background-height: calc(var(--dropdown-content-height) + 48px + 10px);
  --dropdown-height: 42px;
  display: inline-flex;
  position: relative;
  z-index: 5;
  width: var(--dropdown-width);
  min-width: var(--dropdown-min-width);
  max-width: var(--dropdown-max-width);
}
.dropdown::before {
  content: "";
  z-index: 1;
  position: absolute;
  height: var(--dropdown-content-background-height);
  width: 100%;
  background: url("/img/pinnacle/backgrounds/dd-paper-bg.1743442783504.webp");
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: inset(0 0 var(--dropdown-content-height) 0);
  transition: clip-path 0.5s var(--motion-easing), opacity 0s 0.5s;
  opacity: 0;
  outline: 1px solid var(--dropdown-outline);
  pointer-events: none;
}

.dropdown__option-length {
  top: 0;
  left: 0;
  clip-path: rect(100% 100% 100% 100%);
  visibility: hidden;
  position: absolute;
  width: min-content;
  white-space: nowrap;
}

.dropdown__entry-point {
  position: relative;
  width: 100%;
  z-index: 1;
}

.dropdown__btn {
  position: relative;
  cursor: pointer;
  display: block;
  width: 100%;
  text-decoration: none;
}
.dropdown__btn:hover, .dropdown__btn:focus {
  outline: none;
}
.dropdown__btn .dropdown__btn-content {
  padding: 0 clamp(0.8333333333rem, 1.1111111111vw, 1.125rem);
}
.dropdown__btn .dropdown__btn-copy {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  letter-spacing: 0.12em;
  text-align: left;
  line-height: 1;
  position: relative;
  transition: transform 0.2s ease-out;
  white-space: nowrap;
  display: block;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.dropdown__btn input.dropdown__btn-copy::placeholder {
  transition: color 0.2s ease-out;
}
.dropdown__btn input.dropdown__btn-copy:focus::placeholder {
  color: rgba(247, 238, 223, 0.4);
}
.dropdown__btn .dropdown__btn-content::before {
  content: "";
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  transform: translate3d(-5px, -5px, 0);
  background: var(--dropdown-selected-background);
  position: absolute;
  top: 0;
  left: 0;
  transition: clip-path 0.3s 0.2s var(--motion-easing);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

/* For browsers that support `scrollbar-*` properties */
@supports (scrollbar-color: auto) {
  .dropdown__options {
    scrollbar-color: #000000 #404040;
    scrollbar-width: thin;
  }
}
/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
@supports selector(::-webkit-scrollbar) {
  .dropdown__options::-webkit-scrollbar {
    background: #404040;
    width: 10px;
  }
  .dropdown__options::-webkit-scrollbar-thumb {
    background: #000000;
  }
}
input[data-dropdown-selected-label] {
  background: transparent;
  width: 100%;
  height: 12px;
}
input[data-dropdown-selected-label]::placeholder {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  letter-spacing: 0.12em;
  color: var(--dropdown-button-text-color);
}

.dropdown__btn-icon.dropdown-arrow {
  width: 12px;
  height: 12px;
  display: block;
  transform-origin: center center;
  transition: transform 0.2s var(--motion-easing);
}
.dropdown__btn-icon.dropdown-arrow .c-icon {
  width: 12px;
  height: 12px;
  display: block;
}
.dropdown__btn-icon.dropdown-search {
  width: 10px;
  height: 10px;
  display: block;
  transform-origin: center center;
}
.dropdown__btn-icon.dropdown-search .c-icon {
  width: 10px;
  height: 10px;
  display: block;
}
.dropdown__btn-icon.close {
  width: 12px;
  height: 12px;
}
.dropdown__btn-icon.close .c-icon {
  width: 12px;
  height: 12px;
  display: block;
}

.dropdown__btn-content {
  display: grid;
  grid-template-columns: 1fr 12px;
  align-items: center;
  column-gap: 10px;
  position: relative;
  overflow: hidden;
  height: var(--dropdown-height);
  color: var(--btn-copy);
}
.dropdown__btn-content::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--dropdown-button-outline);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.dropdown__btn-icon {
  position: relative;
  grid-column: 2;
  grid-row: 1;
}

.has-selection .dropdown__deselect {
  display: flex;
}
.has-selection .dropdown__btn-icon.dropdown-arrow,
.has-selection .dropdown__btn-icon.dropdown-search {
  display: none;
}

.dropdown__deselect {
  position: absolute;
  right: var(--dropdown-btn-padding);
  top: 50%;
  transform: translate3d(0, -50%, 0);
  display: none;
  justify-content: right;
  align-items: center;
  z-index: 3;
  height: 32px;
  width: 32px;
}

.dropdown__content {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 0;
  height: var(--dropdown-content-height);
  transition: max-height 0.5s var(--motion-easing);
  overflow: hidden;
  z-index: 2;
}

.dropdown__options {
  height: 100%;
  overflow-y: auto;
}

.dropdown__option {
  display: block;
}

.dropdown__option-reference {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.dropdown__option-btn {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: var(--dropdown-option-vertical-padding) var(--dropdown-option-horizontal-padding);
}
.dropdown__option-btn:hover, .dropdown__option-btn:focus, .dropdown__option-btn.focus {
  background: var(--dropdown-hover);
  outline: none;
}

.dropdown__option--no-results .dropdown__option-btn:hover, .dropdown__option--no-results .dropdown__option-btn:focus, .dropdown__option--no-results .dropdown__option-btn.focus {
  background: transparent;
  outline: none;
  pointer-events: none;
}

.dropdown__option-text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 16px;
}
@media all and (max-width: 42.4375em) {
  .dropdown__option-text {
    font-size: 22px;
  }
}
.dropdown__option-text .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 48.0625em) {
  .dropdown__option-text {
    font-size: 18px;
  }
}
@media all and (min-width: 75em) {
  .dropdown__option-text {
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}

.dropdown.open::before {
  opacity: 1;
  transition-delay: 0s, 0s;
  clip-path: inset(0 0 0 0);
}
.dropdown.open .dropdown-arrow {
  transform: rotate(180deg);
}
.dropdown.open .dropdown__content {
  max-height: calc(var(--dropdown-content-height) + 1px);
}
.dropdown.open .dropdown__btn-content::after {
  opacity: 0;
}

.dropdown.has-selection .dropdown__btn-content::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.c-dropdown__toggle .c-icon--arrow-down {
  margin-left: 0.4em;
}
.c-dropdown__toggle .c-icon--info {
  transform: rotate(90deg);
  stroke: none;
}
.c-dropdown__toggle.is-active .c-icon--arrow-down {
  transform: rotate(180deg);
}
.c-dropdown__toggle.is-active .c-icon--info {
  transform: rotate(0deg);
  stroke: none;
}

.c-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transition: opacity 300ms ease, visibility 300ms linear 0s;
  background: #494540;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 80;
}
.c-dropdown.is-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.c-dropdown__item:not(:first-child) {
  border-top: 1px solid #54504b;
}

.c-dropdown__link {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2.5em;
  padding: 0.5em 1em;
  color: #a7a49e;
  line-height: 1.25;
  text-decoration: none;
}
.c-dropdown__link:hover, .c-dropdown__link:focus, .c-dropdown__link:active {
  background: #54504b;
  color: #a7a49e;
}
.c-dropdown__link mark {
  background: none;
  color: #cdc9c3;
}

@media all and (max-width: 38.6875em) {
  .c-dropdown__toggle\@lt-620 .c-icon--arrow-down {
    margin-left: 0.4em;
  }
  .c-dropdown__toggle\@lt-620 .c-icon--info {
    transform: rotate(90deg);
    stroke: none;
  }
  .c-dropdown__toggle\@lt-620.is-active .c-icon--arrow-down {
    transform: rotate(180deg);
  }
  .c-dropdown__toggle\@lt-620.is-active .c-icon--info {
    transform: rotate(0deg);
    stroke: none;
  }
  .c-dropdown\@lt-620 {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transition: opacity 300ms ease, visibility 300ms linear 0s;
    background: #494540;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 80;
  }
  .c-dropdown\@lt-620.is-active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .c-dropdown__item\@lt-620:not(:first-child) {
    border-top: 1px solid #54504b;
  }
  .c-dropdown__link\@lt-620 {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 2.5em;
    padding: 0.5em 1em;
    color: #a7a49e;
    line-height: 1.25;
    text-decoration: none;
  }
  .c-dropdown__link\@lt-620:hover, .c-dropdown__link\@lt-620:focus, .c-dropdown__link\@lt-620:active {
    background: #54504b;
    color: #a7a49e;
  }
  .c-dropdown__link\@lt-620 mark {
    background: none;
    color: #cdc9c3;
  }
}
.footer {
  --footer-spacing-top: 45px;
  --footer-spacing-bottom: 50px;
  --footer-spacing-right: var(--page-margins-outer);
  --footer-spacing-left: var(--page-margins-outer);
  background: var(--footer-bg-color);
  position: relative;
  z-index: 1;
  padding-top: var(--footer-spacing-top);
  padding-bottom: var(--footer-spacing-top);
  padding-left: var(--footer-spacing-left);
  padding-right: var(--footer-spacing-right);
}
@media all and (min-width: 65em) {
  .footer {
    --footer-spacing-top: 55px;
    --footer-spacing-right: clamp(8.8541666667rem, 11.8055555556vw, 12.375rem);
  }
}

.footer__content {
  display: grid;
  grid-template-rows: min-content min-content;
  column-gap: clamp(3.125rem, 4.1666666667vw, 4.375rem);
  grid-template-columns: 1fr;
  grid-template-areas: "logo" "navigation" "newsletter" "social" "copyright";
}
@media all and (min-width: 61.25em) {
  .footer__content {
    row-gap: 0;
  }
}
@media all and (min-width: 61.25em) {
  .footer__content {
    grid-template-columns: clamp(22.3958333333rem, 29.8611111111vw, 31.3125rem) 1fr;
    grid-template-areas: "logo navigation" "newsletter navigation" "social copyright";
  }
}

.footer__social-nav {
  grid-area: social;
}

.footer__newsletter {
  grid-area: newsletter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: min-content;
}
.footer__newsletter p {
  text-align: center;
  margin-bottom: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
}
.footer__newsletter p .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 75em) {
  .footer__newsletter p {
    letter-spacing: 0.02em;
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}
@media all and (min-width: 61.25em) {
  .footer__newsletter {
    align-items: flex-start;
  }
  .footer__newsletter p {
    text-align: left;
  }
}

.footer__contact {
  grid-area: navigation;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  width: 75vw;
}
@media all and (min-width: 48.0625em) {
  .footer__contact {
    width: 100%;
    padding-left: clamp(1.9791666667rem, 2.6388888889vw, 2.75rem);
  }
}
.footer__contact h3 {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.08em;
  font-size: 18px;
  margin-bottom: clamp(0.8333333333rem, 1.1111111111vw, 1.125rem);
}
@media all and (min-width: 75em) {
  .footer__contact h3 {
    line-height: 1.28;
    letter-spacing: 0.06em;
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.footer__contact p {
  text-align: center;
  margin-bottom: clamp(1.3020833333rem, 1.7361111111vw, 1.8125rem);
}
.footer__contact .footer__contact-btn {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.1em;
  color: var(--color-brand-gold);
}
@media all and (min-width: 61.25em) {
  .footer__contact .footer__contact-btn {
    letter-spacing: 0.1em;
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
@media all and (min-width: 48.0625em) {
  .footer__contact {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer__contact p {
    text-align: left;
  }
}

.footer__logo {
  grid-area: logo;
  margin: 0 auto 37px;
}
@media all and (min-width: 61.25em) {
  .footer__logo {
    margin: 0;
  }
}
.footer__logo img {
  max-width: 83px;
}

.footer__navigation {
  grid-area: navigation;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  column-gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
}
@media all and (min-width: 48.0625em) {
  .footer__navigation {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.footer__navigation h4 {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.08em;
  font-size: 18px;
  color: var(--footer-navigation-color);
  margin-bottom: clamp(1.5104166667rem, 2.0138888889vw, 2.0625rem);
}
@media all and (min-width: 75em) {
  .footer__navigation h4 {
    line-height: 1.28;
    letter-spacing: 0.06em;
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.footer__navigation p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
}
.footer__navigation p .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 75em) {
  .footer__navigation p {
    letter-spacing: 0.02em;
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}

.footer__navigation-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: clamp(50px, 6.6666666667vw, 100px);
  flex-basis: 45%;
}
@media all and (min-width: 48.0625em) {
  .footer__navigation-list {
    flex-basis: 50%;
    justify-content: flex-start;
  }
}
@media all and (min-width: 61.25em) {
  .footer__navigation-list {
    margin-bottom: 0;
  }
}
.footer__navigation-list h3 {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.08em;
  font-size: 18px;
  margin-bottom: clamp(0.8333333333rem, 1.1111111111vw, 1.125rem);
}
@media all and (min-width: 75em) {
  .footer__navigation-list h3 {
    line-height: 1.28;
    letter-spacing: 0.06em;
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}

.footer__copyright {
  grid-area: copyright;
  margin-top: clamp(2.5rem, 3.3333333333vw, 3.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: min-content;
}
@media all and (min-width: 72.5em) {
  .footer__copyright {
    flex-direction: row;
    align-items: start;
  }
}
.footer__copyright a {
  color: transparent;
}
.footer__copyright a svg {
  color: initial;
}
.footer__copyright a.sitemap {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #AB965D;
}
@media all and (min-width: 61.25em) {
  .footer__copyright a.sitemap {
    letter-spacing: 0.1em;
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.footer__copyright p {
  display: inline;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  order: 2;
  margin-top: clamp(50px, 6.6666666667vw, 100px);
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  color: #BBAE96;
  text-align: center;
}
@media all and (min-width: 61.25em) {
  .footer__copyright p {
    display: flex;
    order: 0;
    white-space: pre;
    margin-top: 0;
    margin-bottom: 0;
  }
  .footer__copyright p br.copyright-break {
    display: none;
  }
}
.footer__copyright p .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 61.25em) {
  .footer__copyright p {
    letter-spacing: 0.025em;
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.footer__copyright p > span {
  order: 2;
}
@media all and (min-width: 61.25em) {
  .footer__copyright p > span {
    order: 1;
  }
}
.footer__copyright p a {
  color: #BBAE96;
  order: 2;
  flex-basis: 100%;
}
@media all and (min-width: 61.25em) {
  .footer__copyright p a {
    flex-basis: auto;
    order: 1;
  }
}
.footer__copyright p a.sitemap {
  flex-basis: 100%;
  order: 1;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.1em;
  color: #AB965D;
}
@media all and (min-width: 61.25em) {
  .footer__copyright p a.sitemap {
    letter-spacing: 0.1em;
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
@media all and (min-width: 61.25em) {
  .footer__copyright p a.sitemap {
    order: 2;
    flex-basis: auto;
    margin-left: clamp(0.9375rem, 2.5vw, 2.625rem);
  }
}

.footer__lang-links {
  list-style: none;
  list-style-type: none;
  display: flex;
  column-gap: 15px;
  margin-bottom: 28px;
}
@media all and (min-width: 48.0625em) {
  .footer__lang-links {
    margin-bottom: 0;
  }
}
.footer__lang-links a {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.1em;
  color: var(--footer-navigation-color);
}
@media all and (min-width: 61.25em) {
  .footer__lang-links a {
    letter-spacing: 0.1em;
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.footer__lang-links a:hover, .footer__lang-links a:focus {
  text-decoration: underline;
}

.footer__social-links {
  padding-top: 22px;
}
@media all and (min-width: 48.0625em) {
  .footer__social-links {
    padding-top: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}
.footer__social-links .social-nav {
  justify-content: center;
}
@media all and (min-width: 61.25em) {
  .footer__social-links .social-nav {
    justify-content: flex-start;
  }
}
.footer__social-links .social-nav li > a {
  color: var(--color-brand-gold);
}

.footer-links__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.footer-links__list a {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  text-decoration: none;
  color: var(--footer-navigation-color);
}
.footer-links__list a .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 75em) {
  .footer-links__list a {
    letter-spacing: 0.02em;
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}
.footer-links__list a:focus {
  text-decoration: underline;
}

.c-product-grid {
  display: grid;
  column-gap: 40px;
  row-gap: 40px;
  column-gap: clamp(32px, 4.2666666667vw, 64px);
  row-gap: clamp(32px, 4.2666666667vw, 64px);
}
@media all and (min-width: 75em) {
  .c-product-grid {
    column-gap: clamp(2.0833333333rem, 2.7777777778vw, 2.875rem);
    row-gap: clamp(2.0833333333rem, 2.7777777778vw, 2.875rem);
  }
}

.c-product-grid--alt {
  counter-reset: recipe-counter 1;
  justify-content: center;
}

.c-product-grid__item {
  position: relative;
  padding-bottom: clamp(20px, 2.6666666667vw, 40px);
}
@media all and (min-width: 75em) {
  .c-product-grid__item {
    padding-bottom: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.c-product-grid--alt .c-product-grid__item {
  counter-increment: recipe-counter;
}
.c-product-grid--alt .c-product-grid__item::after {
  content: none;
}

.c-product-grid__link {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
  color: var(--product-grid-link);
}
.c-product-grid__link.c-link {
  color: var(--product-grid-link);
}
.c-product-grid__link:hover, .c-product-grid__link:focus, .c-product-grid__link:active {
  color: #c3aa86;
}
.c-product-grid__link .c-video-badge {
  display: none;
}
.c-product-grid__link .c-product-grid__figure::after {
  background: var(--product-grid-link-hover-bg-lt);
}
.c-product-grid__link:not(.c-product-grid__link--no-label) .c-product-grid__figure::after {
  background: var(--product-grid-link-hover-bg);
}
.c-product-grid__link.c-product-grid__link--old-label .c-product-label {
  left: 0;
  width: 100%;
  transform: translate3d(0, -2%, 0);
}
.c-product-grid__link.c-product-grid__link--old-label.c-link:hover .c-link__figure {
  opacity: 1;
}
.c-product-grid__link.c-product-grid__link--old-label.c-link:hover .c-link__figure .c-product-label {
  transform: translate3d(0, 0, 0);
}
.c-product-grid__link .c-product-label {
  object-fit: contain;
  width: 65%;
  left: 50%;
  transform: translate3d(-50%, -2%, 0) !important;
}
.c-product-grid__link.c-link:hover .c-link__figure {
  opacity: 1;
}
.c-product-grid__link.c-link:hover .c-link__figure .c-product-label {
  transform: translate3d(-50%, 0, 0);
}
.c-product-grid__link.c-link:hover .c-link__figure::after {
  opacity: 1;
}

.c-product-grid__figure {
  width: 100%;
  margin-bottom: 24px;
}
@media all and (min-width: 75em) {
  .c-product-grid__figure {
    margin-bottom: clamp(1.25rem, 1.6666666667vw, 1.75rem);
  }
}

.c-product-grid__caption {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
  padding: 0 30px;
}
@media all and (min-width: 75em) {
  .c-product-grid__caption {
    font-size: 0.9722222222vw;
  }
}
@media all and (min-width: 61.25em) {
  .c-product-grid__caption {
    padding: 0;
  }
}
.c-product-grid__caption > p {
  display: block;
  width: 100%;
  text-align: center;
}
.c-product-grid--alt .c-product-grid__caption {
  display: block;
}
.c-product-grid--alt .c-product-grid__caption > .c-heading::before {
  font-variant: normal;
  font-variant-caps: normal;
  text-transform: none;
  content: counter(recipe-counter, decimal-leading-zero);
  display: block;
  color: #8a7449;
  font-style: italic;
}
.supports-font-features.wf-active .c-product-grid--alt .c-product-grid__caption > .c-heading::before {
  font-variant-caps: initial;
  font-feature-settings: initial;
}
.c-product-grid--alt .c-product-grid__caption > .c-heading::before sup {
  top: 0.4em;
  font-size: 100%;
}

.c-badge {
  -webkit-transform-style: preserve-3d;
  font-size: 1.125em;
  letter-spacing: 0.05em;
  display: inline-flex;
  position: absolute;
  top: 0.875em;
  left: 0.875em;
  align-items: center;
  justify-content: center;
  width: 5.2222222222em;
  height: 5.2222222222em;
  color: #AB965D;
  text-decoration: none;
  z-index: 10;
  pointer-events: none;
}
.c-badge > * {
  -webkit-backface-visibility: hidden;
}
.c-badge::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 200ms ease;
  border: 2px solid rgba(171, 150, 93, 0.5);
  border-radius: 50%;
  content: "";
  pointer-events: none;
  width: 5.2222222222em;
  height: 5.2222222222em;
}
@media all and (max-width: 42.4375em) {
  .c-badge {
    font-size: 1em;
  }
}
.c-badge::after {
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
@media (min-width: 22.5em) and (max-width: 31.875em) {
  .c-badge {
    top: 5%;
    left: 5%;
    transform: scale(0.75);
    transform-origin: 0 0;
  }
}

.c-product-grid__product-flag {
  position: absolute;
  top: 0px;
  right: 0;
  width: 43px;
  height: 43px;
  background-color: transparent;
  color: #AB965D;
  z-index: 2;
  border-radius: 50%;
  border: 1.25px solid #AB965D;
  text-align: center;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (min-width: 48.0625em) {
  .c-product-grid__product-flag {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .c-product-grid__product-flag {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
@media all and (min-width: 61.25em) {
  .c-product-grid__product-flag {
    top: clamp(0.5729166667rem, 0.7638888889vw, 0.75rem);
    right: clamp(0.5729166667rem, 0.7638888889vw, 0.75rem);
  }
}
.c-product-grid__product-flag--lto {
  width: 65px;
  height: 65px;
  padding: 2px;
}

.c-featured-product {
  overflow: visible;
}
@media all and (max-width: 48em) {
  .c-featured-product header {
    padding-bottom: 0;
  }
}

@media all and (max-width: 29.9375em) {
  ._featured-product-min-height {
    margin-right: -1.875em;
    margin-left: -1.875em;
  }
}
@media all and (min-width: 61.25em) {
  ._featured-product-min-height {
    min-height: 17.5em;
  }
}

.c-featured-product__figure {
  padding: 0 0 25.3623188406%;
  margin-bottom: calc(0% - 2px);
}
.c-featured-product__figure .o-zorro-svg__image {
  height: 100%;
  padding: 0;
}
.c-featured-product__figure .o-zorro-svg__content {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.c-featured-product__figure .o-zorro-svg__image {
  z-index: 10;
}

@media all and (min-width: 61.25em) {
  .c-featured-product__figure {
    margin-top: -1.25em;
    margin-bottom: -1.875em;
    transform: scale(0.9);
  }
  .c-featured-product__caption {
    transform: scale(1.1111111111);
  }
}
.c-featured-product__caption {
  display: flex;
  position: absolute;
  top: 72.6785714286%;
  left: 79.3025362319%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #AB965D;
  text-decoration: none;
}
.c-featured-product__caption:focus {
  outline: none;
}
.c-featured-product__caption:focus .c-link--cta {
  border-bottom: 6px solid #54504b;
}

.c-link--cta:focus, .c-link--cta:active {
  outline: none;
  border-bottom: 6px solid #54504b;
  margin-bottom: -5px;
}

@media (max-width: 48em) {
  .c-featured-product__caption {
    -webkit-transform-style: preserve-3d;
    width: 5.2222222222em;
    height: 5.2222222222em;
  }
  .c-featured-product__caption > * {
    -webkit-backface-visibility: hidden;
  }
  .c-featured-product__caption::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 200ms ease;
    border: 2px solid rgba(171, 150, 93, 0.5);
    border-radius: 50%;
    content: "";
    pointer-events: none;
    width: 4.4444444444em;
    height: 4.4444444444em;
  }
  .c-featured-product__caption:hover::after, .c-featured-product__caption:focus::after, .c-featured-product__caption:active::after {
    transform: translate(-50%, -50%) scale(1.175);
    background: rgba(195, 170, 134, 0.1);
  }
  .c-featured-product__caption.is-active::after {
    transform: translate(-50%, -50%) scale(1.175);
    background: rgba(195, 170, 134, 0.1);
  }
  .c-featured-product__caption .c-featured-product__subheading {
    display: none;
  }
  .c-featured-product__caption .c-featured-product__heading {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0 0 0 0);
    border: 0;
    overflow: hidden;
  }
  .c-featured-product__caption .c-link--cta {
    border-bottom: 0;
  }
}
@media (min-width: 48.0625em) {
  .c-featured-product__caption {
    -webkit-transform-style: preserve-3d;
    width: 193px;
    height: 193px;
  }
  .c-featured-product__caption > * {
    -webkit-backface-visibility: hidden;
  }
  .c-featured-product__caption::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 200ms ease;
    border: 2px solid rgba(171, 150, 93, 0.5);
    border-radius: 50%;
    content: "";
    pointer-events: none;
    width: 203px;
    height: 203px;
  }
  .c-featured-product__caption:hover::after, .c-featured-product__caption:focus::after, .c-featured-product__caption:active::after {
    transform: translate(-50%, -50%) scale(0.9507389163);
    background: rgba(195, 170, 134, 0.1);
  }
  .c-featured-product__caption.is-active::after {
    transform: translate(-50%, -50%) scale(0.9507389163);
    background: rgba(195, 170, 134, 0.1);
  }
}
.c-featured-product__subheading {
  margin-bottom: 0;
}

.c-featured-product__heading,
.c-featured-product__link {
  color: inherit;
  line-height: 1;
}

.c-featured-product__heading {
  width: 100%;
  margin: 0.4375em auto 0.875em;
  padding: 0 1.125em;
}

.c-featured-recipes {
  overflow: visible;
}

@media all and (min-width: 48.0625em) {
  .c-featured-recipes__header {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

.c-featured-recipes__grid {
  background: right bottom/33.3333333333% auto no-repeat;
}
@media all and (max-width: 48em) {
  .c-featured-recipes__grid {
    background-image: none !important;
  }
}
@media (min-width: 30em) and (max-width: 48em) {
  .c-featured-recipes__grid {
    margin-top: 3em;
  }
}

.c-featured-recipes__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25em 0;
}
@media all and (max-width: 29.9375em) {
  .c-featured-recipes__content {
    align-items: center;
    text-align: center;
  }
}
@media all and (min-width: 48.0625em) {
  .c-featured-recipes__content {
    padding: 3em;
  }
}
@media (min-width: 68.75em) {
  .c-featured-recipes__content {
    padding: 4em;
  }
}

@media all and (min-width: 30em) {
  .c-featured-recipes__content {
    padding-right: 4%;
    padding-left: 4%;
  }
  .c-featured-recipes__list-item--right .c-featured-recipes__content {
    align-items: flex-start;
    padding-right: 0;
    text-align: left;
  }
  .c-featured-recipes__list-item--left .c-featured-recipes__content {
    align-items: flex-end;
    padding-left: 0;
    text-align: right;
  }
}
@media all and (min-width: 30em) and (max-width: 48em) {
  .c-featured-recipes__list-item--lg {
    flex-direction: column-reverse;
    margin-top: 1.875em;
  }
  .c-featured-recipes__list-item--lg .c-featured-recipes__content {
    align-items: center;
    text-align: center;
  }
}
@media all and (max-width: 29.9375em) {
  .c-featured-recipes__list-item {
    flex-direction: column-reverse;
    margin-top: 1.875em;
  }
}
@media (min-width: 48.0625em) and (max-width: 68.6875em) {
  .c-featured-recipes__list-item {
    padding-bottom: 6%;
  }
  .c-featured-recipes__list-item > * {
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.c-featured-recipes__list-counter {
  display: block;
  color: #AB965D;
  font-family: sans-serif;
  font-size: 1.75em;
  font-style: italic;
  letter-spacing: 0.05em;
}
@media all and (max-width: 42.4375em) {
  .c-featured-recipes__list-counter {
    font-size: 1.1875em;
  }
}

.product-recommendations {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-recommendations .feature-carousel__arrow {
  display: none;
  position: absolute;
  z-index: 3;
}
.product-recommendations .feature-carousel__arrow .c-icon {
  width: clamp(2.34375rem, 3.125vw, 3.25rem);
  height: auto;
}
@media all and (min-width: 61.25em) {
  .product-recommendations .feature-carousel__arrow {
    top: 0;
    display: block;
    aspect-ratio: 573/500;
    width: clamp(21.0416666667rem, 28.0555555556vw, 29.4375rem);
    max-width: clamp(21.0416666667rem, 28.0555555556vw, 29.4375rem);
    transform: translate3d(0, 0, 0);
  }
  .product-recommendations .feature-carousel__arrow .c-icon {
    transition: transform 0.2s var(--motion-easing);
  }
}
.product-recommendations .feature-carousel__next {
  top: 0;
  right: 0;
}
.product-recommendations .feature-carousel__next .c-icon {
  transform: translate3d(50%, 0, 0);
}
.product-recommendations .feature-carousel__next:hover .c-icon {
  transform: translate3d(100%, 0, 0);
}
.product-recommendations .feature-carousel__previous {
  top: 0;
  left: 0;
}
.product-recommendations .feature-carousel__previous .c-icon--arrow-reverse {
  transform: translate3d(-50%, 0, 0) rotate(180deg);
}
.product-recommendations .feature-carousel__previous:hover .c-icon--arrow-reverse {
  transform: translate3d(-100%, 0, 0) rotate(180deg);
}

.product-recommendations--static {
  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);
  justify-content: center;
}
.product-recommendations--static .product-recommendations__slide::after {
  display: none;
}
.product-recommendations--static .product-recommendations__slide::after {
  opacity: 0;
}
.product-recommendations--static .product-recommendations__slide:hover .product-recommendations__view {
  opacity: 1;
}
.product-recommendations--static .product-recommendations__slide:hover .product-recommendations__slide-media img, .product-recommendations--static .product-recommendations__slide:hover .product-recommendations__slide-media video {
  transform: scale3d(1.1, 1.1, 1.1);
}
.product-recommendations--static .product-recommendations__slide:hover .product-recommendations__slide-media::after {
  opacity: 1;
}

.product-recommendations__heading {
  margin-bottom: clamp(1.71875rem, 2.2916666667vw, 2.375rem);
}
.product-recommendations__heading h2 {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 0.81;
  letter-spacing: 0.08em;
  font-size: 18px;
  color: #AB965D;
  text-align: center;
}
@media all and (min-width: 48.0625em) {
  .product-recommendations__heading h2 {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .product-recommendations__heading h2 {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}

.product-recommendations__carousel {
  width: 100%;
  display: flex;
  margin-bottom: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
}

.product-recommendations__slide {
  width: 86.933333vw;
  max-width: 86.933333vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: clamp(1.4583333333rem, 1.9444444444vw, 2rem);
  text-decoration: none;
  color: var(--color-bone);
}
.product-recommendations__slide:focus {
  text-decoration: underline;
}
.product-recommendations__slide::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
@media all and (min-width: 54.375em) {
  .product-recommendations__slide {
    width: clamp(29.84375rem, 39.7916666667vw, 41.75rem);
    max-width: clamp(29.84375rem, 39.7916666667vw, 41.75rem);
  }
}
.product-recommendations__slide.swiper-slide-active::after {
  opacity: 0;
}
.product-recommendations__slide.swiper-slide-active:hover .product-recommendations__view {
  opacity: 1;
}
.product-recommendations__slide.swiper-slide-active:hover .product-recommendations__slide-media img, .product-recommendations__slide.swiper-slide-active:hover .product-recommendations__slide-media video {
  transform: scale3d(1.1, 1.1, 1.1);
}
.product-recommendations__slide.swiper-slide-active:hover .product-recommendations__slide-media::after {
  opacity: 1;
}

.product-recommendations--static .product-recommendations__carousel {
  column-gap: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  flex-direction: column;
  align-items: center;
  row-gap: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
  justify-content: center;
}
@media all and (min-width: 48.0625em) {
  .product-recommendations--static .product-recommendations__carousel {
    flex-direction: row;
    align-items: flex-start;
  }
}
.product-recommendations--static .product-recommendations__slide-title {
  opacity: 1;
  transition: none;
}
.product-recommendations--static .product-recommendations__slide {
  margin-right: 0;
  max-width: 100%;
  flex-shrink: 1;
}

.product-recommendations__slide-media {
  position: relative;
  aspect-ratio: 573/352;
  overflow: hidden;
  clip-path: polygon(0 0, 99% 0, 100% 100%, 0% 100%);
}
.product-recommendations__slide-media img, .product-recommendations__slide-media video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-out;
  transform-origin: center;
}
.product-recommendations__slide-media::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--product-recommendation-hover-overlay);
  transition: opacity 0.2s ease-out;
  opacity: 0;
  border-bottom: 8px solid var(--product-recommendation-hover-border);
}

.product-recommendations__slide-title {
  opacity: 0;
  transition: 1s var(--motion-easing);
}
.product-recommendations__slide-title p {
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
}

.product-recommendations__slide.swiper-slide-active .product-recommendations__slide-title {
  opacity: 1;
}

.product-recommendations__view {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: clamp(2.9947916667rem, 3.9930555556vw, 4.1875rem);
  height: clamp(2.9947916667rem, 3.9930555556vw, 4.1875rem);
  text-decoration: none;
}
.product-recommendations__view::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-bone);
  transform: rotate(-45deg);
  transition: transform 0.2s var(--motion-easing);
  position: absolute;
  top: 0;
  left: 0;
}
.product-recommendations__view span {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  color: var(--color-bone);
}

.c-ingredients-list {
  display: grid;
  grid-template-columns: minmax(100px, min-content) 1fr;
  grid-template-areas: "amount ingredient";
  grid-template-rows: auto;
  row-gap: clamp(1.25rem, 1.6666666667vw, 1.75rem);
  column-gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
}

.c-ingredients-list__amount {
  grid-area: amount;
  grid-row: auto;
}
.c-ingredients-list__amount > span {
  display: inline-flex;
  width: 100%;
  padding: 0.3125em 0.5em;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  background: #1E1D1A;
  color: var(--color-brand-gold);
  text-align: center;
  height: 49px;
  align-items: center;
  justify-content: center;
}
@media all and (min-width: 48.0625em) {
  .c-ingredients-list__amount > span {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .c-ingredients-list__amount > span {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}

.c-ingredients-list__ingredient {
  grid-area: ingredient;
  grid-row: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 0.6em;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
}
@media all and (min-width: 42.5em) {
  .c-ingredients-list__ingredient {
    padding-top: 0.4em;
  }
}
.c-ingredients-list__ingredient > div > div,
.c-ingredients-list__ingredient > div > a {
  display: inline;
}
.c-ingredients-list__ingredient > div > div:not(:last-child)::after,
.c-ingredients-list__ingredient > div > a:not(:last-child)::after {
  display: inline;
  content: ",";
}
@media all and (min-width: 48.0625em) {
  .c-ingredients-list__ingredient {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .c-ingredients-list__ingredient {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.c-ingredients-list__ingredient a {
  color: var(--color-bone);
}
.c-ingredients-list__ingredient a:hover, .c-ingredients-list__ingredient a:focus, .c-ingredients-list__ingredient a:active {
  color: var(--color-bone);
}

.ingredients-list--wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
}
@media all and (min-width: 61.25em) {
  .ingredients-list--wrap {
    row-gap: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
    column-gap: clamp(0.78125rem, 1.0416666667vw, 1.0625rem);
  }
}
.ingredients-list--wrap .c-heading {
  margin-bottom: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
}

.ingredients-list--wrap.ingredients-list--wrap-multiple {
  flex-direction: column;
}
@media all and (min-width: 75em) {
  .ingredients-list--wrap.ingredients-list--wrap-multiple {
    flex-direction: row;
  }
  .ingredients-list--wrap.ingredients-list--wrap-multiple .o-grid__item {
    flex: calc(50% - clamp(0.78125rem, 1.0416666667vw, 1.0625rem)) 1 0;
  }
}

.nutrition-panel {
  width: 100%;
  height: calc(100dvh - var(--header-height));
  top: var(--header-height);
  padding: 0;
}
.nutrition-panel .c-modal__close {
  right: auto;
  left: auto;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 12px;
  width: auto;
  height: auto;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  letter-spacing: 0.12em;
}
.nutrition-panel .c-modal__close:focus {
  text-decoration: none;
}
.nutrition-panel .c-modal__close:focus-visible {
  outline: none;
  text-decoration: none;
}
.nutrition-panel .c-icon--close {
  width: 11px;
  height: 11px;
  display: block;
  margin: 0;
}
.nutrition-panel .c-modal__content-container {
  width: 100%;
  background: #000;
  min-height: 100%;
}
.nutrition-panel .c-modal__content {
  width: 100%;
  padding-left: var(--page-margins-inner);
  padding-right: var(--page-margins-inner);
  background: none;
}
.nutrition-panel .c-health-attributes {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 17px;
  row-gap: 17px;
  margin-bottom: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
}
@media all and (min-width: 48.0625em) {
  .nutrition-panel .c-health-attributes {
    grid-template-columns: 1fr 1fr;
  }
}
.nutrition-panel .c-health-attribute {
  display: flex;
  align-items: center;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.12em;
  column-gap: clamp(0.625rem, 0.8333333333vw, 0.875rem);
}
@media all and (min-width: 48.0625em) {
  .nutrition-panel .c-health-attribute {
    font-size: 12.5px;
  }
}
@media all and (min-width: 75em) {
  .nutrition-panel .c-health-attribute {
    font-size: clamp(0.6510416667rem, 0.8680555556vw, 0.875rem);
  }
}

.nutrition-panel__content {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 53px;
  grid-template-areas: "nutrition" "product-qp";
}
@media all and (min-width: 48.0625em) {
  .nutrition-panel__content {
    grid-template-columns: 1fr minmax(20px, 96px) 1fr;
    grid-template-areas: "product . ." "product-qp . nutrition";
  }
}
@media all and (min-width: 75em) {
  .nutrition-panel__content {
    grid-template-columns: 1.12fr minmax(20px, 60px) 1.2fr minmax(20px, 47px) 1fr;
    grid-template-areas: "product . product-qp . nutrition";
  }
}

.nutrition-panel__product {
  grid-area: product;
  display: none;
}
@media all and (min-width: 48.0625em) {
  .nutrition-panel__product {
    display: block;
  }
}
.nutrition-panel__product h1 {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;
  font-size: 28px;
}
@media all and (min-width: 48.0625em) {
  .nutrition-panel__product h1 {
    font-size: clamp(1.875rem, 2.5vw, 2.625rem);
  }
}
@media all and (min-width: 75em) {
  .nutrition-panel__product h1 {
    font-size: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
  }
  .nutrition-panel__product h1 .u-type-subscripts__sub--tm {
    font-size: 30%;
  }
}
@media all and (min-width: 75em) {
  .nutrition-panel__product h1 {
    margin-bottom: clamp(4.1666666667rem, 5.5555555556vw, 5.8125rem);
  }
}
.nutrition-panel__product > img {
  display: none;
}
@media all and (min-width: 75em) {
  .nutrition-panel__product > img {
    display: block;
    max-width: 88.5%;
  }
}

.nutrition-panel__quality-promise {
  grid-area: product-qp;
}
.nutrition-panel__quality-promise .c-icon--health {
  height: 48px;
  width: 48px;
}
.nutrition-panel__quality-promise .c-icon--health svg {
  stroke: none;
  fill: none;
}

.nutrition-panel__quality-promise-copy {
  margin-bottom: clamp(2.8125rem, 3.75vw, 3.9375rem);
}

.nutrition-panel__quality-promise-cta {
  margin-bottom: clamp(3.6458333333rem, 4.8611111111vw, 5.0625rem);
}

.nutrition-panel__disclaimer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.nutrition-panel__disclaimer > span {
  display: block;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
  color: #BBAE96;
}
@media all and (min-width: 75em) {
  .nutrition-panel__disclaimer > span {
    font-size: 0.9722222222vw;
  }
}

.nutrition-panel__label {
  grid-area: nutrition;
  display: flex;
  justify-content: center;
}

.c-nutrition {
  justify-self: center;
  height: min-content;
  background-color: #1a1917;
  -webkit-font-smoothing: auto;
  width: 90%;
  max-width: 335px;
  padding: clamp(2.2916666667rem, 3.0555555556vw, 3.1875rem) clamp(1.5625rem, 2.0833333333vw, 2.1875rem) clamp(2.5rem, 3.3333333333vw, 3.5rem);
}
.c-nutrition > h2 {
  -webkit-font-smoothing: antialiased;
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 400;
  font-style: normal;
  font-variant: small-caps;
  text-transform: lowercase;
  font-size: 1.2em;
  line-height: 0.9230769231;
  padding-bottom: 11px;
}
.c-nutrition nav {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
  color: #AB965D;
}
@media all and (min-width: 75em) {
  .c-nutrition nav {
    font-size: 0.9722222222vw;
  }
}
.c-nutrition nav a {
  text-decoration: none;
}
@media all and (min-width: 48.0625em) {
  .c-nutrition {
    justify-self: start;
    width: 100%;
  }
}

.c-nutrition__inner {
  border: 1px #cdc9c3 solid;
  padding: 3px 7px 4px;
}

.c-nutrition-summary {
  width: 100%;
  font-family: helvetica neue, helvetica, arial, sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.c-nutrition-summary__item {
  background: #cdc9c3;
  padding: 3px;
  vertical-align: top;
}

.c-nutrition-summary__item--header {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 10px;
  font-weight: normal;
}
.c-nutrition-summary__item--header--calories {
  color: #901a1c;
}
.c-nutrition-summary__item--header--total-fat {
  color: #ab965d;
}
.c-nutrition-summary__item--header--cholesterol {
  color: #00396c;
}
.c-nutrition-summary__item--header--sodium {
  color: #006432;
}
.c-nutrition-summary__item--header--protein {
  color: #430055;
}

.c-nutrition-summary__item--body {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #cdc9c3;
  font-size: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 46px;
}
.c-nutrition-summary__item--body--calories {
  background: #901a1c;
}
.c-nutrition-summary__item--body--total-fat {
  background: #ab965d;
}
.c-nutrition-summary__item--body--cholesterol {
  background: #00396c;
}
.c-nutrition-summary__item--body--sodium {
  background: #006432;
}
.c-nutrition-summary__item--body--protein {
  background: #430055;
}

.c-nutrition-summary__item__measure {
  display: block;
  font-size: 10px;
  margin: -7px 0 0;
}

.c-nutrition-summary__divider {
  width: 2.5%;
}

.c-nutrition__padding-bottom-5px {
  padding-bottom: 5px;
}

.c-nutrition__padding-top-5px {
  padding-top: 5px;
}

.c-nutrition__margin-bottom-5px {
  margin-bottom: 5px;
}

.c-nutrition__margin-top-5px {
  margin-top: 5px;
}

.c-nutrition-facts {
  color: #cdc9c3;
  font-family: helvetica neue, helvetica, arial, sans-serif;
  font-size: 8pt;
}

.c-nutrition-facts__title {
  color: inherit;
  font-feature-settings: normal !important;
  font-variant-caps: normal;
  letter-spacing: 0;
  margin: 0;
  text-transform: none;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
}

.c-nutrition-facts__servings {
  line-height: 1.25;
}

.c-nutrition-facts__divder {
  width: 100%;
  height: 4px;
  background: #cdc9c3;
  margin: 6px 0 4px;
}

.c-nutrition-facts__divder--lg {
  height: 9px;
}

.c-nutrition-facts__22pt {
  font-size: 22pt;
}

.c-nutrition-facts__16pt {
  font-size: 16pt;
}

.c-nutrition-facts__10pt {
  font-size: 10pt;
}

.c-nutrition-facts__7pt {
  font-size: 7pt;
}

.c-nutrition-facts__table-header {
  color: inherit;
  font-feature-settings: normal !important;
  font-variant-caps: normal;
  letter-spacing: 0;
  margin: 0;
  text-transform: none;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 9px;
  font-weight: bold;
  padding: 2px 0;
}

.c-nutrition-facts__table-border-bottom {
  border-bottom: 1px #cdc9c3 solid;
}

.c-nutrition-facts__table {
  width: 100%;
}
.c-nutrition-facts__table + .c-nutrition-facts__divder {
  margin-top: -1px;
}

.c-nutrition-facts__table__row {
  border-bottom: 1px #cdc9c3 solid;
}

.c-nutrition-facts__table__item {
  padding: 1px 0;
}
.c-nutrition-facts__table__item:first-child {
  text-align: left;
}
.c-nutrition-facts__table__item:last-child {
  text-align: right;
}
.c-nutrition-facts__table__item:only-child {
  text-align: left;
  width: 100%;
}

.c-nutrition-facts__table__item--indent {
  padding-left: 16px;
}

.c-nutrition-facts__table__item--double-indent {
  padding-left: 32px;
}

.c-ornament {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
}

.c-ornament__figure {
  height: 0;
  background: center/cover no-repeat;
}
@media all and (max-width: 48em) {
  .c-ornament__figure {
    background-image: none !important;
  }
}

.c-ornament--salami-new {
  width: 34.0833333333%;
  max-width: 409px;
  transform: translate(-163.3251833741%, 0.3623188406%);
}
.c-ornament--salami-new > .c-ornament__figure {
  padding: 0 0 67.4816625917%;
  background-image: url("/img/views/product-landing/background-ingredients/salami-new.1743442783504.jpg");
}

.c-ornament--cheese-new {
  width: 31.3333333333%;
  max-width: 376px;
  transform: translate(64.6276595745%, 1.7441860465%);
}
.c-ornament--cheese-new > .c-ornament__figure {
  padding: 0 0 91.4893617021%;
  background-image: url("/img/views/product-landing/background-ingredients/cheese-new.jpg");
}

.c-ornament--heart-new {
  width: 39.1666666667%;
  max-width: 470px;
  left: 0;
  top: 0;
}
.c-ornament--heart-new > .c-ornament__figure {
  padding: 0 0 106.3829787234%;
  background-image: url("/img/landing/backtoschool/sandwichoffer.1743442783504.png");
}

.c-ornament--bento-new {
  width: 42.1666666667%;
  max-width: 506px;
  left: auto;
  right: 0;
  top: 0;
}
.c-ornament--bento-new > .c-ornament__figure {
  padding: 0 0 118.5770750988%;
  background-image: url("/img/landing/backtoschool/bentobox.png");
}

.c-ornament--paperclip-new {
  width: 25%;
  max-width: 300px;
  left: 0;
  bottom: 0;
  top: -9em;
}
.c-ornament--paperclip-new > .c-ornament__figure {
  padding: 0 0 166.6666666667%;
  background-image: url("/img/landing/backtoschool/paperclips.1743442783504.png");
}

.c-ornament--crayons-new {
  width: 20.8333333333%;
  max-width: 250px;
  left: auto;
  right: 0;
  top: -250px;
}
.c-ornament--crayons-new > .c-ornament__figure {
  padding: 0 0 200%;
  background-image: url("/img/landing/backtoschool/crayons.1743442783504.png");
}

.c-product-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-2%);
  transition: transform 200ms ease-in-out, opacity 400ms ease-out;
  opacity: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
}

.c-product-label.lazyloaded {
  opacity: 0;
}

.c-link .c-product-label {
  display: none;
}
@media all and (min-width: 61.25em) {
  .c-link .c-product-label {
    display: block;
  }
  .c-link:hover .c-product-label, .c-link:focus .c-product-label, .c-link:active .c-product-label {
    transform: translateY(0);
    opacity: 1;
  }
}

.c-testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 48em) {
  .c-testimonial:not(:first-child) {
    display: none;
  }
}

.c-testimonial__bottom {
  padding-top: 1.25em;
}

.c-testimonial__quote {
  font-style: italic;
  letter-spacing: 0.025em;
}

.c-testimonial__icon {
  margin-bottom: 1.125em;
}

.c-testimonial__author {
  display: block;
  color: #cdc9c3;
  font-size: 1.3125em;
  letter-spacing: 0.025em;
  line-height: 0.7142857143;
}

.c-testimonial__source {
  display: block;
  letter-spacing: 0.025em;
  line-height: 1.3125;
}

.c-video-badge {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.3125em 0.25em;
  color: #AB965D;
  z-index: 1;
}

.c-video-badge::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 2.8125em solid #211f1d;
  border-left: 3.3125em solid transparent;
  content: "";
  opacity: 0.8;
  z-index: -1;
}

.c-video-badge__icon {
  display: block;
  width: 1.4375em;
  height: 0.875em;
}

.c-view-nav__item {
  font-style: italic;
  text-decoration: none;
}
.c-view-nav__item.c-view-nav__previous::before {
  content: "‹ ";
}
.c-view-nav__item.c-view-nav__next::after {
  content: " ›";
}

.c-view-nav__item-divider {
  margin: 0 0.5em;
  color: #54504b;
}

/*doc
---
title: Modals
name: modals
category: Components
---

TODO: Document markup and CSS/JS separation.

- body.modal-open

- js-modal-carousel
- js-modal-carousel__item

- c-modal
- c-modal--align-top
- c-modal__content
- c-modal__content-container

- c-modal-carousel
- c-modal-carousel__item
- c-modal-carousel__nav
- c-modal-carousel__nav-item
- c-modal-carousel__nav-item--next
- c-modal-carousel__nav-item--previous

## States for .c-modal-carousel

- is-active

## States for .c-modal-carousel__item

- is-current
- is-inactive-next
- is-inactive-next-off
- is-inactive-previous
- is-inactive-previous-off
- is-not-transitionable
- is-transitionable

## States for .c-modal-carousel__nav-item

- is-inactive

*/
body.modal-open {
  overflow: hidden;
}

body.modal-open::before {
  z-index: 1100;
}
body.modal-open.modal-open--with-nav::before {
  z-index: 999;
}
body.modal-open::before {
  opacity: 0.8;
  pointer-events: auto;
}

.c-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
  transition: opacity 0.2s ease;
  outline: 0;
  opacity: 0;
  visibility: hidden;
  /* ADA specifies this as no display */
  display: none;
  z-index: 1100;
  -webkit-overflow-scrolling: touch;
}
.modal-open--with-nav .c-modal {
  z-index: 999;
}
.c-modal.is-active {
  display: flex;
  opacity: 1;
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
}
@media all and (max-width: 48em) {
  .c-modal {
    padding-top: 10px;
  }
  .c-modal.is-active {
    display: block;
  }
}

.c-modal--align-top:not(.c-modal--video) {
  align-items: flex-start;
}

.o-center-block {
  margin: 0 auto;
}

.c-modal__content-container {
  position: relative;
  width: 90%;
  margin: 0 auto;
}
@media all and (min-width: 48.0625em) {
  .c-modal__content-container {
    margin: 0;
  }
}

.c-modal__close {
  right: auto;
  left: auto;
  top: -30px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 12px;
  width: auto;
  height: auto;
  z-index: 1005;
  position: absolute;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 11.5px;
  letter-spacing: 0.12em;
  color: #F7EEDF;
}
.c-modal__close:focus {
  text-decoration: none;
}
.c-modal__close:focus-visible {
  outline: none;
  text-decoration: none;
  color: #F7EEDF;
}
.c-modal__close:hover, .c-modal__close:focus {
  color: #F7EEDF;
}

.c-icon--close {
  width: 11px;
  height: 11px;
  display: block;
  margin: 0;
}

.c-modal__close--white {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E_buildSvg('%3Cline x1='0' y1='0' x2='13' y2='13' stroke='%23fff' stroke-width='2px'/%3E%3Cline x1='13' y1='0' x2='0' y2='13' stroke='%23fff' stroke-width='2px'/%3E', 13, 13)%3C/svg%3E");
}

.c-modal__content {
  position: relative;
  min-height: 3em;
  padding: 55px 20px 20px;
  background: #34302B;
}
@media all and (min-width: 61.25em) {
  .c-modal__content {
    padding: 55px 40px 40px;
  }
}

.c-modal--video {
  padding: 1.5em 0;
  justify-content: center;
}

.c-modal--video .c-modal__content-container {
  padding-top: 40px;
}
@media (orientation: landscape) {
  .c-modal--video .c-modal__content-container {
    max-width: calc((100vh - 71px) * 1.7777777778);
  }
}
@media (orientation: landscape) and (max-width: 48em) {
  .c-modal--video .c-modal__content-container {
    max-width: calc((100vh - 123px) * 1.7777777778);
  }
}
@media (orientation: portrait) and (max-width: 48em) {
  .c-modal--video .c-modal__content-container {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.c-modal--video .c-modal__content-container .o-video__media {
  aspect-ratio: 16/9;
}
.c-modal--video .c-modal__content-container .o-video__media iframe {
  width: 100%;
  height: 100%;
}
.c-modal--video .c-modal__content-container .c-modal__content {
  padding: 0;
  background: transparent;
}

#sweetbs-modal {
  -webkit-font-smoothing: antialiased;
}
#sweetbs-modal .logo {
  width: 15vw;
  min-width: 150px;
}
#sweetbs-modal .c-icon--close {
  border-radius: 50%;
  color: #fff;
  border: 2px solid white;
  display: block;
  height: 35px;
  width: 35px;
  padding: 10px;
}
#sweetbs-modal h1 {
  font-size: 1.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 102%;
  font-family: "minion-pro-display";
  color: #AB965D;
}
#sweetbs-modal h1 .u-small-caps__sub {
  margin-right: 0;
  padding-right: 0.4em;
}
#sweetbs-modal h1 .u-small-caps__sub:after {
  margin-bottom: 0.44em;
  transform: translateY(50%);
}
#sweetbs-modal p.c-copy {
  font-family: "minion-pro";
  font-size: 1.3125em;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.742px;
  color: #FBF6EA;
}
#sweetbs-modal .c-copy--xxs {
  opacity: 0.66;
}
#sweetbs-modal .c-copy--xxs a {
  color: #FBF6EA;
}
#sweetbs-modal .c-button {
  font-family: "myriad-pro";
}
#sweetbs-modal .c-button span {
  padding: 11px 25px 12px 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.c-copy--bts {
  font-family: "myriad-pro", "Arial", sans-serif;
}

picture {
  z-index: 0;
}

.middle-text {
  z-index: 99;
}

.circle {
  display: block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #FFF;
}
.circle.red-bg {
  background-color: #ae1f19;
}
.circle > .c-icon--close {
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}

.c-modal__content-container.rainbow-border {
  border: 8px solid transparent;
}
.c-modal__content-container.rainbow-border:after {
  position: absolute;
  top: -8px;
  bottom: 0;
  left: -8px;
  right: -8px;
  background: rgba(0, 0, 0, 0) linear-gradient(-45deg, #57257d 0%, #57257d 23%, #57257d 23%, #ae1f19 23%, #ae1f19 39%, #123ca6 39%, #123ca6 39%, #123ca6 57%, #123ca6 57%, #187816 57%, #187816 79%, #57257d 79%, #57257d 88%, #ae1f19 88%);
  background-position: top left;
  background-size: contain;
  background-repeat: repeat-x;
  content: "";
  z-index: -1;
}

.c-modal.hellofresh-modal .c-modal__content-container {
  position: relative;
  background: #000;
}
.c-modal.hellofresh-modal .c-modal__content-container::before {
  content: "";
  position: absolute;
  border: 2px solid #A6192E;
  top: 35px;
  right: 35px;
  bottom: 35px;
  left: 35px;
}
.c-modal.hellofresh-modal .c-modal__content {
  background: transparent;
  padding: 50px;
}
@media all and (min-width: 48.0625em) {
  .c-modal.hellofresh-modal .c-modal__content {
    padding: 70px;
  }
}
.c-modal.hellofresh-modal .c-modal__close {
  top: 3em;
  right: 3em;
}

body.modal-open.landing-hummus::after {
  opacity: 0.45;
}

/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
@keyframes come-in {
  to {
    opacity: 1;
  }
}
@keyframes come-in-scale {
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
@keyframes slight-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}
.announcement-modal {
  max-width: clamp(32.2916666667rem, 43.0555555556vw, 45.1875rem);
}
.announcement-modal .c-modal__close {
  top: 20px;
  right: 20px;
}
.announcement-modal .c-modal__content {
  background: #000;
  padding: clamp(3.2291666667rem, 4.3055555556vw, 4.5rem) clamp(1.5625rem, 2.0833333333vw, 2.1875rem) clamp(2.0833333333rem, 2.7777777778vw, 2.875rem);
}
.announcement-modal .announcement-modal__content {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  border: 1px solid #A6192E;
  padding: 30px;
}
@media all and (min-width: 48.0625em) {
  .announcement-modal .announcement-modal__content {
    padding: clamp(3.125rem, 4.1666666667vw, 4.375rem) clamp(5.2083333333rem, 6.9444444444vw, 7.25rem);
  }
}
.announcement-modal h1 {
  font-variant-numeric: lining-nums proportional-nums;
  font-variant: normal;
  font-family: "Minion Pro";
  font-size: clamp(1.4583333333rem, 1.9444444444vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
}
.announcement-modal .c-copy {
  text-align: center;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 16px;
}
.announcement-modal .c-copy .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 48.0625em) {
  .announcement-modal .c-copy {
    font-size: 18px;
  }
}
@media all and (min-width: 75em) {
  .announcement-modal .c-copy {
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}
.announcement-modal .c-hr {
  margin-bottom: 0;
}

.c-brochure {
  display: block;
  width: 100%;
  height: 550px;
  max-height: 70vh;
  margin: 0 auto;
}
@media all and (max-width: 48em) {
  .c-brochure {
    width: 90%;
  }
}

[role=tabpanel]:not(.selected) {
  display: none;
}

.c-tabs__nav {
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 1em;
}

.c-tabs__nav-item {
  flex: 1 1 auto;
  line-height: 1.1538461538;
  text-align: center;
}
.c-tabs__nav-item:not(:first-child) {
  padding-left: 1em;
}
@media (max-width: 35em) {
  .c-tabs__nav-item {
    flex: 1 1 25%;
  }
  .c-tabs__nav-item:not(:first-child) {
    padding-left: 0.1em;
  }
}
@media all and (max-width: 29.9375em) {
  .c-tabs__nav-item {
    font-size: 0.75em;
  }
}

.c-tabs__nav-link {
  opacity: 0.6;
}
.c-tabs__nav-link[aria-selected=true] {
  color: #AB965D;
  opacity: 1;
}

.c-tabs__content {
  position: relative;
}

.c-tabs__pane {
  height: 0;
  transition: none;
  opacity: 0;
  visibility: hidden;
}
.c-tabs__pane.selected {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.js-show-more__item.is-hidden,
.js-show-more__button.is-hidden {
  display: none;
}

#onetrust-banner-sdk * {
  box-sizing: border-box !important;
}

.onetrust .ot-sdk-show-settings, .onetrust .optanon-show-settings {
  letter-spacing: 0.01em;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "minion-pro", "times new roman", times, georgia, serif;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1.375;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  height: auto;
  text-decoration: underline;
  white-space: normal;
  padding: 0;
  cursor: pointer;
  color: #ab965d;
  background-color: inherit;
  font-size: inherit !important;
}
.onetrust .ot-sdk-show-settings:hover, .onetrust .optanon-show-settings:hover {
  color: #c3aa86;
  background-color: inherit;
}
.onetrust .otnotice-section-content span, .onetrust strong {
  background: inherit !important;
}
.onetrust .otnotice-menu {
  margin-bottom: 50px;
}

#onetrust-banner-sdk #onetrust-policy-text, #onetrust-banner-sdk .ot-b-addl-desc, #onetrust-banner-sdk .ot-gv-list-handler {
  font-size: 14px !important;
  line-height: inherit !important;
  text-align: center !important;
  max-width: 80%;
}
@media all and (min-width: 72.5em) {
  #onetrust-banner-sdk #onetrust-policy-text, #onetrust-banner-sdk .ot-b-addl-desc, #onetrust-banner-sdk .ot-gv-list-handler {
    max-width: none;
    text-align: left !important;
  }
}

.onetrust-pc-sdk .ot-pc-footer-logo {
  background: black !important;
}

.onetrust-pc-btn-handler {
  background: #ab965d !important;
  color: white !important;
  text-decoration: none !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
  font-size: 16px !important;
  text-transform: lowercase !important;
  font-variant-caps: small-caps !important;
  font-feature-settings: "smcp";
  display: flex !important;
  flex-direction: row-reverse !important;
  font-feature-settings: "smcp";
}

#onetrust-pc-btn-handler {
  font-size: 16px !important;
  white-space: nowrap !important;
  max-width: none !important;
  text-align: center;
  text-transform: lowercase !important;
  font-variant-caps: small-caps !important;
  font-feature-settings: "smcp";
}

.onetrust-button-group {
  width: auto;
}

#onetrust-button-group {
  display: flex !important;
  flex-direction: column !important;
}
@media all and (min-width: 48.0625em) {
  #onetrust-button-group {
    flex-direction: row-reverse !important;
  }
}

#onetrust-accept-btn-handler {
  justify-content: center !important;
  white-space: nowrap !important;
}

#onetrust-banner-sdk .banner-actions-container {
  width: auto !important;
}

#onetrust-pc-sdk h3, #onetrust-pc-sdk h4, #onetrust-pc-sdk h5 {
  font-size: 16px !important;
  text-transform: lowercase !important;
  font-variant-caps: small-caps !important;
}

#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
  color: #ab965d !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  background-color: rgb(206, 194, 160) !important;
  border: 1px solid #ab965d !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
  background-color: #ab965d !important;
  border-color: #ab965d !important;
}

#onetrust-pc-sdk .ot-cat-item > button:focus, #onetrust-pc-sdk .ot-acc-cntr > button:focus, #onetrust-pc-sdk li > button:focus {
  outline: #ab965d solid 2px !important;
}

#onetrust-pc-sdk .ot-host-item > button:focus, #onetrust-pc-sdk .ot-ven-item > button:focus {
  border: 2px solid #ab965d !important;
}

#onetrust-pc-sdk .ot-host-desc, #onetrust-pc-sdk .ot-host-info {
  font-size: 14px !important;
}

#onetrust-pc-sdk #filter-btn-handler {
  background-color: #ab965d !important;
}

.ot-pc-footer-logo {
  background: black !important;
}

#onetrust-button-group-parent.ot-sdk-three.ot-sdk-columns {
  position: static;
  transform: none !important;
  width: auto !important;
  flex-shrink: 0;
}

@media only screen and (min-width: 897px) and (max-width: 1023px) {
  #onetrust-banner-sdk.vertical-align-content #onetrust-button-group-parent {
    position: static !important;
    top: inherit !important;
    left: inherit !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1267px) {
  #onetrust-banner-sdk #onetrust-button-group {
    margin-left: 2em !important;
  }
}
@media only screen and (min-width: 769px) {
  #onetrust-banner-sdk #onetrust-button-group {
    margin-right: 0 !important;
  }
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  position: static !important;
}
@media only screen and (min-width: 426px) and (max-width: 896px) {
  #onetrust-banner-sdk #onetrust-button-group-parent {
    margin-left: 1em !important;
  }
}

#onetrust-policy-text {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

#onetrust-policy {
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 1em !important;
}
@media all and (min-width: 72.5em) {
  #onetrust-policy {
    justify-content: flex-start;
  }
}

#onetrust-banner-sdk .ot-sdk-row {
  justify-content: center;
  display: flex !important;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
@media all and (min-width: 72.5em) {
  #onetrust-banner-sdk .ot-sdk-row {
    flex-direction: row;
    padding: 1em !important;
  }
}

#onetrust-group-container.ot-sdk-eight.ot-sdk-columns {
  width: auto !important;
  display: flex;
  justify-content: center;
}
@media all and (min-width: 72.5em) {
  #onetrust-group-container.ot-sdk-eight.ot-sdk-columns {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1280px) {
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
    margin-left: 2em;
    padding: 0;
  }
}

@media only screen and (min-width: 897px) and (max-width: 1023px) {
  #onetrust-button-group-parent {
    margin-left: 2em !important;
  }
}

#onetrust-policy-text a:focus,
a.privacy-notice-link:focus {
  outline: none !important;
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
}
.cursor.active {
  visibility: visible;
}
.cursor.active.disabled-by-child-cursor {
  visibility: hidden !important;
}
.cursor.disable-cursor-scroll {
  display: none !important;
}

.cursor__image {
  transform: translate3d(-50%, -50%, 0);
}

.cursor-disabled {
  cursor: none;
}

#cursor-arrow {
  color: #AB965D;
}
#cursor-arrow .cursor__content {
  transform: translate3d(0, -50%, 0);
  top: 0;
  position: absolute;
  left: 100%;
  min-width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#cursor-arrow .cursor__headline {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  color: #F7EEDF;
}
@media all and (min-width: 48.0625em) {
  #cursor-arrow .cursor__headline {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  #cursor-arrow .cursor__headline {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}

@media (hover: none) {
  #cursor-drag-drop {
    display: none !important;
  }
}
#cursor-drag-drop .cursor__image {
  opacity: 1;
}
#cursor-drag-drop .cursor__image.cursor__image--moving {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
#cursor-drag-drop.is-moving .cursor__image {
  opacity: 0;
}
#cursor-drag-drop.is-moving .cursor__image.cursor__image--moving {
  opacity: 1;
}

.c-hero {
  position: relative;
  background: center/cover no-repeat;
  overflow: hidden;
}
.c-hero .c-button--box:focus, .c-hero .c-button--box:active {
  outline: none;
}
.c-hero .c-button--box:focus-within::after, .c-hero .c-button--box:active::after {
  content: "-";
  display: none;
}

.c-hero__preserve-aspect-ratio {
  width: 100%;
  height: 0;
}

.c-hero__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}
.c-hero__background--brown-sugar {
  top: auto;
  bottom: 0;
  transform: translate(-50%, 0);
}
@media all and (max-width: 48em) {
  .c-hero__background--brown-sugar {
    max-width: none;
  }
}

.c-hero__content {
  position: relative;
  z-index: 99;
  padding: 4em 1.875em;
  color: #e0e0e0;
  z-index: 20;
}
.c-hero__content .c-hero__subheading,
.c-hero__content .c-hero__heading {
  color: #fff !important;
}
.c-hero__content--brown-sugar {
  position: absolute;
  top: 0;
  transform: translateY(0);
}

.is-active .c-hero__content {
  z-index: 0;
}

.c-hero__scroll-button {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
}
body:not(.has-video-playing) .c-hero__scroll-button {
  z-index: 60;
}

.c-hero__disclaimer {
  width: 100%;
  margin-top: 1.25em;
  text-align: right;
}
@media all and (max-width: 48em) {
  .c-hero__disclaimer {
    padding-right: 1.25em;
  }
}

@media all and (max-width: 48em) {
  .c-hero__preserve-aspect-ratio {
    position: absolute;
    top: 0;
    left: 50%;
    width: 166.7673716012vw;
    height: 100%;
    transform: translateX(-50%);
  }
}
@media all and (min-width: 48.0625em) {
  .c-hero__preserve-aspect-ratio {
    padding: 0 0 59.9637681159%;
  }
  .c-hero__content {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 99;
    padding-top: 2.75em;
    padding-bottom: 2.75em;
    transform: translateY(-50%);
  }
  .c-hero__content.c-hero__content--bottom-align {
    top: 70%;
  }
  .c-hero__content--brown-sugar {
    top: 0;
    transform: translateY(0);
  }
  .is-active .c-hero__content {
    z-index: 0;
  }
}
.c-hero--red-outline::after {
  position: absolute;
  top: 0.875em;
  right: 0.875em;
  bottom: 0.875em;
  left: 0.875em;
  border: 4px solid #A6192E;
  content: "";
  z-index: 10;
  pointer-events: none;
}

@media all and (min-width: 48.0625em) {
  .c-hero--red-outline\@md::after {
    position: absolute;
    top: 0.875em;
    right: 0.875em;
    bottom: 0.875em;
    left: 0.875em;
    border: 4px solid #A6192E;
    content: "";
    z-index: 10;
    pointer-events: none;
  }
}
.c-hero--pink-outline::after {
  position: absolute;
  top: 0.875em;
  right: 0.875em;
  bottom: 0.875em;
  left: 0.875em;
  border: 4px solid #e371ac;
  content: "";
  z-index: 10;
  pointer-events: none;
}

@media all and (min-width: 48.0625em) {
  .c-hero--pink-outline\@md::after {
    position: absolute;
    top: 0.875em;
    right: 0.875em;
    bottom: 0.875em;
    left: 0.875em;
    border: 4px solid #e371ac;
    content: "";
    z-index: 10;
    pointer-events: none;
  }
}
.c-hero__logo {
  width: 78px;
  height: 53px;
}

.c-hero__header {
  margin-bottom: 1.25em;
  pointer-events: none;
}
.c-hero__header a,
.c-hero__header button {
  pointer-events: auto;
}

.c-hero__header-button {
  z-index: -1;
}

@media all and (min-width: 48.0625em) {
  .c-hero__header--push-up {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 4em 1.875em;
    transform: translate(-50%, -50%);
  }
}
@media all and (min-width: 56.25em) {
  .c-hero__header--push-up {
    top: 33.3333333333%;
  }
}

@media all and (min-width: 48.0625em) {
  .c-hero__header--push-down {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 4em 1.875em;
    transform: translate(-50%, -33.333%);
  }
}
@media all and (min-width: 56.25em) {
  .c-hero__header--push-down {
    top: 66.6666666667%;
  }
}

.c-hero__header--tint::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 200%;
  transform: translate(-50%, -50%);
  background: radial-gradient(ellipse at center, #0d0806 0%, rgba(13, 8, 6, 0) 100%);
  content: "";
  opacity: 0.5;
  z-index: -1;
  pointer-events: none;
}

.c-hero__heading {
  margin-top: 0.1363636364em;
  margin-bottom: 0.2272727273em;
}

.c-hero__subheading {
  font-size: 0.9375em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-shadow: -1px -1px 13px rgb(150, 150, 150);
}
.c-hero__subheading .c-hero__subheading-pipe {
  color: #524e4a;
}

.c-hero__banner {
  position: relative;
  z-index: 11;
  max-width: 535px;
  width: 60%;
  top: 15px;
  left: -3.613369467%;
}
@media all and (min-width: 48.0625em) {
  .c-hero__banner {
    position: absolute;
    top: 3.75em;
    width: 48.4191508582%;
    left: 0.875em;
    transform: translateX(-2.8496042216%);
  }
}
@media (min-width: 1200px) {
  .c-hero__banner {
    margin-left: 0;
  }
}

.u-animation-delay-100ms {
  animation-delay: 100ms;
}

.u-animation-delay-1100ms {
  animation-delay: 1100ms;
}

.u-animation-bounce {
  animation: bounce 2000ms ease infinite;
}

.u-background-color-black {
  background-color: #000;
}

.u-background-color-black-2 {
  background-color: #211f1d;
}

.u-background-color-white-2 {
  background-color: #f1f1ee;
}

.u-background-color-gray-2 {
  background-color: #a7a49e;
}

.u-background-color-gray-5 {
  background-color: #404040;
}

.u-background-color-pink-1 {
  background-color: #e371ac;
}

.u-background-color-red-1 {
  background-color: #A6192E;
}

.u-background-color-gold-1 {
  background-color: #8a7449;
}

.u-background-color-gold-7 {
  background-color: rgba(171, 150, 97, 0.8);
}

.u-border-left-black {
  border-left: 4px solid #000;
}

.u-border-bottom-gray {
  border-bottom: 1px solid #272727;
}

.u-border-left-gray {
  border-left: 1px solid #272727;
}

.u-border-gold {
  border: 1px solid #AB965D;
}

.u-border-bottom-gold {
  border-bottom: 1px solid #AB965D;
}

.u-border-vertical-gold-light {
  border-top: 1px solid #2f2b21;
  border-bottom: 1px solid #2f2b21;
}

.u-border-top-gold-light {
  border-top: 1px solid #2f2b21;
}

.u-border-right-gold-light {
  border-right: 1px solid #2f2b21;
}

.u-border-bottom-gold-light {
  border-bottom: 1px solid #2f2b21;
}

.u-border-left-gold-light {
  border-left: 1px solid #2f2b21;
}

.u-border-vertical-gold-6 {
  border-top: 1px solid #3f3727;
  border-bottom: 1px solid #3f3727;
}

.u-border-top-red {
  border-top: 1px solid #A6192E;
}

.u-border-right-red {
  border-right: 1px solid #A6192E;
}

.u-border-bottom-red {
  border-bottom: 1px solid #A6192E;
}

.u-border-left-red {
  border-left: 1px solid #A6192E;
}

.u-border-vertical-red {
  border-top: 1px solid #A6192E;
  border-bottom: 1px solid #A6192E;
}

@media all and (min-width: 48.0625em) {
  .u-border-right-red\@md {
    border-right: 1px solid #A6192E;
  }
}
@media all and (max-width: 56.1875em) {
  .u-border-bottom-red\@lt-900 {
    border-bottom: 1px solid #A6192E;
  }
}
@media all and (min-width: 30em) {
  .u-border-left-red\@sm {
    border-left: 1px solid #A6192E;
  }
}
.u-border-top-pink {
  border-top: 3px solid rgba(227, 113, 172, 0.5);
}

.u-border-bottom-pink {
  border-bottom: 3px solid rgba(227, 113, 172, 0.5);
}

@media all and (min-width: 30em) {
  .u-border-bottom-gray\@sm {
    border-bottom: 1px solid #272727;
  }
}
@media all and (min-width: 30em) {
  .u-border-left-gray\@sm {
    border-left: 1px solid #272727;
  }
}
@media all and (min-width: 48.0625em) {
  .u-border-top-red-thick\@md {
    border-top: 3px solid #A6192E;
  }
}
.u-border-bottom-red-thick {
  border-bottom: 3px solid #A6192E;
}

.u-color-primary {
  color: #a7a49e;
}

.u-color-secondary {
  color: #AB965D;
}

.u-color-black-3 {
  color: #2d2a28;
}

.u-color-black-1 {
  color: #000;
}

.u-color-white {
  color: #fff;
}

.u-color-white-2 {
  color: #f1f1ee;
}

.u-color-white-3 {
  color: #cec3b7;
}

.u-color-brown-3 {
  color: #54504b;
}

.u-color-brown-4 {
  color: #332518;
}

.u-color-gray-1 {
  color: #827a70;
}

.u-color-gray-2 {
  color: #a7a49e;
}

.u-color-gray-3 {
  color: #cdc9c3;
}

.u-color-gray-4 {
  color: #e0e0e0;
}

.u-color-gray-7 {
  color: #525050;
}

.u-color-gray-8 {
  color: #d2d2d0;
}

.u-color-gray-9 {
  color: #7b7c7d;
}

.u-color-gray-10 {
  color: #e8e4e0;
}

.u-color-gray-11 {
  color: #474747;
}

.u-color-gray-12 {
  color: #757575;
}

.u-color-gray-13 {
  color: #eef3e9;
}

.u-color-gold-1 {
  color: #8a7449;
}

.u-color-gold-2 {
  color: #AB965D;
}

.u-color-gold-8 {
  color: #8d7249;
}

.u-color-red-1 {
  color: #A6192E;
}

.u-color-red-2 {
  color: #a9271b;
}

.u-color-pink-1 {
  color: #e371ac;
}

a.u-color-pink-1:hover, a.u-color-pink-1:focus, a.u-color-pink-1:active {
  color: #e371ac;
  transition: opacity 0.25s ease;
  opacity: 0.85;
}

.u-color-primary--links a {
  color: #a7a49e;
}

.u-columns-2 {
  columns: 2;
}

@media all and (min-width: 30em) {
  .u-columns-2\@sm {
    columns: 2;
  }
}
@media all and (min-width: 48.0625em) {
  .u-columns-2\@md {
    columns: 2;
  }
}
.u-column-gap-sm {
  column-gap: 1.875em;
}

.u-column-gap-md {
  column-gap: 3em;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-depth-0 {
  z-index: 0;
}

.u-depth-1 {
  z-index: 1;
}

.u-depth-2 {
  z-index: 2;
}

.u-depth-3 {
  z-index: 3;
}

.u-depth-4 {
  z-index: 4;
}

.u-depth-5 {
  z-index: 5;
}

.u-depth-6 {
  z-index: 6;
}

.u-depth-7 {
  z-index: 7;
}

.u-depth-8 {
  z-index: 8;
}

.u-depth-9 {
  z-index: 9;
}

.u-depth-10 {
  z-index: 10;
}

.u-depth-11 {
  z-index: 11;
}

.u-depth-12 {
  z-index: 12;
}

.u-depth-13 {
  z-index: 13;
}

.u-depth-14 {
  z-index: 14;
}

.u-depth-15 {
  z-index: 15;
}

.u-depth-16 {
  z-index: 16;
}

.u-depth-17 {
  z-index: 17;
}

.u-depth-18 {
  z-index: 18;
}

.u-depth-19 {
  z-index: 19;
}

.u-depth-20 {
  z-index: 20;
}

.u-depth-21 {
  z-index: 21;
}

.u-depth-22 {
  z-index: 22;
}

.u-depth-23 {
  z-index: 23;
}

.u-depth-24 {
  z-index: 24;
}

.u-depth-25 {
  z-index: 25;
}

.u-depth-26 {
  z-index: 26;
}

.u-depth-27 {
  z-index: 27;
}

.u-depth-28 {
  z-index: 28;
}

.u-depth-29 {
  z-index: 29;
}

.u-depth-30 {
  z-index: 30;
}

.u-depth-0 {
  z-index: 0;
}

.u-depth\(-1\) {
  z-index: -1;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-flex-start {
  align-items: flex-start;
}

.u-align-items-flex-end {
  align-items: flex-end;
}

.u-align-self-center {
  align-self: center;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-flex-start {
  justify-content: flex-start;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-nowrap {
  flex-wrap: nowrap;
}

@media all and (min-width: 48.0625em) {
  .u-flex-nowrap\@md {
    flex-wrap: nowrap;
  }
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-order-1 {
  order: 1;
}

.u-order-2 {
  order: 2;
}

.u-order-3 {
  order: 3;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-2 {
  flex: 2;
}

.u-flex-3 {
  flex: 3;
}

.u-flex-4 {
  flex: 4;
}

.u-flex-5 {
  flex: 5;
}

.u-flex-6 {
  flex: 6;
}

.u-flex-shrink {
  flex: 0 0 auto;
}

.u-flex-grow {
  flex: 1 1 auto;
}

.u-flex-1-100 {
  flex: 1 100%;
}

.u-flex-1-54 {
  flex: 1 54%;
}

.u-flex-1-46 {
  flex: 1 46%;
}

.u-flex-direction-column {
  flex-direction: column;
}

@media all and (min-width: 65em) {
  .u-flex-direction-column\@1040 {
    flex-direction: column;
  }
}
@media all and (min-width: 48.0625em) {
  .u-flex-direction-row\@md {
    flex-direction: row;
  }
}
@media all and (min-width: 75em) {
  .u-flex-direction-row\@xl {
    flex-direction: row;
  }
}
@media all and (min-width: 48.0625em) {
  .u-flex-wrap-wrap\@md {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 61.1875em) {
  .u-flex-direction-column-reverse\@lt-lg {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 48em) {
  .u-flex-direction-column-reverse\@lt-md {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 63.9375em) {
  .u-justify-content-center\@lt-1024 {
    justify-content: center;
  }
}
@media all and (max-width: 48em) {
  .u-justify-content-space-evenly\@lt-md {
    justify-content: space-evenly;
  }
}
@media all and (max-width: 38.6875em) {
  .u-flex-1-1-auto\@lt-620 {
    flex: 1 1 auto !important;
  }
}
@media all and (max-width: 29.9375em) {
  .u-flex-1\@lt-sm {
    flex: 1;
  }
}
@media all and (min-width: 48.0625em) {
  .u-flex-direction-row-reverse\@md {
    flex-direction: row-reverse;
  }
}
@media all and (min-width: 48.0625em) {
  .u-flex-2\@md {
    flex: 2;
  }
}
@media all and (min-width: 48.0625em) {
  .u-flex-4\@md {
    flex: 4;
  }
}
@media all and (min-width: 48.0625em) {
  .u-flex-3\@md {
    flex: 3;
  }
}
@media all and (max-width: 63.9375em) {
  .u-align-items-center\@lt-1024 {
    align-items: center;
  }
}
.u-font-size-100\% {
  font-size: 100%;
}

.u-font-size-xs {
  font-size: 0.625em;
}

.u-font-size-13 {
  font-size: 0.8125em;
}

.u-font-size-14 {
  font-size: 0.875em;
}

.u-font-size-18 {
  font-size: 1.125em;
}

.u-font-size-21 {
  font-size: 1.3125em;
}

.u-font-size-25 {
  font-size: 1.5625em;
  letter-spacing: 0.03em;
}

.u-font-size-30 {
  font-size: 30px;
}

.u-font-style-italic {
  font-style: italic;
}

.u-font-style-normal {
  font-style: normal;
}

.u-text-decoration-none {
  text-decoration: none;
}

.u-text-decoration-underline {
  text-decoration: underline;
}

.u-nowrap {
  white-space: nowrap;
}

.u-font-weight-600 {
  font-weight: 600;
}

.u-font-weight-bold {
  font-weight: bold;
}

.u-font-weight-400 {
  font-weight: 400;
}

.u-line-height-0\.8 {
  line-height: 0.8;
}

.u-line-height-0 {
  line-height: 0;
}

.u-line-height-1 {
  line-height: 1;
}

.u-margin-horizontal-auto {
  margin-right: auto;
  margin-left: auto;
}

.u-margin-horizontal-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.u-margin-horizontal-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.u-margin-horizontal-3 {
  margin-right: 3px;
  margin-left: 3px;
}

.u-margin-horizontal-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.u-margin-horizontal-sm {
  margin-right: 1.875em;
  margin-left: 1.875em;
}

.u-margin-vertical-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-margin-vertical-xxs {
  margin-top: 0.625em;
  margin-bottom: 0.625em;
}

.u-margin-vertical-xs {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.u-margin-vertical-sm {
  margin-top: 1.875em;
  margin-bottom: 1.875em;
}

.u-margin-vertical-md {
  margin-top: 3em;
  margin-bottom: 3em;
}

.u-margin-vertical-lg {
  margin-top: 4em;
  margin-bottom: 4em;
}

.u-margin-vertical-xl {
  margin-top: 5em;
  margin-bottom: 5em;
}

.u-margin-vertical-xxl {
  margin-top: 10.625em;
  margin-bottom: 10.625em;
}

.u-margin-top-3 {
  margin-top: 3px;
}

.u-margin-top-xxs {
  margin-top: 0.625em;
}

.u-margin-top-xs {
  margin-top: 1.25em;
}

.u-margin-top-sm {
  margin-top: 1.875em;
}

.u-margin-top-md {
  margin-top: 3em;
}

.u-margin-top-lg {
  margin-top: 4em;
}

.u-margin-top-xl {
  margin-top: 5em;
}

.u-margin-top-xxl {
  margin-top: 10.625em;
}

.u-margin-right-3 {
  margin-right: 3px;
}

.u-margin-right-auto {
  margin-right: auto;
}

.u-margin-right-xxs {
  margin-right: 0.625em;
}

.u-margin-right-xs {
  margin-right: 1.25em;
}

.u-margin-bottom-0 {
  margin-bottom: 0;
}

.u-margin-bottom-xxxs {
  margin-bottom: 3px;
}

.u-margin-bottom-xxs {
  margin-bottom: 0.625em;
}

.u-margin-bottom-xs {
  margin-bottom: 1.25em;
}

.u-margin-bottom-sm {
  margin-bottom: 1.875em;
}

.u-margin-bottom-md {
  margin-bottom: 3em;
}

.u-margin-bottom-lg {
  margin-bottom: 4em;
}

.u-margin-bottom-xl {
  margin-bottom: 5em;
}

.u-margin-bottom-xxl {
  margin-bottom: 10.625em;
}

.u-margin-left-auto {
  margin-left: auto;
}

.u-margin-left-0 {
  margin-left: 0;
}

.u-margin-left-xxs {
  margin-left: 0.625em;
}

.u-margin-left-xs {
  margin-left: 1.25em;
}

.u-margin-left-40px {
  margin-left: 40px;
}

.u-margin-left-15px {
  margin-left: 15px;
}

@media all and (max-width: 61.1875em) {
  .u-margin-vertical-xs\@lt-lg {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
}
@media all and (max-width: 61.1875em) {
  .u-margin-top-md\@lt-lg {
    margin-top: 3em;
  }
}
@media all and (max-width: 56.1875em) {
  .u-margin-vertical-xs\@lt-900 {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-vertical-lg\@lt-md {
    margin-top: 4em;
    margin-bottom: 4em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-vertical-md\@lt-md {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}
@media all and (max-width: 56.1875em) {
  .u-margin-top-sm\@lt-900 {
    margin-top: 1.875em;
  }
}
@media all and (max-width: 56.1875em) {
  .u-margin-top-md\@lt-900 {
    margin-top: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-bottom-sm\@lt-md {
    margin-bottom: 3em;
  }
}
@media all and (max-width: 56.1875em) {
  .u-margin-bottom-md\@lt-900 {
    margin-bottom: 3em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-margin-bottom-md\@lt-sm {
    margin-bottom: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-bottom-xl\@lt-md {
    margin-bottom: 5em;
  }
}
@media all and (max-width: 54.3125em) {
  .u-margin-bottom-sm\@lt-870 {
    margin-bottom: 1.875em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-horizontal-5\@lt-md {
    margin-right: 5px;
    margin-left: 5px;
  }
}
@media all and (max-width: 48em) {
  .u-margin-right-xs\@lt-md {
    margin-right: 1.25em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-bottom-xxs\@lt-md {
    margin-bottom: 0.625em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-bottom-xs\@lt-md {
    margin-bottom: 1.25em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-bottom-sm\@lt-md {
    margin-bottom: 1.875em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-bottom-md\@lt-md {
    margin-bottom: 3em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-bottom-sm\@lt-1024 {
    margin-bottom: 1.875em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-bottom-md\@lt-1024 {
    margin-bottom: 3em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-bottom-lg\@lt-1024 {
    margin-bottom: 4em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-right-xs\@lt-1024 {
    margin-right: 1.25em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-right-xxs\@lt-1024 {
    margin-right: 0.625em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-top-md\@lt-md {
    margin-top: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-top-sm\@lt-md {
    margin-top: 1.875em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-top-sm\@lt-1024 {
    margin-top: 1.875em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-horizontal-auto\@lt-md {
    margin-right: auto;
    margin-left: auto;
  }
}
@media all and (max-width: 63.9375em) {
  .u-margin-horizontal-auto\@lt-1024 {
    margin-right: auto;
    margin-left: auto;
  }
}
@media all and (max-width: 64.9375em) {
  .u-margin-horizontal-auto\@lt-1040 {
    margin-right: auto;
    margin-left: auto;
  }
}
@media all and (max-width: 48em) {
  .u-margin-top-lg\@lt-md {
    margin-top: 4em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-margin-top-xs\@lt-sm {
    margin-top: 1.25em;
  }
}
@media all and (max-width: 48em) {
  .u-margin-top-xs\@lt-md {
    margin-top: 1.25em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-margin-right-xs\@lt-sm {
    margin-right: 1.25em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-margin-bottom-xs\@lt-sm {
    margin-bottom: 1.25em;
  }
}
@media all and (max-width: 61.1875em) {
  .u-margin-top-sm\@lt-lg {
    margin-top: 1.875em;
  }
}
@media all and (max-width: 72.4375em) {
  .u-margin-bottom-sm\@lt-1160 {
    margin-bottom: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-vertical-0\@md {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-bottom-xxs\@md {
    margin-bottom: 0.625em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-bottom-xs\@md {
    margin-bottom: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-bottom-xl\@md {
    margin-bottom: 5em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-vertical-xxs\@md {
    margin-top: 0.625em;
    margin-bottom: 0.625em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-vertical-md\@md {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-vertical-lg\@md {
    margin-top: 4em;
    margin-bottom: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-top-xxs\@md {
    margin-top: 0.625em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-top-xs\@md {
    margin-top: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-top-md\@md {
    margin-top: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-top-lg\@md {
    margin-top: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-horizontal-sm\@md {
    margin-right: 1.875em;
    margin-left: 1.875em;
  }
}
@media all and (min-width: 65em) {
  .u-margin-horizontal-auto\@1040 {
    margin-right: auto;
    margin-left: auto;
  }
}
@media all and (min-width: 30em) {
  .u-margin-vertical-xs\@sm {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
}
@media all and (min-width: 30em) {
  .u-margin-top-xs\@sm {
    margin-top: 1.25em;
  }
}
@media all and (min-width: 30em) {
  .u-margin-right-xs\@sm {
    margin-right: 1.25em;
  }
}
@media all and (min-width: 30em) {
  .u-margin-bottom-sm\@sm {
    margin-bottom: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-bottom-lg\@md {
    margin-bottom: 4em;
  }
}
@media all and (min-width: 75em) {
  .u-margin-bottom-lg\@xl {
    margin-bottom: 4em;
  }
}
@media all and (min-width: 30em) {
  .u-margin-left-auto\@sm {
    margin-left: auto;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-left-auto\@md {
    margin-left: auto;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-left-0\@md {
    margin-left: 0;
  }
}
@media all and (min-width: 64em) {
  .u-margin-left-0\@1024 {
    margin-left: 0;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-left-md\@md {
    margin-left: 3em;
  }
}
@media all and (min-width: 56.25em) {
  .u-margin-bottom-sm\@900 {
    margin-bottom: 1.875em;
  }
}
@media all and (min-width: 61.25em) {
  .u-margin-top-xl\@lg {
    margin-top: 5em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-top-xl\@md {
    margin-top: 5em;
  }
}
@media all and (min-width: 75em) {
  .u-margin-top-xl\@xl {
    margin-top: 5em;
  }
}
@media all and (min-width: 61.25em) {
  .u-margin-bottom-sm\@lg {
    margin-bottom: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-margin-top-sm\@md {
    margin-top: 1.875em;
  }
}
@media all and (min-width: 61.25em) {
  .u-margin-top-sm\@lg {
    margin-top: 1.875em;
  }
}
@media all and (min-width: 75em) {
  .u-margin-top-sm\@xl {
    margin-top: 1.875em;
  }
}
@media all and (min-width: 65em) {
  .u-margin-top-sm\@1040 {
    margin-top: 1.875em;
  }
}
@media all and (min-width: 91.25em) {
  .u-margin-bottom-xxl\@1460 {
    margin-bottom: 10.625em;
  }
}
.u-max-width-none {
  max-width: none;
}

.u-max-width-16px {
  max-width: 16px;
}

.u-max-width-100 {
  max-width: 6.25em;
}

.u-max-width-150 {
  max-width: 9.375em;
}

.u-max-width-180 {
  max-width: 11.25em;
}

.u-max-width-240 {
  max-width: 15em;
}

.u-max-width-280 {
  max-width: 17.5em;
}

.u-max-width-320 {
  max-width: 20em;
}

.u-max-width-340 {
  max-width: 21.25em;
}

.u-max-width-350 {
  max-width: 21.875em;
}

.u-max-width-360 {
  max-width: 22.5em;
}

.u-max-width-370 {
  max-width: 23.125em;
}

.u-max-width-380 {
  max-width: 23.75em;
}

.u-max-width-400 {
  max-width: 25em;
}

.u-max-width-410 {
  max-width: 25.625em;
}

.u-max-width-430 {
  max-width: 26.875em;
}

.u-max-width-450 {
  max-width: 28.125em;
}

.u-max-width-480 {
  max-width: 30em;
}

.u-max-width-500 {
  max-width: 31.25em;
}

.u-max-width-500px {
  max-width: 500px;
}

.u-max-width-540px {
  max-width: 540px;
}

.u-max-width-530px {
  max-width: 530px;
}

.u-max-width-550 {
  max-width: 34.375em;
}

.u-max-width-600 {
  max-width: 37.5em;
}

.u-max-width-640 {
  max-width: 40em;
}

.u-max-width-700 {
  max-width: 43.75em;
}

.u-max-width-720 {
  max-width: 45em;
}

.u-max-width-760 {
  max-width: 47.5em;
}

.u-max-width-900 {
  max-width: 56.25em;
}

.u-max-width-960 {
  max-width: 60em;
}

.u-max-width-1000 {
  max-width: 62.5em;
}

.u-max-width-1000px {
  max-width: 1000px;
}

.u-max-width-1100px {
  max-width: 1100px;
}

.u-max-width-1040 {
  max-width: 65em;
}

.u-max-width-1200 {
  max-width: 75em;
}

.u-max-width-1400 {
  max-width: 87.5em;
}

.u-max-width-1500 {
  max-width: 93.75em;
}

.u-max-width-1600 {
  max-width: 100em;
}

@media all and (max-width: 56.1875em) {
  .u-max-width-300\@lt-900 {
    max-width: 18.75em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-max-width-240\@lt-sm {
    max-width: 15em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-max-width-430\@md {
    max-width: 26.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-max-width-450\@md {
    max-width: 28.125em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-max-width-350\@md {
    max-width: 21.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-max-width-380\@md {
    max-width: 23.75em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-max-width-200\@md {
    max-width: 200px;
  }
}
@media all and (min-width: 48.0625em) {
  .u-max-width-240\@md {
    max-width: 15em;
  }
}
@media all and (min-width: 87.5em) {
  .u-max-width-640\@1400 {
    max-width: 40em;
  }
}
@media all and (min-width: 87.5em) {
  .u-max-width-900\@1400 {
    max-width: 56.25em;
  }
}
@media all and (min-width: 38.75em) {
  .u-max-width-170\@620 {
    max-width: 10.625em;
  }
}
@media all and (min-width: 42.5em) {
  .u-max-width-425\@680 {
    max-width: 425px;
  }
}
@media all and (min-width: 64em) {
  .u-max-width-380\@1024 {
    max-width: 380px;
  }
}
@media all and (min-width: 65em) {
  .u-max-width-380\@1040 {
    max-width: 380px;
  }
}
.u-min-height-100vh {
  min-height: 100vh;
}

.u-min-height-250 {
  min-height: 250px;
}

@media all and (max-width: 48em) {
  .u-min-height-100\%\@lt-md {
    min-height: 100%;
  }
}
@media all and (min-width: 61.25em) {
  .u-min-height-700\@lg {
    min-height: 43.75em;
  }
}
@media all and (min-width: 61.25em) {
  .u-max-height-675\@lg {
    max-height: 42.1875em;
  }
}
@media all and (min-width: 30em) {
  .u-max-height-150\@sm {
    max-height: 9.375em;
  }
}
@media all and (max-width: 48em) {
  .u-max-height-none\@lt-md {
    max-height: none;
  }
}
.u-object-fit-cover-100\% {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

@media all and (max-width: 63.9375em) {
  .u-object-fit-cover-100\%\@lt-1024 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}
@media all and (max-width: 64.9375em) {
  .u-object-fit-cover-100\%\@lt-1040 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}
@media all and (max-width: 74.9375em) {
  .u-object-fit-cover-100\%\@lt-xl {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}
@media all and (min-width: 48.0625em) {
  .u-object-fit-cover-100\%\@md {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}
@media all and (max-width: 64.9375em) {
  .u-object-fit-cover-100\%\@lt-1040 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}
.u-object-position-center-2\/3 {
  object-position: 50% 66.6666666667%;
}

.u-object-position-center-bottom {
  object-position: center bottom;
}

.u-object-position-top-right {
  object-position: right top;
}

.u-opacity-0 {
  opacity: 0;
}

.u-opacity-0\.5 {
  opacity: 0.5;
}

.u-opacity-0\.7 {
  opacity: 0.7;
}

.u-opacity-0\.8 {
  opacity: 0.8;
}

.u-overflow-auto {
  overflow: auto;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-scroll {
  overflow: scroll;
}

.u-overflow-visible {
  overflow: visible;
}

.u-overflow-visible\! {
  overflow: visible !important;
}

.u-padding-xxs {
  padding: 0.625em;
}

.u-padding-xs {
  padding: 1.25em;
}

.u-padding-sm {
  padding: 1.875em;
}

.u-padding-md {
  padding: 3em;
}

.u-padding-lg {
  padding: 4em;
}

.u-padding-0 {
  padding: 0;
}

.u-padding-horizontal-xxs {
  padding-right: 0.625em;
  padding-left: 0.625em;
}

.u-padding-horizontal-xs {
  padding-right: 1.25em;
  padding-left: 1.25em;
}

.u-padding-horizontal-sm {
  padding-right: 1.875em;
  padding-left: 1.875em;
}

.u-padding-horizontal-md {
  padding-right: 3em;
  padding-left: 3em;
}

.u-padding-horizontal-lg {
  padding-right: 4em;
  padding-left: 4em;
}

.u-padding-horizontal-xl {
  padding-right: 5em;
  padding-left: 5em;
}

.u-padding-vertical-xxs {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
}

.u-padding-vertical-xs {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
}

.u-padding-vertical-sm {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}

.u-padding-vertical-md {
  padding-top: 3em;
  padding-bottom: 3em;
}

.u-padding-vertical-lg {
  padding-top: 4em;
  padding-bottom: 4em;
}

.u-padding-vertical-xl {
  padding-top: 5em;
  padding-bottom: 5em;
}

.u-padding-top-0 {
  padding-top: 0;
}

.u-padding-top-xxs {
  padding-top: 0.625em;
}

.u-padding-top-xs {
  padding-top: 1.25em;
}

.u-padding-top-sm {
  padding-top: 1.875em;
}

.u-padding-top-md {
  padding-top: 3em;
}

.u-padding-top-lg {
  padding-top: 4em;
}

.u-padding-top-xl {
  padding-top: 5em;
}

.u-padding-top-xxl {
  padding-top: 10.625em;
}

.u-padding-right-xxs {
  padding-right: 0.625em;
}

.u-padding-right-xs {
  padding-right: 1.25em;
}

.u-padding-right-sm {
  padding-right: 1.875em;
}

.u-padding-right-md {
  padding-right: 3em;
}

.u-padding-right-lg {
  padding-right: 4em;
}

.u-padding-right-xl {
  padding-right: 5em;
}

.u-padding-bottom-0 {
  padding-bottom: 0;
}

.u-padding-bottom-xxxs-px {
  padding-bottom: 2px;
}

.u-padding-bottom-xxs {
  padding-bottom: 0.625em;
}

.u-padding-bottom-xs {
  padding-bottom: 1.25em;
}

.u-padding-bottom-sm {
  padding-bottom: 1.875em;
}

.u-padding-bottom-md {
  padding-bottom: 3em;
}

.u-padding-bottom-lg {
  padding-bottom: 4em;
}

.u-padding-bottom-xl {
  padding-bottom: 5em;
}

.u-padding-bottom-xxl {
  padding-bottom: 10.625em;
}

.u-padding-left-xxs {
  padding-left: 0.625em;
}

.u-padding-left-xs {
  padding-left: 1.25em;
}

.u-padding-left-sm {
  padding-left: 1.875em;
}

.u-padding-left-md {
  padding-left: 3em;
}

.u-padding-left-lg {
  padding-left: 4em;
}

@media all and (max-width: 61.1875em) {
  .u-padding-bottom-xxl\@lt-lg {
    padding-bottom: 10.625em;
  }
}
@media all and (max-width: 61.1875em) {
  .u-padding-horizontal-sm\@lt-lg {
    padding-right: 1.875em;
    padding-left: 1.875em;
  }
}
@media all and (max-width: 56.1875em) {
  .u-padding-vertical-xs\@lt-900 {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-padding-vertical-xxl\@lt-sm {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-vertical-md\@lt-md {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-vertical-lg\@lt-md {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-vertical-xl\@lt-md {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-top-xl\@lt-md {
    padding-top: 5em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-top-md\@lt-md {
    padding-top: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-top-xxs\@lt-md {
    padding-top: 0.625em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-top-sm\@lt-md {
    padding-top: 1.875em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-right-xs\@lt-md {
    padding-right: 1.25em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-bottom-xs\@lt-md {
    padding-bottom: 1.25em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-bottom-sm\@lt-md {
    padding-bottom: 1.875em;
  }
}
@media all and (max-width: 89.9375em) {
  .u-padding-bottom-md\@lt-1440 {
    padding-bottom: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-bottom-md\@lt-md {
    padding-bottom: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-bottom-lg\@lt-md {
    padding-bottom: 4em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-bottom-xl\@lt-md {
    padding-bottom: 5em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-padding-top-xl\@lt-1024 {
    padding-top: 5em;
  }
}
@media all and (max-width: 63.9375em) {
  .u-padding-top-lg\@lt-1024 {
    padding-top: 4em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-bottom-200\@lt-md {
    padding-bottom: 200px;
  }
}
@media all and (max-width: 48em) {
  .u-padding-left-md\@lt-md {
    padding-left: 3em;
  }
}
@media all and (max-width: 48em) {
  .u-padding-horizontal-md\@lt-md {
    padding-right: 3em;
    padding-left: 3em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-padding-bottom-sm\@lt-sm {
    padding-bottom: 1.875em;
  }
}
@media all and (max-width: 29.9375em) {
  .u-padding-vertical-sm\@lt-sm {
    padding-top: 1.875em;
    padding-bottom: 1.875em;
  }
}
@media all and (min-width: 25.75em) {
  .u-padding-bottom-sm\@412 {
    padding-bottom: 1.875em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-vertical-sm\@sm {
    padding-top: 1.875em;
    padding-bottom: 1.875em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-horizontal-sm\@sm {
    padding-right: 1.875em;
    padding-left: 1.875em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-bottom-md\@sm {
    padding-bottom: 3em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-bottom-sm\@sm {
    padding-bottom: 1.875em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-bottom-xs\@sm {
    padding-bottom: 1.25em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-left-sm\@sm {
    padding-left: 1.875em;
  }
}
@media all and (min-width: 30em) {
  .u-padding-left-md\@sm {
    padding-left: 3em;
  }
}
@media all and (min-width: 38.75em) {
  .u-padding-horizontal-sm\@620 {
    padding-right: 1.875em;
    padding-left: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-lg\@md {
    padding: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-md\@md {
    padding: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-sm\@md {
    padding: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-xs\@md {
    padding: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-xxs\@md {
    padding: 0.625em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-right-sm\@md {
    padding-right: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-xs\@md {
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-sm\@md {
    padding-right: 1.875em;
    padding-left: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-md\@md {
    padding-right: 3em;
    padding-left: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-lg\@md {
    padding-right: 4em;
    padding-left: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-xl\@md {
    padding-right: 5em;
    padding-left: 5em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-100px\@md {
    padding-right: 100px;
    padding-left: 100px;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-horizontal-20px\@md {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-vertical-md\@md {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-vertical-45\@md {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-bottom-35\@md {
    padding-bottom: 35px;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-vertical-lg\@md {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-vertical-xl\@md {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-right-md\@md {
    padding-right: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-right-lg\@md {
    padding-right: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-bottom-xxs\@md {
    padding-bottom: 0.625em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-bottom-xs\@md {
    padding-bottom: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-bottom-sm\@md {
    padding-bottom: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-bottom-lg\@md {
    padding-bottom: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-left-lg\@md {
    padding-left: 4em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-left-md\@md {
    padding-left: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-left-sm\@md {
    padding-left: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-top-xs\@md {
    padding-top: 1.25em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-top-sm\@md {
    padding-top: 1.875em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-top-md\@md {
    padding-top: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-top-lg\@md {
    padding-top: 4em;
  }
}
@media all and (min-width: 61.25em) {
  .u-padding-top-lg\@lg {
    padding-top: 4em;
  }
}
@media all and (min-width: 85.625em) {
  .u-padding-top-md\@1370 {
    padding-top: 3em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-top-xl\@md {
    padding-top: 5em;
  }
}
@media all and (min-width: 48.0625em) {
  .u-padding-top-xxl\@md {
    padding-top: 10.625em;
  }
}
@media all and (min-width: 61.25em) {
  .u-padding-top-xl\@lg {
    padding-top: 5em;
  }
}
@media all and (min-width: 87.5em) {
  .u-padding-top-xl\@1400 {
    padding-top: 5em;
  }
}
@media all and (min-width: 100em) {
  .u-padding-top-xl\@1600 {
    padding-top: 5em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-horizontal-xxs\@1040 {
    padding-right: 0.625em;
    padding-left: 0.625em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-horizontal-xs\@1040 {
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-horizontal-md\@1040 {
    padding-right: 3em;
    padding-left: 3em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-horizontal-lg\@1040 {
    padding-right: 4em;
    padding-left: 4em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-horizontal-xl\@1040 {
    padding-right: 5em;
    padding-left: 5em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-right-lg\@1040 {
    padding-right: 4em;
  }
}
@media all and (min-width: 65em) {
  .u-padding-left-md\@1040 {
    padding-left: 3em;
  }
}
@media all and (min-width: 75em) {
  .u-padding-bottom-xl\@xl {
    padding-bottom: 5em;
  }
}
.u-pointer-events-none {
  pointer-events: none;
}

.u-pointer-events-auto {
  pointer-events: auto;
}

@media all and (max-width: 48em) {
  .u-pointer-events-none\@lt-md {
    pointer-events: none;
  }
}
@media all and (max-width: 48em) {
  .u-pointer-events-auto\@lt-md {
    pointer-events: auto;
  }
}
@media all and (min-width: 48.0625em) {
  .u-pointer-events-none\@md {
    pointer-events: none;
  }
}
@media all and (min-width: 48.0625em) {
  .u-pointer-events-auto\@md {
    pointer-events: auto;
  }
}
.u-position-static {
  position: static;
}

.u-position-relative {
  position: relative;
}

.u-position-absolute {
  position: absolute;
}

.u-position-fixed {
  position: fixed;
}

.u-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.u-position-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
}

.u-position-top-center {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
}

.u-position-bottom-left {
  bottom: 0;
  left: 0;
  position: absolute;
}

.u-position-bottom-right {
  bottom: 0;
  right: 0;
  position: absolute;
}

.u-position-top-right {
  top: 0;
  right: 0;
  position: absolute;
}

.u-position-cover {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.u-position-right-center {
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  position: absolute;
}

@media all and (min-width: 65em) {
  .u-position-left-center\@1040 {
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    position: absolute;
  }
}
@media all and (min-width: 64em) {
  .u-position-left-center\@1024 {
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    position: absolute;
  }
}
.u-bottom-0 {
  bottom: 0;
}

.u-left-0 {
  left: 0;
}

@media all and (max-width: 48em) {
  .u-position-bottom-center\@lt-md {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
}
@media all and (min-width: 48.0625em) {
  .u-position-cover\@md {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (min-width: 30em) {
  .u-position-cover\@sm {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (min-width: 42.5em) {
  .u-position-cover\@680 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (max-width: 63.9375em) {
  .u-position-cover\@lt-1024 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (max-width: 42.4375em) {
  .u-position-cover\@lt-680 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (max-width: 61.1875em) {
  .u-position-cover\@lt-lg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (max-width: 48em) {
  .u-position-cover\@lt-md {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    position: absolute;
  }
}
@media all and (min-width: 61.25em) {
  .u-position-center\@lg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
@media all and (min-width: 42.5em) {
  .u-position-center\@680 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
@media all and (min-width: 48.0625em) {
  .u-position-center\@md {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
@media all and (min-width: 65em) {
  .u-position-center\@1040 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
@media all and (min-width: 48.0625em) {
  .u-position-top-center\@md {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
}
@media all and (max-width: 63.9375em) {
  .u-position-center\@lt-1024 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
@media all and (max-width: 63.9375em) {
  .u-position-top-center\@lt-1024 {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
}
@media all and (max-width: 64.9375em) {
  .u-position-center\@lt-1040 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
@media all and (max-width: 64.9375em) {
  .u-position-top-center\@lt-1040 {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
}
.u-break-inside-avoid {
  break-inside: avoid;
}

.u-rotate-180 {
  transform: rotate(180deg);
}

.u-text-align-right {
  text-align: right;
}

.u-text-align-center {
  text-align: center;
}

.u-text-align-left {
  text-align: left;
}

@media all and (max-width: 56.1875em) {
  .u-text-align-center\@lt-900 {
    text-align: center;
  }
}
@media all and (max-width: 38.6875em) {
  .u-text-align-center\@lt-620 {
    text-align: center;
  }
}
@media all and (max-width: 29.9375em) {
  .u-text-align-center\@lt-sm {
    text-align: center;
  }
}
@media all and (min-width: 48.0625em) {
  .u-text-align-right\@md {
    text-align: right;
  }
}
@media all and (min-width: 90em) {
  .u-text-align-right\@1440 {
    text-align: right;
  }
}
@media all and (min-width: 48.0625em) {
  .u-text-align-left\@md {
    text-align: left;
  }
}
@media all and (min-width: 61.25em) {
  .u-text-align-left\@lg {
    text-align: left;
  }
}
@media all and (min-width: 64em) {
  .u-text-align-left\@1024 {
    text-align: left;
  }
}
@media all and (min-width: 65em) {
  .u-text-align-left\@1040 {
    text-align: left;
  }
}
.u-text-transform-uppercase {
  text-transform: uppercase;
}

.u-text-transform-lowercase {
  text-transform: lowercase;
}

.u-user-select-none {
  user-select: none;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-vertical-align-baseline {
  vertical-align: baseline;
}

.u-width-60 {
  width: 3.75em;
}

.u-width-100 {
  width: 6.25em;
}

.u-width-145 {
  width: 9.0625em;
}

.u-width-1\/1 {
  width: 100%;
}

.u-width-1\/2 {
  width: 50%;
}

.u-width-1\/3 {
  width: 33.3333333333%;
}

.u-width-2\/3 {
  width: 66.6666666667%;
}

.u-width-1\/4 {
  width: 25%;
}

.u-width-3\/4 {
  width: 75%;
}

.u-width-1\/5 {
  width: 20%;
}

.u-width-2\/5 {
  width: 40%;
}

.u-width-4\/5 {
  width: 80%;
}

.u-width-9\/10 {
  width: 90%;
}

.u-width-29\/100 {
  width: 29%;
}

@media all and (max-width: 48em) {
  .u-width-1\/2\@lt-md {
    width: 50%;
  }
}
@media all and (max-width: 29.9375em) {
  .u-width-1\/1\@lt-sm {
    width: 100%;
  }
}
@media all and (max-width: 29.9375em) {
  .u-width-2\/3\@lt-sm {
    width: 66.6666666667%;
  }
}
@media all and (max-width: 48em) {
  .u-width-1\/1\@lt-md {
    width: 100%;
  }
}
@media all and (max-width: 48em) {
  .u-width-1\/3\@lt-md {
    width: 33.3333333333%;
  }
}
@media all and (max-width: 48em) {
  .u-width-3\/4\@lt-md {
    width: 75%;
  }
}
@media all and (max-width: 64.9375em) {
  .u-width-1\/1\@lt-1040 {
    width: 100%;
  }
}
@media all and (min-width: 22.5em) {
  .u-width-1\/2\@360 {
    width: 50%;
  }
}
@media all and (min-width: 38.75em) {
  .u-width-1\/2\@620 {
    width: 50%;
  }
}
@media all and (min-width: 30em) {
  .u-width-1\/2\@sm {
    width: 50%;
  }
}
@media all and (min-width: 30em) {
  .u-width-1\/3\@sm {
    width: 33.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .u-width-2\/3\@sm {
    width: 66.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .u-width-1\/4\@sm {
    width: 25%;
  }
}
@media all and (min-width: 30em) {
  .u-width-3\/4\@sm {
    width: 75%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-1\/1\@md {
    width: 100%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-1\/2\@md {
    width: 50%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-1\/3\@md {
    width: 33.3333333333%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-2\/3\@md {
    width: 66.6666666667%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-1\/4\@md {
    width: 25%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-1\/5\@md {
    width: 20%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-2\/5\@md {
    width: 40%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-3\/5\@md {
    width: 60%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-4\/5\@md {
    width: 80%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-3\/4\@md {
    width: 75%;
  }
}
@media all and (min-width: 48.0625em) {
  .u-width-9\/10\@md {
    width: 90%;
  }
}
@media all and (min-width: 61.25em) {
  .u-width-1\/2\@lg {
    width: 50%;
  }
}
@media all and (min-width: 61.25em) {
  .u-width-1\/4\@lg {
    width: 25%;
  }
}
@media all and (min-width: 61.25em) {
  .u-width-2\/3\@lg {
    width: 66.6666666667%;
  }
}
@media all and (min-width: 61.25em) {
  .u-width-3\/4\@lg {
    width: 75%;
  }
}
@media all and (min-width: 81.25em) {
  .u-width-2\/3\@1300 {
    width: 66.6666666667%;
  }
}
@media all and (min-width: 87.5em) {
  .u-width-2\/3\@1400 {
    width: 66.6666666667%;
  }
}
@media all and (min-width: 90em) {
  .u-width-2\/3\@1440 {
    width: 66.6666666667%;
  }
}
@media all and (min-width: 72.5em) {
  .u-width-3\/4\@1160 {
    width: 75%;
  }
}
@media all and (min-width: 72.5em) {
  .u-width-9\/10\@1160 {
    width: 90%;
  }
}
@media all and (min-width: 87.5em) {
  .u-width-3\/5\@1400 {
    width: 60%;
  }
}
@media all and (min-width: 100em) {
  .u-width-3\/5\@1600 {
    width: 60%;
  }
}
@media all and (min-width: 65em) {
  .u-width-1\/2\@1040 {
    width: 50%;
  }
}
@media all and (min-width: 65em) {
  .u-width-3\/4\@1040 {
    width: 75%;
  }
}
@media all and (min-width: 42.5em) {
  .u-width-1\/1\@680 {
    width: 100%;
  }
}
@media all and (min-width: 54.375em) {
  .u-width-1\/2\@870 {
    width: 50%;
  }
}
@media all and (min-width: 54.375em) {
  .u-width-1\/3\@870 {
    width: 33.3333333333%;
  }
}
@media all and (min-width: 56.25em) {
  .u-width-1\/1\@900 {
    width: 100%;
  }
}
@media all and (min-width: 56.25em) {
  .u-width-1\/2\@900 {
    width: 50%;
  }
}
@media all and (min-width: 64em) {
  .u-width-1\/2\@1024 {
    width: 50%;
  }
}
@media all and (min-width: 64em) {
  .u-width-1\/3\@1024 {
    width: 33.3333333333%;
  }
}
@media all and (min-width: 87.5em) {
  .u-width-1\/3\@1400 {
    width: 33.3333333333%;
  }
}
@media all and (min-width: 90em) {
  .u-width-1\/3\@1440 {
    width: 33.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .u-width-1\/4\@1024 {
    width: 25%;
  }
}
@media all and (min-width: 64em) {
  .u-width-1\/5\@1024 {
    width: 20%;
  }
}
@media all and (min-width: 64em) {
  .u-width-1\/1\@1024 {
    width: 100%;
  }
}
@media all and (min-width: 65em) {
  .u-width-1\/1\@1040 {
    width: 100%;
  }
}
@media all and (min-width: 72.5em) {
  .u-width-1\/4\@1160 {
    width: 25%;
  }
}
@media all and (min-width: 75em) {
  .u-width-1\/4\@xl {
    width: 25%;
  }
}
@media all and (min-width: 75em) {
  .u-width-1\/2\@xl {
    width: 50%;
  }
}
@media all and (min-width: 77.5em) {
  .u-width-3\/4\@1240 {
    width: 75%;
  }
}
@media all and (min-width: 77.5em) {
  .u-width-9\/10\@1240 {
    width: 90%;
  }
}
@media all and (max-width: 38.6875em) {
  .u-width-1\/1\@lt-620 {
    width: 100%;
  }
}
@media all and (max-width: 42.4375em) {
  .u-width-1\/1\@lt-680 {
    width: 100%;
  }
}
@media all and (min-width: 42.5em) {
  .u-width-1\/1\@680 {
    width: 100%;
  }
}
@media (min-width: 50em) {
  .u-width-1\/1\@800 {
    width: 100%;
  }
}

@media (min-width: 31.25em) {
  .u-width-1\/1\@500 {
    width: 50%;
  }
}

@media (min-width: 31.25em) {
  .u-width-1\/2\@500 {
    width: 100%;
  }
}

@media (min-width: 21.875em) {
  .u-width-1\/1\@350 {
    width: 100%;
  }
}

.u-white-space-nowrap {
  white-space: nowrap;
}

.float_left {
  float: left;
  margin-right: 20px;
}

.float_right {
  float: right;
  margin-left: 20px;
}

.c-position-zero {
  overflow: visible;
}

@media all and (min-width: 48.0625em) {
  .c-position-zero__header {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

@media (min-width: 30em) and (max-width: 48em) {
  .c-position-zero__grid {
    margin-top: 3em;
  }
}

.position-zero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25em 0;
}
@media all and (max-width: 29.9375em) {
  .position-zero__content {
    align-items: center;
    text-align: left;
  }
}
@media all and (min-width: 48.0625em) {
  .position-zero__content {
    padding: 3em;
  }
}
@media (min-width: 68.75em) {
  .position-zero__content {
    padding: 4em;
  }
}

@media all and (min-width: 30em) {
  .c-position-zero__content {
    padding-right: 4%;
    padding-left: 4%;
  }
  .c-position-zero__list-item--right .c-position-zero__content {
    align-items: flex-start;
    padding-right: 0;
    text-align: left;
  }
  .c-position-zero__list-item--left .c-position-zero__content {
    align-items: flex-end;
    padding-left: 0;
    text-align: right;
  }
}
@media all and (min-width: 30em) and (max-width: 48em) {
  .c-position-zero__list-item--lg {
    flex-direction: column-reverse;
    margin-top: 1.875em;
  }
  .c-position-zero__list-item--lg .c-position-zero__content {
    align-items: center;
    text-align: left;
  }
}
@media all and (max-width: 29.9375em) {
  .c-position-zero__list-item {
    margin-top: 1.875em;
  }
}
@media (min-width: 48.0625em) and (max-width: 68.6875em) {
  .c-position-zero__list-item {
    padding-bottom: 6%;
  }
  .c-position-zero__list-item > * {
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.o-preserve-aspect-ratio--11x14 {
  padding: 0 0 127.2727272727%;
}

#nutrition-and-wellness {
  background-image: url("/img/pinnacle/backgrounds/mobile-bottom-nutrition.1743442783504.webp");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}
@media all and (min-width: 48.0625em) {
  #nutrition-and-wellness {
    background-image: url("/img/pinnacle/backgrounds/page-bg-map.1743442783504.webp");
  }
}

.nutrition-wellness {
  scroll-behavior: smooth;
}
.nutrition-wellness main {
  background-image: url("/img/pinnacle/backgrounds/mobile-dark-map-short.1743442783504.webp");
  background-repeat: no-repeat;
  background-position: right -10px;
  background-size: 100vw;
  scroll-behavior: smooth;
}
@media all and (min-width: 48.0625em) {
  .nutrition-wellness main {
    background-image: url("/img/pinnacle/backgrounds/map-top-dk.1743442783504.webp");
  }
}
.nutrition-wellness #m-wrapper {
  padding-top: var(--header-height);
}
.nutrition-wellness .breadcrumbs {
  position: absolute;
  top: var(--header-full-height);
}
.nutrition-wellness .half-half__content .section__copy {
  margin-bottom: clamp(2.6041666667rem, 3.4722222222vw, 3.625rem);
}
.nutrition-wellness .half-half__content .c-icon.c-icon--health svg {
  stroke: none;
  fill: none;
}
.nutrition-wellness .half-half__content .section__ctas .btn {
  position: relative;
  z-index: 1;
}
.nutrition-wellness .section-header picture img {
  font-family: "object-fit: cover;";
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.nutrition-wellness .section-header.opacity .section-header__background {
  opacity: 0.3;
}
@media all and (min-width: 61.25em) {
  .nutrition-wellness .section-header.opacity .section-header__background {
    opacity: 0.4;
  }
}
.nutrition-wellness .section-header .section-header__content {
  max-width: 682px;
  width: 100%;
}
@media all and (min-width: 61.25em) {
  .nutrition-wellness .section-header--primary-short .section-header__disclaimer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media all and (max-width: 61.1875em) {
  .nutrition-wellness .half-half__image .half-half__background {
    width: 75%;
    margin: 0 auto;
  }
}
@media all and (min-width: 48.0625em) {
  .nutrition-wellness .half-half__background {
    background: linear-gradient(86deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3645833333) 10%, rgb(0, 0, 0) 42%, rgb(0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%);
  }
}
.nutrition-wellness .section-header:not(.section-header--primary-short):not(.section--background) .section-header__content {
  max-width: 870px;
}
.nutrition-wellness .section--background .section-header__content {
  max-width: 682px;
  width: 100%;
}
.nutrition-wellness .section-header__heading {
  font-variant: normal;
  font-variant-caps: normal;
  text-transform: none;
}
.supports-font-features.wf-active .nutrition-wellness .section-header__heading {
  font-variant-caps: initial;
  font-feature-settings: initial;
}
.nutrition-wellness .section-header__heading sup {
  top: 0.4em;
  font-size: 100%;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-header__heading:last-child {
    padding-bottom: clamp(3.6458333333rem, 4.8611111111vw, 5.0625rem);
  }
}
.nutrition-wellness .padding {
  padding: clamp(4.0625rem, 5.4166666667vw, 5.6875rem) 0;
}
.nutrition-wellness .quality-promise {
  padding-top: 70px;
}
@media all and (min-width: 61.25em) {
  .nutrition-wellness .quality-promise {
    padding-top: clamp(6.5104166667rem, 8.6805555556vw, 9.0625rem);
  }
}
.nutrition-wellness .quality-promise img {
  padding-bottom: clamp(2.2395833333rem, 2.9861111111vw, 3.125rem);
}
.nutrition-wellness .quality-promise .section-header__content {
  margin-top: 0;
}
@media all and (max-width: 54.3125em) {
  .nutrition-wellness .product-section {
    padding-top: clamp(34px, 4.5333333333vw, 68px);
  }
}
.nutrition-wellness .nutrition-list--section {
  padding-left: var(--page-margins-inner);
  padding-right: var(--page-margins-inner);
  text-align: center;
  padding-bottom: clamp(142.5px, 19vw, 285px);
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .nutrition-list--section {
    padding-bottom: clamp(4.6875rem, 6.25vw, 6.5625rem);
  }
}
.nutrition-wellness .nutrition-list--section h2 {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;
  font-size: 28px;
  padding-top: clamp(6.25rem, 8.3333333333vw, 8.75rem);
  padding-bottom: clamp(4.4791666667rem, 5.9722222222vw, 6.25rem);
}
@media all and (min-width: 48.0625em) {
  .nutrition-wellness .nutrition-list--section h2 {
    font-size: clamp(1.875rem, 2.5vw, 2.625rem);
  }
}
@media all and (min-width: 75em) {
  .nutrition-wellness .nutrition-list--section h2 {
    font-size: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
  }
  .nutrition-wellness .nutrition-list--section h2 .u-type-subscripts__sub--tm {
    font-size: 30%;
  }
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: clamp(62px, 8.2666666667vw, 124px);
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .nutrition-list--section .nutrition-list--container {
    grid-template-columns: 1fr 1fr;
    column-gap: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
    row-gap: clamp(3.28125rem, 4.375vw, 4.5625rem);
  }
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container a.nutrition-list {
  text-align: left;
  overflow: hidden;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container a.nutrition-list img {
  width: 100%;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container a.nutrition-list .c-copy {
  text-align: left;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container a.nutrition-list .c-icon {
  transform: translate(-1px, 1px);
  background-color: #151310;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item {
  text-decoration: none;
  color: #F7EEDF;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item .img-wrap {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 99% 0, 100% 100%, 0 100%);
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item .img-wrap img {
  width: 100%;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item .img-wrap .c-icon {
  z-index: 1;
  background-color: #151310;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item .img-wrap::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  z-index: 2;
  transition: opacity 0.2s ease-out;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item .img-wrap img {
  transition: transform 0.2s ease-out;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item:hover .img-wrap img {
  transform: scale(1.1);
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item:hover .img-wrap::after {
  opacity: 1;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item h3 {
  padding-top: clamp(1.71875rem, 2.2916666667vw, 2.375rem);
  padding-bottom: clamp(0.9375rem, 1.25vw, 1.3125rem);
  color: #F7EEDF;
  text-align: left;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: 0.028125em;
  font-size: 16px;
  max-width: 440px;
  color: #F7EEDF;
  text-align: left;
}
.nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item p .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 75em) {
  .nutrition-wellness .nutrition-list--section .nutrition-list--container .nutrition-list__item p {
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.nutrition-wellness .section-half-half {
  padding: 0 var(--page-margins-inner);
}
.nutrition-wellness .section-half-hero .page-navigation__top {
  justify-content: center;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero .page-navigation__top {
    justify-content: space-between;
  }
}
.nutrition-wellness .section-half-hero main {
  padding-top: var(--header-height);
}
.nutrition-wellness .section-half-hero--full-hero main {
  padding-top: var(--header-height);
}
@media all and (min-width: 65em) {
  .nutrition-wellness .section-half-hero--full-hero main {
    padding-top: 0;
  }
}
.nutrition-wellness .section-half-hero__hero {
  display: grid;
  grid-template-columns: 100%;
  padding-top: 50px;
}
.nutrition-wellness .section-half-hero__hero .section-header__heading {
  text-align: center;
  width: 90%;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__hero {
    grid-template-columns: 1fr 1fr;
    background-image: url("/img/pinnacle/backgrounds/map.1743442783504.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left 50%;
    padding-top: 0;
    margin-bottom: clamp(7.3958333333rem, 9.8611111111vw, 10.3125rem);
  }
  .nutrition-wellness .section-half-hero__hero .section-header__heading {
    text-align: left;
    width: auto;
  }
}
.nutrition-wellness .section-half-hero__hero-content {
  padding-left: var(--page-margins-inner);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: clamp(1.9791666667rem, 2.6388888889vw, 2.75rem);
  position: relative;
  z-index: 1;
}
.nutrition-wellness .section-half-hero__hero-content::after {
  content: "";
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  left: 0;
  bottom: -75px;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__hero-content {
    align-items: flex-start;
  }
  .nutrition-wellness .section-half-hero__hero-content::after {
    display: none;
  }
}
.nutrition-wellness .section-half-hero__hero-content img, .nutrition-wellness .section-half-hero__hero-content .c-icon--health {
  margin-bottom: clamp(1.875rem, 2.5vw, 2.625rem);
  width: 64px;
  height: 64px;
}
.nutrition-wellness .section-half-hero__hero-content img svg, .nutrition-wellness .section-half-hero__hero-content .c-icon--health svg {
  fill: none;
  stroke: none;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__hero-content .section-header__copy {
    text-align: left;
    padding-right: clamp(2.34375rem, 3.125vw, 3.25rem);
  }
}
.nutrition-wellness .section-half-hero__breadcrumb {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 0.81;
  letter-spacing: 0.08em;
  font-size: 18px;
  margin-bottom: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
}
@media all and (min-width: 48.0625em) {
  .nutrition-wellness .section-half-hero__breadcrumb {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .nutrition-wellness .section-half-hero__breadcrumb {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.nutrition-wellness .section-half-hero__title {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;
  font-size: 28px;
  margin-bottom: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
  text-align: center;
}
@media all and (min-width: 48.0625em) {
  .nutrition-wellness .section-half-hero__title {
    font-size: clamp(1.875rem, 2.5vw, 2.625rem);
  }
}
@media all and (min-width: 75em) {
  .nutrition-wellness .section-half-hero__title {
    font-size: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
  }
  .nutrition-wellness .section-half-hero__title .u-type-subscripts__sub--tm {
    font-size: 30%;
  }
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__title {
    text-align: left;
  }
}
.nutrition-wellness .section-half-hero__description {
  margin-bottom: clamp(2.0833333333rem, 2.7777777778vw, 2.875rem);
}
.nutrition-wellness .section-half-hero__description > * {
  text-align: center;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__description > * {
    text-align: left;
  }
}
.nutrition-wellness .section-half-hero__ctas {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  column-gap: clamp(1.40625rem, 1.875vw, 1.9375rem);
  position: relative;
  z-index: 1;
}
.nutrition-wellness .section-half-hero__hero-media {
  width: 100%;
  aspect-ratio: 820/720;
}
.nutrition-wellness .section-half-hero__hero-media img, .nutrition-wellness .section-half-hero__hero-media video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3;
  position: relative;
}
.nutrition-wellness .section-half-hero__hero--full .section-half-hero__hero-media {
  aspect-ratio: 750/940;
  grid-column: 1;
  grid-row: 1/span 2;
  margin-top: 154px;
}
@media all and (min-width: 42.5em) {
  .nutrition-wellness .section-half-hero__hero--full .section-half-hero__hero-media {
    margin-top: 0px;
  }
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__hero--full .section-half-hero__hero-media {
    aspect-ratio: 1440/820;
    grid-column: 1/span 2;
    grid-row: 1;
  }
}
.nutrition-wellness .section-half-hero__hero--full .section-half-hero__hero-content {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  z-index: 1;
}
.nutrition-wellness .section-half-hero__hero--full .section-half-hero__hero-content::after {
  display: none;
}
.nutrition-wellness .section-half-hero__image--mobile {
  display: block;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__image--mobile {
    display: none;
  }
}
.nutrition-wellness .section-half-hero__image--desktop {
  display: none;
}
@media all and (min-width: 54.375em) {
  .nutrition-wellness .section-half-hero__image--desktop {
    display: block;
  }
}
.nutrition-wellness .product-listing__content {
  display: flex;
  flex-direction: column;
}
.nutrition-wellness .product-listing__content .btn {
  display: inline-flex;
  align-self: center;
  margin-top: clamp(3.6458333333rem, 4.8611111111vw, 5.0625rem);
}
.nutrition-wellness .product-listing__cta {
  display: flex;
  justify-content: center;
  margin-bottom: 90px;
}
@media all and (min-width: 42.5em) {
  .nutrition-wellness .product-listing__cta {
    margin-bottom: clamp(6.40625rem, 8.5416666667vw, 8.9375rem);
  }
}
.nutrition-wellness .faq-scroll {
  padding-top: 200px;
  margin-top: -200px;
}

@media all and (min-width: 61.25em) {
  .half-half__left--brochures.section-half-half {
    grid-template-columns: 1fr 40%;
  }
}
.half-half__left--brochures .half-half__content {
  max-width: none;
}
.half-half__left--brochures .half-half__background img {
  max-width: clamp(20.0520833333rem, 26.7361111111vw, 28.0625rem);
}

/**
 * TODO: Get with designers about responsive adjustments for these figures.
 * Definitely needs help between 450px and 850px.
 */
.search {
  --search-form-height: 75px;
  padding-top: var(--header-full-height);
}

.search__form {
  background: var(--background);
  position: static;
  top: calc(var(--height) - 1px);
  left: 0;
  z-index: 1;
  height: var(--search-form-height);
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);
}

.search__input-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
}
@media all and (min-width: 75em) {
  .search__input-wrapper {
    max-width: 700px;
  }
}
.search__input-wrapper .search__actions {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transform: translate3d(-100%, 0px, 0);
}
.search__input-wrapper .search__submit {
  grid-row: 1;
  grid-column: 1;
  width: 12px;
  height: 12px;
  visibility: visible;
  pointer-events: auto;
}
.search__input-wrapper .search__submit .c-icon--search {
  width: 100%;
  height: 100%;
  display: block;
}
@media all and (min-width: 75em) {
  .search__input-wrapper .search__submit {
    display: block;
  }
}
.search__input-wrapper .search__close {
  grid-row: 1;
  grid-column: 1;
  visibility: hidden;
  pointer-events: none;
  width: 12px;
  height: 12px;
}
.search__input-wrapper .search__close .c-icon--close {
  display: block;
  width: 100%;
  height: 100%;
}

.search-open--has-results .search__submit, .search-open--no-results .search__submit {
  visibility: hidden;
  pointer-events: none;
}
.search-open--has-results .search__close, .search-open--no-results .search__close {
  visibility: visible;
  pointer-events: auto;
}

.search__input {
  color: var(--color-bone);
  font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  letter-spacing: 0.0113636364em;
  background: transparent;
  border-bottom: 0.5px solid var(--color-bone);
  padding-bottom: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem);
  padding-right: clamp(2.0833333333rem, 2.7777777778vw, 2.875rem);
  appearance: none;
  width: 100%;
}
.search__input::placeholder {
  color: #F7EEDF;
  opacity: 0.5;
}
@media all and (min-width: 75em) {
  .search__input {
    width: clamp(61.875rem, 82.5vw, 86.625rem);
  }
}

.search__results {
  background: var(--background);
  position: static;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-top: calc(var(--height) + var(--search-form-height));
  padding-left: var(--page-margins-inner);
  padding-right: var(--page-margins-inner);
}
@media all and (min-width: 75em) {
  .search__results {
    padding-right: calc(var(--page-margins-inner) - 20px);
  }
}
@media (min-width: 1200px) and (max-height: 600px) {
  .search__results > .search__view-all {
    display: none;
  }
}

.search__intro-message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: 0.025em;
  font-size: 20px;
  transition: opacity 0.2s ease-out;
}
@media all and (min-width: 48.0625em) {
  .search__intro-message {
    font-size: 26px;
  }
}
@media all and (min-width: 75em) {
  .search__intro-message {
    font-size: 2.0833333333vw;
    line-height: 1.28;
  }
}
.search__intro-message p {
  max-width: 90%;
  text-align: center;
  margin-bottom: 70px;
}
@media all and (min-width: 61.25em) {
  .search__intro-message p {
    max-width: 80%;
  }
}
@media all and (min-width: 75em) {
  .search__intro-message p {
    max-width: 63%;
  }
}
.has-searched .search__intro-message {
  display: none;
}

.c-modal__close.search__close-search {
  position: static;
}

.search__no-results-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: 0.025em;
  font-size: 20px;
  transition: opacity 0.2s ease-out;
}
@media all and (min-width: 48.0625em) {
  .search__no-results-message {
    font-size: 26px;
  }
}
@media all and (min-width: 75em) {
  .search__no-results-message {
    font-size: 2.0833333333vw;
    line-height: 1.28;
  }
}
.search__no-results-message p {
  max-width: 90%;
  text-align: center;
}
@media all and (min-width: 61.25em) {
  .search__no-results-message p {
    max-width: 80%;
  }
}
@media all and (min-width: 75em) {
  .search__no-results-message p {
    max-width: 63%;
  }
}

.search__results-tab-panels {
  margin-bottom: 0;
  position: relative;
}
.search__results-tab-panels::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
  z-index: 1;
}
.has-searched .search__results-tab-panels {
  display: block;
}
.search-open--load-results .search__results-tab-panels {
  pointer-events: none;
}
.search-open--load-results .search__results-tab-panels::after {
  opacity: 1;
  pointer-events: auto;
}

.search__results-tabs {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-self: center;
  margin-bottom: clamp(1.8229166667rem, 2.4305555556vw, 2.5rem);
}
.search__results-tabs > div {
  display: flex;
  column-gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 768px) {
  .search__results-tabs > div {
    width: auto;
    justify-content: flex-start;
  }
}
.search__results-tabs a {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.12em;
  color: var(--color-brand-gold);
  text-decoration: none;
}
@media all and (min-width: 48.0625em) {
  .search__results-tabs a {
    font-size: 12.5px;
  }
}
@media all and (min-width: 75em) {
  .search__results-tabs a {
    font-size: clamp(0.6510416667rem, 0.8680555556vw, 0.875rem);
  }
}
.search__results-tabs a:focus, .search__results-tabs a:active {
  outline: none;
}
.search__results-tabs a.active-list__item[aria-selected=true] {
  opacity: 1;
}
.search__results-tabs .search__view-all {
  display: none;
  align-self: center;
}
@media (min-width: 1200px) and (max-height: 600px) {
  .search__results-tabs .search__view-all {
    display: inline-block;
  }
}
.has-searched .search__results-tabs {
  visibility: visible;
}

.search__results-products {
  display: grid;
  padding-bottom: clamp(4.0625rem, 5.4166666667vw, 5.6875rem);
  grid-template-columns: repeat(2, 1fr);
  column-gap: 9px;
  row-gap: 12px;
}
@media all and (min-width: 61.25em) {
  .search__results-products {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
    row-gap: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
    padding-right: 10px;
  }
}

.search__results-recipes {
  display: grid;
  padding-bottom: clamp(4.0625rem, 5.4166666667vw, 5.6875rem);
  grid-template-columns: 1fr;
  gap: 15px;
}
@media all and (min-width: 48.0625em) {
  .search__results-recipes {
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
    padding-right: 10px;
  }
}

.search__results-pages {
  padding-bottom: clamp(4.0625rem, 5.4166666667vw, 5.6875rem);
}
.search__results-pages ul {
  list-style: none;
  list-style-type: none;
  columns: 20rem auto;
  column-gap: clamp(6.7708333333rem, 9.0277777778vw, 9.4375rem);
}
.search__results-pages li {
  margin-bottom: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
}
.search__results-pages a {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  text-decoration: none;
  color: var(--brand-bone);
}
@media all and (min-width: 48.0625em) {
  .search__results-pages a {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .search__results-pages a {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.search__results-pages a:focus, .search__results-pages a:hover {
  text-decoration: underline;
}

.search__result-card {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
}
.search__result-card .search__result-card-img {
  flex-shrink: 0;
  height: 200px;
  height: 20.305556vw;
}
@media (min-height: 900px) {
  .search__result-card .search__result-card-img {
    height: 24.305556vw;
  }
}
.search__result-card .search__result-card-img img, .search__result-card .search__result-card-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search__result-card h2 {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 16px;
  color: var(--color-bone);
}
.search__result-card h2 .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 48.0625em) {
  .search__result-card h2 {
    font-size: 18px;
  }
}
@media all and (min-width: 75em) {
  .search__result-card h2 {
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}
.search__result-card a {
  text-decoration: none;
}
.search__result-card a::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.search__product .search__result-card-img {
  aspect-ratio: 275/350;
}

.search__recipe .search__result-card-img {
  aspect-ratio: 572/350;
}

.search__results-tabs button[aria-selected=true] {
  opacity: 1;
  position: relative;
}
.search__results-tabs button[aria-selected=true]::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-brand-gold);
}
.search__results-tabs button:not([aria-selected=true]) {
  opacity: 0.7;
}
.search__results-tabs button:not([aria-selected=true]):hover {
  opacity: 0.9;
}

.search__results-products {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 9px;
  row-gap: 12px;
}
@media all and (min-width: 61.25em) {
  .search__results-products {
    grid-template-columns: repeat(4, 1fr);
    column-gap: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
    row-gap: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
  }
}

.search__results-recipes {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
@media all and (min-width: 48.0625em) {
  .search__results-recipes {
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}

.recipes-listing .search__results {
  transform: translate3d(0, 0, 0) !important;
}
.recipes-listing .search__results[style] {
  opacity: 1 !important;
  visibility: visible !important;
}

[role=tabpanel]:not(.selected) {
  display: none;
  visibility: hidden;
}

[role=tabpanel].selected {
  display: grid;
  visibility: visible;
}

.contact main {
  background-image: url("/img/pinnacle/backgrounds/mobile-dark-map-short.1743442783504.webp");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  padding-top: clamp(42px, 5.6vw, 84px);
}
@media all and (min-width: 48.0625em) {
  .contact main {
    background-position: right -3%;
    background-image: url("/img/pinnacle/backgrounds/map.1743442783504.webp");
    padding-top: clamp(7.34375rem, 9.7916666667vw, 10.25rem);
  }
}
.contact #m-wrapper {
  padding-top: var(--header-height);
}
@media all and (max-width: 48em) {
  .contact #m-wrapper {
    background-image: url("/img/pinnacle/backgrounds/page-bg-map.1743442783504.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 164%;
  }
}
.contact #m-wrapper .section-header--primary-short {
  padding-bottom: clamp(3.125rem, 4.1666666667vw, 4.375rem);
}
@media all and (max-width: 61.1875em) {
  .contact #m-wrapper .section-header--primary-short .section-header__copy {
    margin-bottom: clamp(28px, 3.7333333333vw, 56px);
  }
}
.contact #m-wrapper .section-header--primary-short .section-header__disclaimer p:first-of-type {
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.12em;
  letter-spacing: 1.25px;
  padding-bottom: 8px;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .section-header--primary-short .section-header__disclaimer p:first-of-type {
    font-size: 12.5px;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .section-header--primary-short .section-header__disclaimer p:first-of-type {
    font-size: clamp(0.6510416667rem, 0.8680555556vw, 0.875rem);
  }
}
.contact #m-wrapper .section-header--primary-short .section-header__disclaimer p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .section-header--primary-short .section-header__disclaimer p {
    font-size: 0.9722222222vw;
  }
}
.contact #m-wrapper .o-sidebar-layout {
  align-items: start;
  padding-top: 0;
}
@media all and (min-width: 61.25em) {
  .contact #m-wrapper .o-sidebar-layout .content-header {
    grid-column: span 2;
  }
}
.contact #m-wrapper .o-sidebar-layout .content-header h2 {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;
  font-size: 28px;
  color: #AB965D;
  margin-bottom: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem);
  text-transform: none;
  font-variant: initial;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .o-sidebar-layout .content-header h2 {
    font-size: clamp(1.875rem, 2.5vw, 2.625rem);
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .o-sidebar-layout .content-header h2 {
    font-size: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
  }
  .contact #m-wrapper .o-sidebar-layout .content-header h2 .u-type-subscripts__sub--tm {
    font-size: 30%;
  }
}
.contact #m-wrapper .o-sidebar-layout .content-header p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  line-height: 1.4em !important;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .o-sidebar-layout .content-header p {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .o-sidebar-layout .content-header p {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
.contact #m-wrapper .o-sidebar-layout .content-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 50px;
}
@media all and (min-width: 61.25em) {
  .contact #m-wrapper .o-sidebar-layout .content-grid {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 5vw;
    grid-column-gap: 8vw;
  }
}
.contact #m-wrapper .o-sidebar-layout .content-grid h3 {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 1.28;
  letter-spacing: 0.06em;
  font-size: 14px;
  color: #AB965D;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .o-sidebar-layout .content-grid h3 {
    font-size: 16px;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .o-sidebar-layout .content-grid h3 {
    font-size: clamp(0.9375rem, 1.25vw, 1.3125rem);
  }
}
.contact #m-wrapper .o-sidebar-layout .content-grid .blurb {
  padding: clamp(10px, 1.3333333333vw, 20px) 0 clamp(30px, 4vw, 60px) 0;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
}
@media all and (min-width: 61.25em) {
  .contact #m-wrapper .o-sidebar-layout .content-grid .blurb {
    padding: clamp(1.3020833333rem, 1.7361111111vw, 1.8125rem) 0 clamp(2.0833333333rem, 2.7777777778vw, 2.875rem) 0;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .o-sidebar-layout .content-grid .blurb {
    font-size: 0.9722222222vw;
  }
}
.contact #m-wrapper .o-sidebar-layout .content-grid a {
  display: inline-flex;
}
.contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar {
  display: grid;
  grid-row-gap: 2vw;
  grid-template-columns: 1fr;
  justify-items: center;
}
@media all and (min-width: 61.25em) {
  .contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7vw;
    justify-items: unset;
    align-content: flex-start;
  }
}
@media all and (max-width: 61.1875em) {
  .contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .content-header {
    padding-bottom: 36px;
  }
}
.contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .o-grid__item {
  color: #AB965D;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .o-grid__item {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .o-grid__item {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .o-grid__item a {
  flex-direction: row;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .o-grid__item .c-icon {
  background-color: rgba(52, 48, 43, 0.5);
  border-radius: 50%;
  height: 50px;
  margin-right: 20px;
  width: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact #m-wrapper .o-sidebar-layout .o-sidebar-layout__sidebar .o-grid__item .c-icon svg {
  max-height: 27px;
  max-width: 30px;
}
.contact #m-wrapper .grid--contact {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: clamp(18px, 2.4vw, 36px);
  margin: 0 clamp(24px, 3.2vw, 48px) clamp(69px, 9.2vw, 138px) clamp(24px, 3.2vw, 48px);
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .grid--contact {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0 clamp(7.0833333333rem, 9.4444444444vw, 9.875rem) clamp(3.59375rem, 4.7916666667vw, 5rem) clamp(7.0833333333rem, 9.4444444444vw, 9.875rem);
  }
}
.contact #m-wrapper .grid--contact .full h2, .contact #m-wrapper .grid--contact .half h2 {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: 0.025em;
  font-size: 20px;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .grid--contact .full h2, .contact #m-wrapper .grid--contact .half h2 {
    font-size: 26px;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .grid--contact .full h2, .contact #m-wrapper .grid--contact .half h2 {
    font-size: 2.0833333333vw;
    line-height: 1.28;
  }
}
.contact #m-wrapper .grid--contact .full p, .contact #m-wrapper .grid--contact .half p {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 16px;
  padding: clamp(22px, 2.9333333333vw, 44px) 0 clamp(18px, 2.4vw, 36px) 0;
}
.contact #m-wrapper .grid--contact .full p .u-type-subscripts__sub--tm, .contact #m-wrapper .grid--contact .half p .u-type-subscripts__sub--tm {
  font-size: 35%;
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .grid--contact .full p, .contact #m-wrapper .grid--contact .half p {
    font-size: 18px;
  }
}
@media all and (min-width: 75em) {
  .contact #m-wrapper .grid--contact .full p, .contact #m-wrapper .grid--contact .half p {
    font-size: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .grid--contact .full p, .contact #m-wrapper .grid--contact .half p {
    padding: clamp(0.5208333333rem, 0.6944444444vw, 0.6875rem) 0 clamp(0.78125rem, 1.0416666667vw, 1.0625rem) 0;
  }
}
.contact #m-wrapper .grid--contact .full picture, .contact #m-wrapper .grid--contact .half picture {
  z-index: 0;
}
.contact #m-wrapper .grid--contact .full img, .contact #m-wrapper .grid--contact .half img {
  position: absolute;
  bottom: 0;
  z-index: 0;
  top: 50%;
  max-width: none;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
.contact #m-wrapper .grid--contact .full a .btn__content::after, .contact #m-wrapper .grid--contact .half a .btn__content::after {
  border: 0;
}
.contact #m-wrapper .grid--contact .full .grid--copy, .contact #m-wrapper .grid--contact .half .grid--copy {
  position: relative;
  z-index: 1;
  max-width: 269px;
  margin: 0 0 clamp(18px, 2.4vw, 36px) clamp(18px, 2.4vw, 36px);
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .grid--contact .full .grid--copy, .contact #m-wrapper .grid--contact .half .grid--copy {
    max-width: 367px;
  }
}
.contact #m-wrapper .grid--contact .full {
  min-height: 439px;
  overflow: hidden;
}
.contact #m-wrapper .grid--contact .full .grid--copy {
  margin: clamp(287px, 38.2666666667vw, 574px) 0 clamp(18px, 2.4vw, 36px) clamp(18px, 2.4vw, 36px);
}
.contact #m-wrapper .grid--contact .half {
  min-height: 256px;
  overflow: hidden;
}
.contact #m-wrapper .grid--contact .half .grid--copy {
  margin: clamp(122px, 16.2666666667vw, 244px) 0 clamp(18px, 2.4vw, 36px) clamp(18px, 2.4vw, 36px);
}
@media all and (min-width: 48.0625em) {
  .contact #m-wrapper .grid--contact .full {
    grid-row: 1/span 1;
    grid-column: 1/span 2;
    min-height: 452px;
  }
  .contact #m-wrapper .grid--contact .full .grid--copy {
    margin: clamp(13.2291666667rem, 17.6388888889vw, 18.5rem) 0 clamp(2.1354166667rem, 2.8472222222vw, 2.9375rem) clamp(2.6041666667rem, 3.4722222222vw, 3.625rem);
  }
  .contact #m-wrapper .grid--contact .half {
    grid-row: 2/span 1;
    grid-column: 1/span 1;
    min-height: 452px;
  }
  .contact #m-wrapper .grid--contact .half .grid--copy {
    margin: clamp(14.2708333333rem, 19.0277777778vw, 19.9375rem) 0 clamp(2.1354166667rem, 2.8472222222vw, 2.9375rem) clamp(2.6041666667rem, 3.4722222222vw, 3.625rem);
  }
  .contact #m-wrapper .grid--contact .half:last-of-type {
    grid-column: 2/span 1;
  }
}
.contact .o-sidebar-layout__sidebar {
  padding-bottom: clamp(7.9166666667rem, 10.5555555556vw, 11.0625rem);
}
.contact .o-sidebar-layout__sidebar p {
  width: 86vw;
  margin: 0 auto;
}
.contact .o-sidebar-layout__sidebar .content-header {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: 0.025em;
  font-size: 20px;
  text-align: center;
  padding-bottom: clamp(2.5rem, 3.3333333333vw, 3.5rem);
}
@media all and (min-width: 48.0625em) {
  .contact .o-sidebar-layout__sidebar .content-header {
    font-size: 26px;
  }
}
@media all and (min-width: 75em) {
  .contact .o-sidebar-layout__sidebar .content-header {
    font-size: 2.0833333333vw;
    line-height: 1.28;
  }
}
.contact .o-sidebar-layout__sidebar .social {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact .o-sidebar-layout__sidebar .social .social-nav {
  gap: 20px;
}
.contact .o-sidebar-layout__sidebar .social .social-nav a:hover {
  color: #AB965D;
}
.contact .o-sidebar-layout__sidebar .social .social-nav .social-pinterest {
  width: clamp(1.6666666667rem, 2.2222222222vw, 2.3125rem);
  height: clamp(1.6666666667rem, 2.2222222222vw, 2.3125rem);
}
.contact .o-sidebar-layout__sidebar .social .social-nav .social-instagram {
  width: clamp(1.6666666667rem, 2.2222222222vw, 2.3125rem);
  height: clamp(1.6666666667rem, 2.2222222222vw, 2.3125rem);
}
.contact .o-sidebar-layout__sidebar .social .social-nav .social-facebook {
  width: clamp(1.0180208333rem, 1.3573611111vw, 1.375rem);
  height: clamp(1.6663541667rem, 2.2218055556vw, 2.3125rem);
}
.contact .o-sidebar-layout__sidebar .social .social-nav .social-x {
  width: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
  height: clamp(1.5625rem, 2.0833333333vw, 2.1875rem);
}
.contact .o-sidebar-layout__sidebar .social .social-nav .social-tiktok {
  width: clamp(1.6666666667rem, 2.2222222222vw, 2.3125rem);
  height: clamp(1.6666666667rem, 2.2222222222vw, 2.3125rem);
}
.contact .accordion--container {
  margin: 0 clamp(24px, 3.2vw, 48px);
}
@media all and (min-width: 48.0625em) {
  .contact .accordion--container {
    margin: 0 clamp(7.0833333333rem, 9.4444444444vw, 9.875rem);
  }
}

@media all and (max-width: 29.9375em) {
  #did-you-know .c-stamp-icon {
    width: 100%;
  }
  #did-you-know .c-stamp-icon::before {
    background-size: 100% auto;
  }
  #did-you-know .c-icon--health-md {
    width: 70%;
  }
}

._aha-table__heading {
  max-width: 27.7777777778em;
  margin-bottom: 0.25em;
  font-size: 1.125em;
  line-height: 1.1428571429;
}

._aha-table__logo-spacer {
  display: inline;
}
._aha-table__logo-spacer::after {
  white-space: pre;
  content: "                     ";
}

._aha-table__logo {
  position: absolute;
  top: -2.5em;
  right: 0;
  z-index: 10;
}

._aha-table__disclaimer {
  display: block;
  font-size: 0.875em;
}

._aha-table {
  position: relative;
  border-top: 1px solid #A6192E;
}
._aha-table .o-grid {
  justify-content: center;
}
._aha-table .o-grid > .o-grid__item {
  padding: 0.625em 0.9375em;
  border-bottom: 1px solid #282624;
}
._aha-table .o-grid > dt.o-grid__item {
  color: #8a7449;
  font-style: italic;
  font-size: 0.875em;
  text-align: center;
  border-right: 1px solid #282624;
}
._aha-table .o-grid > dd.o-grid__item {
  font-size: 0.875em;
  border-bottom: none;
}
._aha-table .o-grid:nth-of-type(odd) > dt.o-grid__item {
  background: #2f2c2a;
}
._aha-table .o-grid:nth-of-type(odd) > dd.o-grid__item {
  background: #1f1d1b;
}
._aha-table .o-grid:nth-of-type(even) > dt.o-grid__item {
  background: #322f2d;
}
._aha-table .o-grid:nth-of-type(even) > dd.o-grid__item {
  background: #211f1d;
}

@media (max-width: 41.25em) {
  ._aha-table__logo {
    transform: scale(0.75);
    transform-origin: 100% 50%;
  }
}
@media (min-width: 26.875em) {
  ._aha-table > dl :nth-child(2) :last-child {
    padding-right: 6.25em;
  }
  ._aha-table > dl :nth-child(3) :last-child {
    padding-right: 5em;
  }
}
@media (max-width: 26.8125em) {
  ._aha-table__logo {
    transform: scale(0.667);
    transform-origin: 100% 33.333%;
  }
  ._aha-table > dl :nth-child(2) :last-child {
    padding-right: 4.375em;
  }
}
.c-bold-teaser .c-bold-teaser__figure::after {
  content: "";
  display: block;
  padding: 0 0 80%;
  height: 0;
}
@media all and (max-width: 61.1875em) {
  .c-bold-teaser .c-bold-teaser__figure::after {
    display: none;
  }
}
.c-bold-teaser .c-bold-teaser__figure > img {
  width: 130%;
  max-width: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: -1;
}
.c-bold-teaser .c-bold-teaser__figure-img--lg {
  display: none;
}
@media all and (max-width: 61.1875em) {
  .c-bold-teaser .c-bold-teaser__figure-img--full {
    display: none;
  }
  .c-bold-teaser .c-bold-teaser__figure-img--lg {
    display: block;
  }
}
.c-bold-teaser .c-bold-teaser__figure-img--lg {
  width: 100% !important;
  position: static !important;
  transform: none !important;
}
.c-bold-teaser .c-bold-teaser__carousel-header {
  position: relative;
  z-index: 1;
}
.c-bold-teaser .c-bold-teaser__carousel {
  margin-top: -50px;
}
.c-bold-teaser .c-bold-teaser__carousel::before, .c-bold-teaser .c-bold-teaser__carousel::after {
  content: "";
  width: 25%;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}
@media all and (max-width: 48em) {
  .c-bold-teaser .c-bold-teaser__carousel::before, .c-bold-teaser .c-bold-teaser__carousel::after {
    width: 15%;
  }
}
@media all and (max-width: 29.9375em) {
  .c-bold-teaser .c-bold-teaser__carousel::before, .c-bold-teaser .c-bold-teaser__carousel::after {
    width: 10%;
  }
}
.c-bold-teaser .c-bold-teaser__carousel::before {
  background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
  left: 0;
}
.c-bold-teaser .c-bold-teaser__carousel::after {
  background: linear-gradient(to left, #000, rgba(0, 0, 0, 0));
  right: 0;
}
.c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button {
  width: 32px;
  height: 32px;
  background: center/32px no-repeat;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translate3d(0, -50%, 0);
  transition: opacity 125ms ease-in-out;
}
@media all and (max-width: 29.9375em) {
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button {
    top: 100%;
  }
}
.c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button:hover, .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button:focus {
  opacity: 1;
}
.c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button svg {
  display: none;
}
.c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.previous {
  background-image: url("/img/journeyboldly/teaser/carousel/arrow-left@2x.png");
  left: 25%;
}
.c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.next {
  right: 25%;
  background-image: url("/img/journeyboldly/teaser/carousel/arrow-right@2x.png");
}
@media (max-width: 920px) {
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.previous {
    left: 20%;
  }
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.next {
    right: 20%;
  }
}
@media all and (max-width: 48em) {
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.previous {
    left: 10%;
  }
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.next {
    right: 10%;
  }
}
@media (max-width: 580px) {
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.previous {
    left: 10px;
  }
  .c-bold-teaser .c-bold-teaser__carousel .flickity-prev-next-button.next {
    right: 10px;
  }
}
.c-bold-teaser .c-bold-teaser__carousel .c-product-label {
  max-width: 380px;
  height: auto;
  top: 115px;
  right: 0;
  left: auto;
  opacity: 1;
  transform: none;
}
@media all and (min-width: 93.75em) {
  .c-bold-teaser .c-bold-teaser__carousel .c-product-label {
    top: 200px;
    right: 40px;
  }
}
@media all and (max-width: 54.3125em) {
  .c-bold-teaser .c-bold-teaser__carousel .c-product-label {
    top: 0;
    right: 40px;
  }
}
@media all and (max-width: 48em) {
  .c-bold-teaser .c-bold-teaser__carousel .c-product-label {
    top: 115px;
    right: 40px;
  }
}
.c-bold-teaser .c-bold-teaser__carousel-item {
  width: 50%;
}
@media all and (max-width: 48em) {
  .c-bold-teaser .c-bold-teaser__carousel-item {
    width: 100%;
  }
}
.c-bold-teaser .c-bold-teaser__carousel-item .c-link__img {
  width: 80%;
  height: 80%;
  left: 12%;
  bottom: 0;
  top: auto;
}
.c-bold-teaser .c-bold-teaser__carousel-content {
  padding: 0 0 10px;
  position: relative;
  z-index: 1;
}
@media all and (max-width: 77.4375em) {
  .c-bold-teaser .c-heading--lg {
    font-size: 2.484375em;
  }
}
@media all and (max-width: 54.3125em) {
  .c-bold-teaser .c-heading--lg {
    font-size: 1.25em;
  }
}
@media all and (max-width: 54.3125em) {
  .c-bold-teaser .c-heading {
    font-size: 1.75em;
  }
}
.c-bold-teaser .c-button--play {
  margin-bottom: 20px;
}
@media all and (max-width: 54.3125em) {
  .c-bold-teaser .c-button--play .c-button--play {
    transform: scale(0.8);
    margin-bottom: 10px;
  }
}
.c-bold-teaser .c-button--play {
  min-height: 60px;
}

.c-learn-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}
.c-learn-more > span:first-child {
  padding-bottom: 17px;
}

/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
@keyframes come-in {
  to {
    opacity: 1;
  }
}
@keyframes come-in-scale {
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
@keyframes slight-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}
.section-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.section-header__content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: clamp(25px, 3.3333333333vw, 50px);
  padding-right: clamp(25px, 3.3333333333vw, 50px);
  margin: 6.3vw auto 0;
  width: 100%;
}
@media all and (min-width: 75em) {
  .section-header__content {
    padding-left: clamp(4.1666666667rem, 5.5555555556vw, 5.8125rem);
    padding-right: clamp(4.1666666667rem, 5.5555555556vw, 5.8125rem);
  }
}
@media all and (min-width: 42.5em) {
  .section-header__content {
    width: 64vw;
    margin: 0 auto;
  }
}
@media all and (min-width: 61.25em) {
  .section-header__content {
    width: 64vw;
  }
}
@media all and (min-width: 75em) {
  .section-header__content {
    padding-left: 0;
    padding-right: 0;
    width: clamp(39.0625rem, 52.0833333333vw, 54.6875rem);
  }
}
.section-header__content > *:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media all and (max-width: 42.4375em) {
  .section-header__content .section-header__ctas {
    margin: 0 auto 6.3vw;
  }
}
.section-header__content .section-header__disclaimer {
  text-align: center;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
  color: #BBAE96;
}
@media all and (min-width: 75em) {
  .section-header__content .section-header__disclaimer {
    font-size: 0.9722222222vw;
  }
}

.section-header__subheading {
  font-family: minion-pro, "times new roman", times, georgia, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: 700;
  line-height: 0.81;
  letter-spacing: 0.08em;
  font-size: 18px;
  text-align: center;
  color: var(--section-header-subheading);
  line-height: 1;
  margin-bottom: clamp(15px, 2vw, 30px);
}
@media all and (min-width: 48.0625em) {
  .section-header__subheading {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .section-header__subheading {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
@media all and (min-width: 75em) {
  .section-header__subheading {
    margin-bottom: clamp(1.3020833333rem, 1.7361111111vw, 1.8125rem);
  }
}

.section-header__heading {
  color: var(--section-header-heading);
  text-align: center;
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;
  font-size: 28px;
  margin-bottom: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
}
@media all and (min-width: 48.0625em) {
  .section-header__heading {
    font-size: clamp(1.875rem, 2.5vw, 2.625rem);
  }
}
@media all and (min-width: 75em) {
  .section-header__heading {
    font-size: clamp(2.1875rem, 2.9166666667vw, 3.0625rem);
  }
  .section-header__heading .u-type-subscripts__sub--tm {
    font-size: 30%;
  }
}

.section-header__copy {
  text-align: center;
  margin-bottom: clamp(68px, 9.0666666667vw, 136px);
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
  color: var(--section-header-copy);
}
@media all and (min-width: 48.0625em) {
  .section-header__copy {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .section-header__copy {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}
@media all and (min-width: 61.25em) {
  .section-header__copy {
    font-family: minion-pro-display, serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 1.35;
    letter-spacing: 0.025em;
    font-size: 18px;
    margin-bottom: clamp(1.8229166667rem, 2.4305555556vw, 2.5rem);
    max-width: clamp(37.5rem, 50vw, 52.5rem);
  }
}
@media all and (min-width: 61.25em) and (min-width: 48.0625em) {
  .section-header__copy {
    font-size: 20px;
  }
}
@media all and (min-width: 61.25em) and (min-width: 75em) {
  .section-header__copy {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}

.section-header__disclaimer {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.0285714286em;
  font-size: 14px;
  color: #BBAE96;
  padding-bottom: clamp(2.0833333333rem, 2.7777777778vw, 2.875rem);
}
@media all and (min-width: 75em) {
  .section-header__disclaimer {
    font-size: 0.9722222222vw;
  }
}

.c-button--pill {
  background-color: var(--btn-pill-background);
  transition: background-color 0.6s ease-in-out;
  overflow: hidden;
  position: static;
  font-family: myriad-pro-semiextended, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: 0.14em;
  letter-spacing: 2.15px;
  height: var(--pill-height);
  border: 1px solid var(--btn-pill-border);
  border-radius: 50px;
  color: var(--btn-pill-txt-color);
  text-decoration: none;
  line-height: normal;
}
@media all and (min-width: 48.0625em) {
  .c-button--pill {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
}
@media all and (min-width: 75em) {
  .c-button--pill {
    font-size: clamp(0.7291666667rem, 0.9722222222vw, 1rem);
  }
}
.c-button--pill .inside-wrapper {
  white-space: nowrap;
  display: flex;
  align-items: center;
  column-gap: 17px;
  padding-left: 15px;
  padding-right: 15px;
}
.c-button--pill .inside-wrapper .c-icon {
  color: var(--btn-pill-icon-color);
  transition: color 0.4s ease-in-out;
}
.microsoft .c-button--pill .btn--text {
  line-height: 0;
  padding-top: 0.17em;
}
.c-button--pill.pill--icon-only {
  min-width: clamp(3.3125rem, 4.8611111111vw, 5.0625rem);
}
.c-button--pill.pill--arrow:hover {
  color: var(--btn-pill-txt-color);
}
.c-button--pill.pill--arrow:hover .c-icon {
  color: var(--btn-pill-txt-color);
  transition: color 0.6s ease-in-out;
  animation: arrow-move 0.8s ease-in-out forwards;
}
.c-button--pill.pill--arrow:hover.prev {
  color: var(--btn-pill-txt-color);
}
.c-button--pill.pill--arrow:hover.prev .c-icon {
  color: var(--btn-pill-txt-color);
  transition: color 0.6s ease-in-out;
  animation: arrow-move 0.8s ease-in-out forwards;
}
@media all and (min-width: 75em) {
  .c-button--pill {
    height: clamp(2.2916666667rem, 3.0555555556vw, 3.1875rem);
  }
  .c-button--pill .inside-wrapper {
    column-gap: clamp(0.78125rem, 1.0416666667vw, 1.0625rem);
    padding-left: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
    padding-right: clamp(1.0416666667rem, 1.3888888889vw, 1.4375rem);
  }
}

.c-button--pill-dk {
  --btn-pill-border: #AB965D;
  --btn-pill-txt-color: #34302B;
  --btn-pill-background: transparent;
}
.c-button--pill-dk:hover {
  --btn-pill-background: var(--btn-pill-border);
}

.c-button--pill-lt {
  --btn-pill-border: #AB965D;
  --btn-pill-txt-color: #F7EEDF;
  --btn-pill-background: transparent;
}
.c-button--pill-lt:hover {
  --btn-pill-background: var(--btn-pill-border);
}

@keyframes arrow-move {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  25% {
    opacity: 1;
    transform: scale(0) translate3d(-200px, 0, 0);
    transform-origin: center;
  }
  40% {
    opacity: 1;
    transform: scale(0) translate3d(-200px, 0, 0);
    transform-origin: center;
  }
  75% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
}
@keyframes arrow-move--opposite {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  25% {
    opacity: 1;
    transform: scale(0) translate3d(200px, 0, 0);
    transform-origin: center;
  }
  40% {
    opacity: 1;
    transform: scale(0) translate3d(200px, 0, 0);
    transform-origin: center;
  }
  75% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transform-origin: center;
  }
}
.ready-additions {
  margin-bottom: clamp(100px, 13.3333333333vw, 200px);
}
.ready-additions .o-darken__overlay::before {
  opacity: 0.8;
}
.ready-additions .o-grid:not(.o-list--leading-zero) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
}
.ready-additions .o-list--leading-zero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.ready-additions .o-list--leading-zero .o-grid__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #34302B;
  padding: clamp(10px, 1.3333333333vw, 20px);
}
.ready-additions .o-list--leading-zero .o-grid__item a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
}

.ready-additions__products .c-product-category-grid > .o-grid__item {
  width: 100%;
}

.ready-additions-slice-guide {
  position: relative;
  background: #F0E5D8;
  color: #000;
  padding: 80px 40px 55px 40px;
}
.ready-additions-slice-guide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  border-width: 2.1875em;
  border-top-color: #000;
  transform: translate(-50%, 0);
}
.ready-additions-slice-guide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  border-width: 0.40625em;
  border-top-color: #8a7449;
  transform: translate(-50%, 0);
}
.ready-additions-slice-guide__inner {
  position: relative;
}
.ready-additions-slice-guide .c-heading {
  color: #000;
}
.ready-additions-slice-guide__item {
  position: relative;
}
.ready-additions-slice-guide__item::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 50%;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 0, 0);
  border-bottom: 2px dotted #A6192E;
}
.ready-additions-slice-guide__item:last-child::after {
  display: none;
}
@media all and (min-width: 48.0625em) {
  .ready-additions-slice-guide__item {
    position: relative;
  }
  .ready-additions-slice-guide__item::after {
    display: none;
  }
}

/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
@keyframes come-in {
  to {
    opacity: 1;
  }
}
@keyframes come-in-scale {
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
@keyframes slight-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}
.service-grid {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
@media all and (max-width: 48em) {
  .service-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}
.service-grid .service-grid__item {
  border-bottom: 1px solid rgba(151, 151, 151, 0.12);
}
.service-grid .service-grid__item div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.service-grid .service-grid__item div .c-heading--alt {
  margin-bottom: 0;
}
.service-grid .service-grid__item a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

#product-list {
  display: grid;
  row-gap: 7.5px;
  column-gap: 7.5px;
  grid-template-columns: 1fr;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 200ms ease-in, transform 300ms ease-in;
  width: 96%;
}
#product-list.hide {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 200ms ease-out, transform 300ms ease-out;
}
#product-list .product-list__title {
  padding-left: 3vw;
  font-size: 2.25em !important;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
#product-list .product-list__title:focus {
  outline: none;
}
#product-list .product-list__title > span {
  pointer-events: none;
}
#product-list .product-list__title .cross {
  height: 38px;
  width: 38px;
  position: relative;
  transform: translateX(-12px);
}
#product-list .product-list__title .cross::before {
  background: currentColor;
  content: "";
  height: 100%;
  left: calc(50% - 1px);
  position: absolute;
  top: 0;
  width: 2px;
}
#product-list .product-list__title .cross::after {
  background: currentColor;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 38px;
}
#product-list .product-list__title.active .cross:before {
  display: none;
}
@media all and (min-width: 48.0625em) {
  #product-list .product-list__title {
    grid-column: 1/span 2;
  }
}
#product-list .product-list__item {
  padding: 0.625em 1.25em;
  border-bottom: 1px solid rgba(151, 151, 151, 0.12);
}
#product-list .product-list__item h3 {
  line-height: 1.2;
}
#product-list .product-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  align-items: center;
}
#product-list .product-wrap img {
  max-width: none;
  object-fit: contain;
  font-family: "object-fit: contain;";
  background-color: black;
}
#product-list .product-wrap img.lazyloaded {
  opacity: 1 !important;
}
#product-list .product-wrap h3 {
  line-height: 1.2;
}
#product-list .product-list__container {
  display: grid;
  grid-template-columns: 1fr;
}
@media all and (min-width: 48.0625em) {
  #product-list .product-list__container {
    grid-template-columns: 1fr 1fr;
  }
}

.tooltip--heading {
  display: inline-block;
  cursor: pointer;
}
.tooltip--heading > span {
  display: inline-block;
}
.tooltip--heading > span:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(175%, -50%, 0) rotate(0deg);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #AB965D;
}
.tooltip--heading > span.is-active:after {
  content: "";
  transform: translate3d(175%, -50%, 0) rotate(180deg);
}

.tooltip {
  display: none;
  top: auto;
  border-radius: 10px;
  transform: translate3d(-50%, 105%, 0);
  z-index: 9;
  box-shadow: 1px 1px 26px rgba(0, 0, 0, 0.5);
}
.tooltip .c-copy--md {
  padding: 10px;
}
@media all and (min-width: 48.0625em) {
  .tooltip .c-copy--md {
    padding: 10px 5px;
  }
}
.tooltip .c-copy--md:not(:last-of-type) {
  border-bottom: 1px solid;
}
.tooltip .c-heading--md-alt {
  font-size: 0.625em;
  letter-spacing: 2.54px;
  display: flex;
  align-items: center;
}
.tooltip a {
  text-decoration: none;
}
.tooltip.is-active {
  display: block;
}

.c-heading--alt {
  font-size: 2.5625em;
  letter-spacing: 2px;
}

.c-heading--md-alt {
  font-size: 1em;
  letter-spacing: 2.54px;
}

.servicecase__alert {
  z-index: 9;
  position: relative;
  border-radius: 10px;
}
@media all and (max-width: 48em) {
  .servicecase__alert {
    margin-top: -20%;
  }
}

@media all and (max-width: 48em) {
  .c-servicecase_detail {
    padding-right: 0;
    padding-left: 0;
  }
}

@media all and (max-width: 48em) {
  .c-servicecase_wrap {
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
}

.c-copy--alt {
  letter-spacing: 2.25px;
}

.slice-guide {
  border-top: solid 1px rgba(151, 151, 151, 0.12);
}

.servicecase__back {
  position: fixed;
  bottom: auto;
  top: 3em;
  left: 1.25em;
  right: auto;
  z-index: 99;
  display: block;
  border-radius: 10px;
}
@media all and (min-width: 48.0625em) {
  .servicecase__back {
    top: 10.625em;
  }
}

.servicecase__tips {
  position: fixed;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  z-index: 99;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  background-color: #AB965D;
}
.servicecase__tips.js-accordion__container {
  transform: translate(0, 90%);
  transition: transform 150ms ease-in;
}
.servicecase__tips.js-accordion__container.open {
  transform: translate(0, 0);
  transition: transform 150ms ease-out;
}
.servicecase__tips.slice-guide {
  transform: translate(0, 90%);
  transition: transform 150ms ease-in;
}
@media all and (min-width: 48.0625em) {
  .servicecase__tips.slice-guide {
    transform: translate(0, 90%);
  }
}
.servicecase__tips.slice-guide .js-accordion__item {
  max-width: 425px;
  margin: 0 auto;
  background-color: #000;
}
.servicecase__tips .accordion-title {
  position: relative;
  letter-spacing: 0.01em;
  padding-bottom: 5px;
}
.servicecase__tips .accordion-title .pattern {
  background-image: url(/img/servicecase/card_pattern.png);
  background-repeat: repeat;
}
.servicecase__tips .order_number {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 74px;
  opacity: 0.44;
  transform: translate3d(-71%, -50%, 0);
  display: block;
  color: #DEC88D;
}
.servicecase__tips .order_number:after {
  content: "";
  height: 1.5px;
  width: 22px;
  background-color: #DEC88D;
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  transform: translate(-6px, 0);
}

.c-servicecase__add-on {
  opacity: 1 !important;
  display: flex !important;
  position: fixed;
  left: 0;
  background: #161614;
  top: 0;
}
.c-servicecase__add-on ._service-bar__quality {
  position: relative;
  width: 100%;
  display: block;
  background: #8a7449;
  z-index: 1001;
  height: auto;
  width: 100%;
}
.c-servicecase__add-on ._service-bar__quality .u-display-grid {
  display: grid;
  grid-template-columns: 2fr 7fr;
  grid-template-rows: 1fr;
  align-items: center;
}
@media all and (min-width: 48.0625em) {
  .c-servicecase__add-on ._service-bar__quality .u-display-grid {
    grid-template-columns: 1fr 6fr;
  }
}
.c-servicecase__add-on ._service-bar__quality .u-display-grid .c-heading {
  padding-right: 20px;
  border-right: 1.5px solid #DEC88D;
}
@media all and (min-width: 48.0625em) {
  .c-servicecase__add-on ._service-bar__quality .u-display-grid .c-heading {
    white-space: nowrap;
  }
}
.c-servicecase__add-on ._service-bar__quality .u-display-grid .c-subheading--sm {
  margin-left: 20px;
}
.c-servicecase__add-on ._service-bar__container {
  height: 0;
  visibility: hidden;
  width: 100vw;
  max-width: 100vw;
}
.c-servicecase__add-on ._servicecase-bar__dropdown {
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}
.c-servicecase__add-on ._servicecase-bar__dropdown button {
  background-color: #000;
  border-radius: 10px;
  border: 2px solid transparent;
}
.c-servicecase__add-on ._servicecase-bar__dropdown button > span {
  pointer-events: none;
}
.c-servicecase__add-on ._servicecase-bar__dropdown button.selected {
  border: 2px solid #AB965D;
}
.c-servicecase__add-on ._servicecase-bar__dropdown::-webkit-scrollbar {
  display: none;
}

[data-filter]:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.servicecase-landing {
  padding-top: 8.5em;
}

.c-health-attributes.c-servicecase_wrap {
  display: grid;
  grid-column: 1/span 2;
  grid-template-rows: 1fr;
  grid-template-columns: none;
}
@media all and (max-width: 48em) {
  .c-health-attributes.c-servicecase_wrap {
    grid-column: 1;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-template-rows: none;
  }
}
.c-health-attributes.c-servicecase_wrap .c-health-attribute {
  max-width: none;
  margin: 0 0.625em 0.625em 0;
}
@media all and (min-width: 48.0625em) {
  .c-health-attributes.c-servicecase_wrap .c-health-attribute {
    grid-row: 1;
  }
}
.c-health-attributes .c-health-attribute.c-health-attribute-feingold-food-list, .c-health-attributes .c-health-attribute.c-health-attribute-no-caramel-color, .c-health-attributes .c-health-attribute.c-health-attribute-no-msg {
  display: none;
}

.service-case--grid .c-product-detail__content {
  grid-template-columns: 3fr 2fr;
  display: grid;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 48em) {
  .service-case--grid .c-product-detail__content {
    padding: 30px 0;
    grid-template-columns: 1fr;
  }
}

[data-promo-id=counterculture] .c-modal__content-container {
  max-width: 375px;
  height: 525px;
}
[data-promo-id=counterculture] .c-icon--close svg {
  fill: #fff;
}
[data-promo-id=counterculture] .c-hero__logo {
  width: 305px;
  height: 120px;
  display: block;
  margin: 126px 0 60px 0;
  flex-shrink: 0;
  position: relative;
}
[data-promo-id=counterculture] .c-hero__logo::after {
  content: "";
  position: absolute;
  bottom: -26px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 50px;
  height: 2px;
  background: #AB965D;
}
[data-promo-id=counterculture] .c-stamp {
  width: 111px;
  height: 115px;
  transform: translate3d(-20px, -33%, 0px) rotate(17deg);
  opacity: 0.2;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-promo-id=counterculture] .c-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/img/counterculture/modal-bg.jpg") no-repeat;
}
[data-promo-id=counterculture] p {
  padding: 0 40px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  margin: 0 0 40px 0;
  width: 100%;
}
[data-promo-id=counterculture] .btn--gold {
  display: inline-flex;
  align-items: center;
  font-family: bureau-grot-wide, sans-serif;
  text-transform: uppercase;
  border: solid 2px #AB965D;
  padding: 17px 39px 16px 39px;
  text-decoration: none;
  font-size: 0.75em;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 56px;
  z-index: 1;
}
[data-promo-id=counterculture] .btn--gold:after {
  height: 500%;
}

[data-promo-id=pitcraft-modal] .c-modal__content {
  height: 380px;
}
@media all and (min-width: 48.0625em) {
  [data-promo-id=pitcraft-modal] .c-modal__content {
    height: 500px;
  }
}
[data-promo-id=pitcraft-modal] .c-modal__content .c-button--box {
  transform: translate(40%, -115%);
}
@media all and (min-width: 48.0625em) {
  [data-promo-id=pitcraft-modal] .c-modal__content .c-button--box {
    transform: translate(40%, -160%);
  }
}

.ticket-link:focus .ticket-wrap {
  position: relative;
}
.ticket-link:focus .ticket-wrap:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
}

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}

#skip-to-main-content.screen-reader-only:focus-visible,
#disable-autoplaying-media.screen-reader-only:focus-visible {
  overflow: auto !important;
  clip-path: unset;
  width: auto;
  height: auto;
  z-index: 2000;
  left: 0;
  top: 0;
  clip: unset;
  padding: 10px;
  background: #211f1d;
}

@media print {
  .header__navigation-drawer {
    display: none;
  }
  .c-header {
    position: static;
    border-bottom: 0;
  }
  .c-header::before {
    content: none;
  }
  .recipe-detail__content {
    padding-top: 0;
  }
  .c-header--sticky {
    position: static;
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
  }
  .c-header--sticky .c-header__nav {
    height: auto;
  }
  main,
  #main {
    padding-top: 10px;
    padding-bottom: 0;
    border-bottom: 0;
    min-height: inherit;
  }
  .o-section__content {
    padding-top: 20px;
    margin-bottom: 0;
  }
  .c-hero__scroll-button,
  .c-header__item--menu {
    display: none;
  }
  .c-hero__preserve-aspect-ratio {
    padding: 0;
  }
  .c-hero {
    max-height: none;
  }
  hr {
    display: none;
  }
  #footer {
    display: none;
  }
  .c-footer.is-fixed {
    position: static;
  }
  .c-footer__placeholder {
    display: none;
  }
  .u-margin-bottom-xs {
    margin-bottom: 10px;
  }
  .u-padding-bottom-sm {
    padding-bottom: 10px;
  }
  .u-margin-top-md {
    margin-top: 20px;
  }
  .c-product-recommendations,
  .c-product-detail__sidebar,
  .c-product-grid__figure {
    display: none;
  }
  .c-product-detail__content {
    margin-bottom: 10px;
    min-height: inherit;
    font-size: 14px;
  }
  .c-product-detail__content .o-grid--inner-border--sm > .o-grid__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  [href="#nutrition-modal"] {
    display: none;
  }
  #nutrition-modal {
    display: block;
    opacity: 1;
    visibility: visible;
    position: static;
  }
  #nutrition-modal .c-modal__close {
    display: none;
  }
  .c-health-attributes {
    margin-top: 0;
    margin-bottom: 0;
  }
  ._recipe-image {
    display: none;
  }
  .recipe-detail {
    color: black;
  }
  .o-sidebar-layout__sidebar {
    display: none;
  }
  .o-list--ingredients .o-list__item,
  .o-list--leading-zero .o-list__item--instructions {
    margin-bottom: 0;
  }
  #at-expanded-menu-v2 {
    display: none;
  }
  .disable-print {
    display: none !important;
  }
  .recipe-detail__meta-share {
    display: none;
  }
  .recipe-detail__details {
    margin-top: 100px;
  }
}
.enable-print {
  display: none !important;
}
@media print {
  .enable-print {
    display: block !important;
  }
}

._reset-lists ol,
._reset-lists ul {
  padding-left: 2.5em;
}
._reset-lists ol {
  list-style: decimal;
}
._reset-lists ul {
  list-style: disc;
}

._disable-click {
  user-select: none;
  pointer-events: none;
}

._fix-ios-z-index {
  transform: translateZ(0);
}

._view-products {
  margin: 1.25em auto 3em;
}
._view-products ~ .c-featured-product {
  margin-top: -6em;
}

._c-product-grid__link--back-to-school .c-product-grid__figure .c-link__img {
  width: 113%;
  max-width: 113%;
}