@use "sass:math";

.o-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.o-circle--xs {
  $size: 30px;
  @include circle($size);
  @include circle-hover($size);
  width: $size;
  height: $size;
}

.o-circle--sm {
  $size: 60px;
  @include circle($size);
  @include circle-hover($size);
  width: $size;
  height: $size;
}
