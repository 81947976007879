.u-font-style-italic {
  font-style: italic;
}

.u-font-style-normal {
  font-style: normal;
}

.u-text-decoration-none {
  text-decoration: none;
}

.u-text-decoration-underline {
  text-decoration: underline;
}

.u-nowrap {
  white-space: nowrap;
}

.u-font-weight-600 {
  font-weight: 600;
}
