@use "sass:math";

$bp-align-position-zero: em(1100, 16);

.float_left{
  float:left;
  margin-right:20px;
}
.float_right{
  float:right;
  margin-left:20px;
}

.c-position-zero {
  overflow: visible;
}

.c-position-zero__header {
  @include mq('md') {
    padding-top: $gutter-md;
    padding-bottom: $gutter-md;
  }
}



.c-position-zero__grid {
  // HACK: Add margin above grid between 2-column and 3-column layouts
  @media (min-width: map-get($breakpoints, 'sm')) and (max-width: map-get($breakpoints, 'md') - 0.0625em) {
    margin-top: $gutter-md;
  }
}

.position-zero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $gutter-xs 0;

  @include mq('lt-sm') {
    align-items: center;
    text-align: left;
  }
  @include mq('md') {
    padding: $gutter-md;
  }
  @media (min-width: $bp-align-position-zero) {
    padding: $gutter-lg;
  }
}

@include mq('sm') {
  .c-position-zero__content {
    padding-right: 4%;
    padding-left: 4%;

    .c-position-zero__list-item--right & {
      align-items: flex-start;
      padding-right: 0;
      text-align: left;
    }

    .c-position-zero__list-item--left & {
      align-items: flex-end;
      padding-left: 0;
      text-align: right;
    }
  }
}

@include mq('sm') {
  @include mq('lt-md') {
    .c-position-zero__list-item--lg  {
      flex-direction: column-reverse;
      margin-top: $gutter-sm;

      .c-position-zero__content {
        align-items: center;
        text-align: left;
      }
    }
  }
}

.c-position-zero__list-item {
  @include mq('lt-sm') {
    // // Reverse items so on mobile media comes first, then copy
    // flex-direction: column-reverse;
    margin-top: $gutter-sm;
  }

  // HACK: Vertical align top and add space below to avoid breaks between rows when content runs too long
  @media (min-width: map-get($breakpoints, 'md')) and (max-width: $bp-align-position-zero - 0.0625em) {
    padding-bottom: 6%;

    > * {
      justify-content: flex-start;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.o-preserve-aspect-ratio--11x14 {
    padding: 0 0 percentage(math.div(14, 11));

}
