@use "sass:math";

// .o-darken {
// }

.o-darken__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;

  // Overlay
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-black-1;
    content: '';
    opacity: 0.15;
  }
  // Radial
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
      ellipse at center,
      #{rgba($color-black-1, 1)} 0%,
      #{rgba($color-black-1, 0)} #{percentage(math.div(1, 3))}
    );
    content: '';
    opacity: 0.4;
  }

  &.o-darken__overlay--darker {

    &::before {
      opacity: .4;
    }
  }
}

.o-darken__button {
  &::before {
    $color: #0d0806;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 266.67%;
    height: 266.67%;
    transform: translate(-50%, -50%);
    background: radial-gradient(
      ellipse at center,
      #{rgba($color, 1)} 0%,
      #{rgba($color, 0)} 50%
    );
    content: '';
    opacity: 0.2;
    z-index: -1;
    pointer-events: none;
  }
}



// ------------------------------
// Responsive
// ------------------------------

.o-darken\@lt-md {
  @include mq('md') {
    .o-darken__overlay {
      display: none;
    }
  }
}
