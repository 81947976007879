@use "sass:math";

.c-featured-product {
  overflow: visible;

  // HACK: Adjust mobile padding for header
  @include mq('lt-md') {
    header {
      padding-bottom: 0;
    }
  }
}

._featured-product-min-height {
  @include mq('lt-sm') {
    margin-right: -$gutter-sm;
    margin-left: -$gutter-sm;
  }
  @include mq('lg') {
    min-height: em(280);
  }
}

.c-featured-product__figure {
  // scss-lint:disable ZeroUnit
  @include o-zorro-svg(1104px, 280px, 0px, 'bottom');
  // scss-lint:enable ZeroUnit

  .o-zorro-svg__image {
    z-index: 10;
  }
}

// HACK: Shrink featured products to "encourage scrolling"
@include mq('lg') {
  .c-featured-product__figure {
    margin-top: -$gutter-xs;
    margin-bottom: -$gutter-sm;
    transform: scale(0.9);
  }
  // Note: This `transform` needs to be added to each custom position (see `c-featured-product__caption` mixin)
  .c-featured-product__caption {
    transform: scale(#{math.div(1, 0.9)});
  }
}



$bp-trim-featured-product: map-get($breakpoints, 'md'); // em(700, 16);

// Featured Product Variables
$fp-img-width: 1104px;
$fp-img-height: 280px;
$fp-circle-size: 193px;
$fp-circle-size-offset: math.div((221px - 203px), 2);
// Position of point's center relative to image
$fp-circle-x: 875.5px;
$fp-circle-y: 203.50px;

.c-featured-product__caption {
  display: flex;
  position: absolute;
  top: percentage(math.div($fp-circle-y, $fp-img-height));
  left: percentage(math.div($fp-circle-x, $fp-img-width));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-brand-gold;
  text-decoration: none;

  &:focus {
    outline:none;
  }
  &:focus .c-link--cta {
    border-bottom: 6px solid $color-brown-3;
  }
}

.c-link--cta {
  &:focus, &:active {
    outline:none;
    border-bottom: 6px solid $color-brown-3;
    margin-bottom: -5px;
  }
}

// TODO: Merge with `.c-button--play` styles
@media (max-width: $bp-trim-featured-product - 0.0625em) {
  .c-featured-product__caption {
    $size: em(80, 18);
    $size-hover: em(94, 18);
    @include circle($size, $size-hover);
    @include circle-hover($size, $size-hover);
    width: $size-hover;
    height: $size-hover;

    // Hide extra labels
    .c-featured-product__subheading {
      display: none;
    }
    .c-featured-product__heading {
      @include visually-hidden;
    }

    // HACK: Remove underline on mobile
    .c-link--cta {
      border-bottom: 0;
    }
  }
}

@media (min-width: $bp-trim-featured-product) {
  .c-featured-product__caption {
    $size: 203px;
    $size-hover: 193px;
    @include circle($size, $size-hover);
    @include circle-hover($size, $size-hover);
    width: $size-hover;
    height: $size-hover;
  }
}

// scss-lint:disable ZeroUnit
@mixin c-featured-product__caption($x, $y) {
  // Position of circle's top left relative to point
  $offset-x: $x - $fp-circle-x - $fp-circle-size-offset;
  $offset-y: $y - $fp-circle-y - $fp-circle-size-offset;
  transform: translate(#{percentage(math.div($offset-x, $fp-circle-size))}, #{percentage(math.div($offset-y, $fp-circle-size))});

  // HACK: Unshrink featured product captions
  @include mq('lg') {
    transform: scale(#{math.div(1, 0.9)}) translate(#{percentage(math.div($offset-x, $fp-circle-size))}, #{percentage(math.div($offset-y, $fp-circle-size))});
  }
}

// .c-featured-product__caption--13044 { // home
//   @include c-featured-product__caption(772px, 0px);
// }
// .c-featured-product__caption--278 {   // turkey
//   @include c-featured-product__caption(772px, 0px);
// }
// .c-featured-product__caption--11005 { // ham
//   @include c-featured-product__caption(760px, -33px);
// }
// .c-featured-product__caption--235 {   // beef
//   @include c-featured-product__caption(750px, -38px);
// }
// .c-featured-product__caption--13014,  // chicken
// .c-featured-product__caption--13008 { // all-natural
//   @include c-featured-product__caption(800px, -18px);
// }
// .c-featured-product__caption--339 {   // franks-sausages-bacon
//   @include c-featured-product__caption(795px, -33px);
// }
// .c-featured-product__caption--15037 { // cheese
//   @include c-featured-product__caption(720px, -18px);
// }
// .c-featured-product__caption--357 {   // bolognas-wursts-loaves
//   @include c-featured-product__caption(700px, -18px);
// }
// .c-featured-product__caption--936,    // condiments
// .c-featured-product__caption--13034 { // bold
//   @include c-featured-product__caption(745px, -33px);
// }
// .c-featured-product__caption--16162 , // hummus
// .c-featured-product__caption--524 {   // charcuterie
//   @include c-featured-product__caption(772px, -39px);
// }
// scss-lint:enable ZeroUnit



// HACK: Override default heading margin
.c-featured-product__subheading {
  margin-bottom: 0;
}

.c-featured-product__heading,
.c-featured-product__link {
  color: inherit; // TODO: Should this be a generic component modifier instead? Or a utility?
  line-height: 1;
}

.c-featured-product__heading {
  width: 100%;
  margin: em(7) auto em(14);
  padding: 0 em(18);
}

// .c-featured-product__link {
// }
