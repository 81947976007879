@mixin o-overflow-horizontal($at-breakpoint: null) {
  @include breakpoints('o-overflow-horizontal', $at-breakpoint) {
    width: 100%;
    overflow-x: scroll;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    .o-overflow-horizontal__inner {
      flex-wrap: nowrap;
      min-width: 100%;
      white-space: nowrap;
    }
  }
}

@include o-overflow-horizontal('lt-1240');
