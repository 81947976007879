.u-animation-delay-100ms {
  animation-delay: 100ms;
}

.u-animation-delay-1100ms {
  animation-delay: 1100ms;
}

.u-animation-bounce {
  animation: bounce 2000ms ease infinite;
}
