.c-counter {
  width: 220px;
  height: 60px;
  background: url('/img/october-taste-of-giving/count-bg.png') no-repeat center center;
  font-size: 36px;
  line-height: 58px;
  text-align: right;
  letter-spacing: 19px;
  color: $color-pink-1;
  margin: 5px auto;
  font-style: italic;
}
