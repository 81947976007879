@use "sass:math";

.c-testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq('lt-md') {
    // TODO: Add carousel slider on mobile
    // HACK: Hide extra testimonials on mobile until carousel is added
    &:not(:first-child) {
      display: none;
    }
  }
}

// Note: These classes are just placeholders. We need an extra `div` in the markup to use flexbox's `space-between`.
// .c-testimonial__top {
// }
.c-testimonial__bottom {
  padding-top: $gutter-xs;
}

.c-testimonial__quote {
  font-style: italic;
  letter-spacing: tracking(25);
}

.c-testimonial__icon {
  margin-bottom: em(18);
}

// Note: Uses `.u-small-caps`
.c-testimonial__author {
  display: block;
  color: $color-gray-3;
  font-size: em(21);
  letter-spacing: tracking(25);
  line-height: math.div(15, 21);
}

// Note: Uses `.u-small-caps`
.c-testimonial__source {
  display: block;
  letter-spacing: tracking(25);
  line-height: math.div(21, 16);
}
