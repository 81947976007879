// .o-lazyload {
// }

.o-lazyload__placeholder {
  transform: scale(1.05);
  filter: blur(5px);
}

.o-lazyload__image {
  transition: opacity 400ms ease-out;

  &.lazyload {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
  }

  &--no-transition {
    transition: none;
  }
}
