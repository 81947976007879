// Note: We shouldn't need any `u-columns-1` classes. Just use a breakpoint
// class to specify when columns should be used.

.u-columns-2 {
  columns: 2;
}

@include mq('sm') {
  .u-columns-2\@sm {
    columns: 2;
  }
}

@include mq('md') {
  .u-columns-2\@md {
    columns: 2;
  }
}



.u-column-gap-sm {
  column-gap: $gutter-sm;
}
.u-column-gap-md {
  column-gap: $gutter-md;
}
// .u-column-gap-md\@md {
//   @include mq('md') {
//     column-gap: $gutter-md\@md;
//   }
// }
