// Reset default list styles (e.g., for News content or WYSIWYG editors)
._reset-lists {
  ol,
  ul {
    padding-left: 2.5em;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
}

._disable-click {
  user-select: none;
  pointer-events: none;
}

._fix-ios-z-index {
  transform: translateZ(0);
}

// Margin hacks for "View Products" buttons on Product Listing pages
._view-products {
  margin: ($gutter-xs) auto ($gutter-md);

  ~ .c-featured-product {
    margin-top: (-2 * $gutter-md);
  }
}


// Back to school images are 1200x748. This allows
// those images to render wider in the grid. A 13%
// increase in width accounts for the lack of height.
._c-product-grid__link--back-to-school {
  .c-product-grid__figure {
    .c-link__img {
      width: 113%;
      max-width: 113%;
    }
  }
}
