@include u-align-items('center');
@include u-align-items('flex-start');
@include u-align-items('flex-end');
@include u-align-self('center');
@include u-justify-content('center');
@include u-justify-content('space-between');
@include u-justify-content('flex-start');
@include u-flex-direction('column');

.u-flex-nowrap {
  flex-wrap: nowrap;
}
.u-flex-nowrap\@md {
  @include mq('md') {
    flex-wrap: nowrap;
  }
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-order-1 {
  order:1;
}

.u-order-2 {
  order:2;
}

.u-order-3 {
  order:3;
}

@include u-flex('1', 1);
@include u-flex('2', 2);
@include u-flex('3', 3);
@include u-flex('4', 4);
@include u-flex('5', 5);
@include u-flex('6', 6);

// TODO: Rename as `u-flex-0-0-auto` and `u-flex-1-1-auto`
.u-flex-shrink {
  flex: 0 0 auto;
}
.u-flex-grow {
  flex: 1 1 auto;
}
.u-flex-1-100{
  flex:1 100%;
}
.u-flex-1-54{
  flex:1 54%;
}
.u-flex-1-46{
  flex:1 46%;
}
// ------------------------------
// Responsive
// ------------------------------

@include u-flex-direction('column');
@include u-flex-direction('column', '1040');
@include u-flex-direction('row', 'md');
@include u-flex-direction('row', 'xl');
@include u-flex-wrap('wrap', 'md');

// Max-width (descending)
@include u-flex-direction('column-reverse', 'lt-lg');
@include u-flex-direction('column-reverse', 'lt-md');
@include u-justify-content('center', 'lt-1024');
@include u-justify-content('space-evenly', 'lt-md');
@include u-flex('1-1-auto', 1 1 auto !important, 'lt-620'); // HACK: Use `!important` to override `.o-media > .o-media__figure`

@include u-flex('1', 1, 'lt-sm');

// Min-width (ascending)
@include u-flex-direction('row-reverse', 'md');
@include u-flex('2', 2, 'md');
@include u-flex('4', 4, 'md');
@include u-flex('3', 3, 'md');
@include u-align-items('center', 'lt-1024');
