
#onetrust-banner-sdk * {
  box-sizing: border-box !important;
}

.onetrust {
  .ot-sdk-show-settings, .optanon-show-settings{
    letter-spacing: 0.01em;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "minion-pro", "times new roman", times, georgia, serif;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.375;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    height: auto;
    text-decoration: underline;
    white-space: normal;
    padding: 0;
    cursor: pointer;
    color: #ab965d;
    background-color: inherit;
    font-size: inherit !important;
  }

  .ot-sdk-show-settings:hover, .optanon-show-settings:hover {
    color: #c3aa86;
    background-color: inherit;
  }
  .otnotice-section-content span, strong {
    background: inherit !important;
  }

  .otnotice-menu {
    margin-bottom: 50px;
  }

}

#onetrust-banner-sdk #onetrust-policy-text, #onetrust-banner-sdk .ot-b-addl-desc, #onetrust-banner-sdk .ot-gv-list-handler{
  font-size: 14px !important;
  line-height: inherit !important;
  text-align: center !important;
  max-width: 80%;
  @include mq('1160') {
    max-width: none;
    text-align: left !important;
  }
}


.onetrust-pc-sdk {
  .ot-pc-footer-logo{
    background: black !important;
  }
}

.onetrust-pc-btn-handler {
  background: #ab965d !important;
  color: white !important;
  text-decoration: none !important
}

#onetrust-banner-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
  font-size: 16px !important;
  text-transform: lowercase !important;
  font-variant-caps: small-caps !important;
  font-feature-settings: 'smcp';
  display: flex !important;
  flex-direction: row-reverse !important;
  font-feature-settings: 'smcp';
}

#onetrust-pc-btn-handler {
  font-size: 16px !important;
  white-space: nowrap !important;
  max-width: none !important;
  text-align: center;
  text-transform: lowercase !important;
  font-variant-caps: small-caps !important;
  font-feature-settings: 'smcp';
}

.onetrust-button-group {
  width: auto;
}

#onetrust-button-group {
  display: flex !important;
  flex-direction: column !important;
  @include mq('md') {
    flex-direction: row-reverse !important;
  }
}

#onetrust-accept-btn-handler{
  justify-content: center !important;
  white-space: nowrap !important;
}


#onetrust-banner-sdk .banner-actions-container {
  width: auto !important;
}

#onetrust-pc-sdk h3, #onetrust-pc-sdk h4, #onetrust-pc-sdk h5 {
  font-size: 16px !important;
  text-transform: lowercase !important;
  font-variant-caps: small-caps !important;
}

#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
  color: #ab965d !important;
}

#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
  background-color: rgb(206, 194, 160) !important;
  border: 1px solid #ab965d !important;
}

#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob:before{
  background-color: #ab965d !important;
  border-color: #ab965d !important;
}

#onetrust-pc-sdk .ot-cat-item>button:focus, #onetrust-pc-sdk .ot-acc-cntr>button:focus, #onetrust-pc-sdk li>button:focus {
  outline: #ab965d solid 2px !important;
}

#onetrust-pc-sdk .ot-host-item>button:focus, #onetrust-pc-sdk .ot-ven-item>button:focus {
  border: 2px solid #ab965d !important;
}

#onetrust-pc-sdk .ot-host-desc, #onetrust-pc-sdk .ot-host-info {
  font-size: 14px !important;
}

#onetrust-pc-sdk #filter-btn-handler {
  background-color: #ab965d !important;
}

.ot-pc-footer-logo {
  background: black !important;
}

#onetrust-button-group-parent.ot-sdk-three.ot-sdk-columns {
  position: static;
  transform: none !important;
  width: auto !important;
  flex-shrink: 0;
}

#onetrust-banner-sdk.vertical-align-content #onetrust-button-group-parent {
  @media only screen and (min-width: 897px) and (max-width: 1023px){
    position: static !important;
    top: inherit !important;
    left: inherit !important;
  }
}

#onetrust-banner-sdk #onetrust-button-group  {
  @media only screen and (min-width: 1025px) and (max-width: 1267px){
    margin-left: 2em !important;
  }
  @media only screen and (min-width: 769px) {
    margin-right: 0 !important;
  }
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  position: static !important;
  @media only screen and (min-width: 426px) and (max-width: 896px){
    margin-left: 1em !important;
  }
}

#onetrust-policy-text {
  font-size: 16px !important;
  margin-bottom: 0 !important;

}

#onetrust-policy {
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 1em !important;
  @include mq('1160') {
    justify-content: flex-start;
  }
}

#onetrust-banner-sdk .ot-sdk-row {
  justify-content: center;
  display: flex !important;
  position: relative;
  flex-direction: column;
  justify-content: center;
  @include mq('1160') {
    flex-direction: row;
    padding: 1em !important;
  }
}
#onetrust-group-container.ot-sdk-eight.ot-sdk-columns {
  width: auto !important;
  display: flex;
  justify-content: center;
  @include mq('1160') {
    justify-content: flex-start;
  }
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
  @media only screen and (min-width: 1280px) {
    margin-left: 2em;
    padding: 0;
  }
}

#onetrust-button-group-parent {
  @media only screen and (min-width: 897px) and (max-width: 1023px){
    margin-left: 2em !important
  }
}

#onetrust-policy-text a:focus,
a.privacy-notice-link:focus {
  outline: none !important;
}
