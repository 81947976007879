@use "sass:math";

@include u-width('60', em(60));   // Journey Boldly section banners
@include u-width('100', em(100)); // Awards featured logo
@include u-width('145', em(145)); // Awards logo

// TODO: `@each` to get all the combos we need
@include u-width('1/1', percentage(math.div(1, 1)));
@include u-width('1/2', percentage(math.div(1, 2)));
@include u-width('1/3', percentage(math.div(1, 3)));
@include u-width('2/3', percentage(math.div(2, 3)));
@include u-width('1/4', percentage(math.div(1, 4)));
@include u-width('3/4', percentage(math.div(3, 4)));
@include u-width('1/5', percentage(math.div(1, 5)));
@include u-width('2/5', percentage(math.div(2, 5)));
@include u-width('4/5', percentage(math.div(4, 5)));
@include u-width('9/10', percentage(math.div(9, 10)));

@include u-width('29/100', percentage(math.div(29,100)));
// ------------------------------
// Responsive
// ------------------------------

@include u-width('1/2', percentage(math.div(1, 2)), 'lt-md');
@include u-width('1/1', percentage(math.div(1, 1)), 'lt-sm');
@include u-width('2/3', percentage(math.div(2, 3)), 'lt-sm');
@include u-width('1/1', percentage(math.div(1, 1)), 'lt-md');
@include u-width('1/3', percentage(math.div(1, 3)), 'lt-md');
@include u-width('3/4', percentage(math.div(3, 4)), 'lt-md');
@include u-width('1/1', percentage(math.div(1, 1)), 'lt-1040');

@include u-width('1/2', percentage(math.div(1, 2)), '360');
@include u-width('1/2', percentage(math.div(1, 2)), '620');
@include u-width('1/2', percentage(math.div(1, 2)), 'sm');
@include u-width('1/3', percentage(math.div(1, 3)), 'sm');
@include u-width('2/3', percentage(math.div(2, 3)), 'sm');
@include u-width('1/4', percentage(math.div(1, 4)), 'sm');
@include u-width('3/4', percentage(math.div(3, 4)), 'sm');
@include u-width('1/1', percentage(math.div(1, 1)), 'md');
@include u-width('1/2', percentage(math.div(1, 2)), 'md');
@include u-width('1/3', percentage(math.div(1, 3)), 'md');
@include u-width('2/3', percentage(math.div(2, 3)), 'md');
@include u-width('1/4', percentage(math.div(1, 4)), 'md');
@include u-width('1/5', percentage(math.div(1, 5)), 'md');
@include u-width('2/5', percentage(math.div(2, 5)), 'md');
@include u-width('3/5', percentage(math.div(3, 5)), 'md');
@include u-width('4/5', percentage(math.div(4, 5)), 'md');
@include u-width('3/4', percentage(math.div(3, 4)), 'md');
@include u-width('9/10', percentage(math.div(9, 10)), 'md');
@include u-width('1/2', percentage(math.div(1, 2)), 'lg');
@include u-width('1/4', percentage(math.div(1, 4)), 'lg');
@include u-width('2/3', percentage(math.div(2, 3)), 'lg');
@include u-width('3/4', percentage(math.div(3, 4)), 'lg');
@include u-width('2/3', percentage(math.div(2, 3)), '1300');
@include u-width('2/3', percentage(math.div(2, 3)), '1400');
@include u-width('2/3', percentage(math.div(2, 3)), '1440');
@include u-width('3/4', percentage(math.div(3, 4)), '1160');
@include u-width('9/10', percentage(math.div(9, 10)), '1160');
@include u-width('3/5', percentage(math.div(3, 5)), '1400');
@include u-width('3/5', percentage(math.div(3, 5)), '1600');
@include u-width('1/2', percentage(math.div(1, 2)), '1040');
@include u-width('3/4', percentage(math.div(3, 4)), '1040');
@include u-width('1/1', percentage(math.div(1, 1)), '680');
@include u-width('1/2', percentage(math.div(1, 2)), '870');
@include u-width('1/3', percentage(math.div(1, 3)), '870');
@include u-width('1/1', percentage(math.div(1, 1)), '900');
@include u-width('1/2', percentage(math.div(1, 2)), '900');
@include u-width('1/2', percentage(math.div(1, 2)), '1024');
@include u-width('1/3', percentage(math.div(1, 3)), '1024');
@include u-width('1/3', percentage(math.div(1, 3)), '1400');
@include u-width('1/3', percentage(math.div(1, 3)), '1440');
@include u-width('1/4', percentage(math.div(1, 4)), '1024');
@include u-width('1/5', percentage(math.div(1, 5)), '1024');
@include u-width('1/1', percentage(math.div(1, 1)), '1024');
@include u-width('1/1', percentage(math.div(1, 1)), '1040');
@include u-width('1/4', percentage(math.div(1, 4)), '1160');
@include u-width('1/4', percentage(math.div(1, 4)), 'xl');
@include u-width('1/2', percentage(math.div(1, 2)), 'xl');
@include u-width('3/4', percentage(math.div(3, 4)), '1240');
@include u-width('9/10', percentage(math.div(9, 10)), '1240');

@include u-width('1/1', percentage(math.div(1, 1)), 'lt-620');
@include u-width('1/1', percentage(math.div(1, 1)), 'lt-680');
@include u-width('1/1', percentage(math.div(1, 1)), '680');

// ------------------------------
// Quick Fix: Product Detail extras
// ------------------------------

.u-width-1\/1\@800 {
  @media (min-width: em(800, 16)) {
    width: percentage(math.div(1, 1));
  }
}

.u-width-1\/1\@500 {
  @media (min-width: em(500, 16)) {
    width: percentage(math.div(1, 2));
  }
}
.u-width-1\/2\@500 {
  @media (min-width: em(500, 16)) {
    width: percentage(math.div(1, 1));
  }
}
.u-width-1\/1\@350 {
  @media (min-width: em(350, 16)) {
    width: percentage(math.div(1, 1));
  }
}
