@use "sass:math";

$desktop-bp: '870';

@mixin slide-hover() {
  &::after {
    opacity: 0;
  }

  &:hover  {
    .product-recommendations__view {
      opacity: 1;
    }

    .product-recommendations__slide-media {
      img, video {
        transform: scale3d(1.1, 1.1, 1.1);
      }

      &::after {
        opacity: 1;
      }
    }
  }
}

.product-recommendations {
  display: flex;
  flex-direction: column;
  align-items: center;

  .feature-carousel__arrow {
    display: none;
    position: absolute;
    z-index: 3;

    .c-icon {
      width: scale-1200(45px);
      height: auto;
    }

    @include mq('lg') {
      top: 0;
      display: block;
      // transform: none;
      aspect-ratio: 573/500;
      width: scale-1200(404px);
      max-width: scale-1200(404px);
      transform: translate3d(0, 0, 0);

      .c-icon {
        transition: transform .2s var(--motion-easing);
      }
    }
  }

  .feature-carousel__next {
    top: 0;
    right: 0;

    .c-icon {
      transform: translate3d(50%, 0, 0);
    }

    &:hover {
      .c-icon {
        transform: translate3d(100%, 0, 0);
      }
    }
  }

  .feature-carousel__previous {
    top: 0;
    left: 0;

    .c-icon--arrow-reverse {
      transform: translate3d(-50%, 0, 0) rotate(180deg);
    }

    &:hover {
      .c-icon--arrow-reverse {
        transform: translate3d(-100%, 0, 0) rotate(180deg);
      }
    }
  }
}

.product-recommendations--static {
  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);
  justify-content: center;

  .product-recommendations__slide {
    &::after {
      display: none;
    }

    @include slide-hover();
  }
}

.product-recommendations__heading {
  margin-bottom: scale-1200(33px);
  h2 {
    @include heading--md();
    color: $color-brand-gold;
    text-align: center;
  }
}

.product-recommendations__carousel {
  width: 100%;
  display: flex;

  margin-bottom: scale-1200(42px);
}

.product-recommendations__slide {
  width: 86.933333vw;
  max-width: 86.933333vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: scale-1200(28px);
  // margin-right: 8px;

  text-decoration: none;
  color: var(--color-bone);

  &:focus {
    text-decoration: underline;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    opacity: 1;
    transition: opacity .2s ease-out;
  }



  @include mq($desktop-bp) {
    width: scale-1200(573px);
    max-width: scale-1200(573px);
    // margin-right: scale-1200(22px);
  }

  &.swiper-slide-active {
    @include slide-hover();
  }
}

.product-recommendations--static {

  .product-recommendations__carousel {
    column-gap: scale-1200(22px);
    flex-direction: column;
    align-items: center;
    row-gap: scale-1200(30px);
    justify-content: center;

    @include mq('md') {
      flex-direction: row;
      align-items: flex-start;
    }

  }

  .product-recommendations__slide-title {
    opacity: 1;
    transition: none;
  }

  .product-recommendations__slide {
    margin-right: 0;
    max-width: 100%;
    flex-shrink: 1;
  }
}

.product-recommendations__slide-media {

  position: relative;

  aspect-ratio: 573 / 352;
  overflow: hidden;
  clip-path: polygon(0 0, 99% 0, 100% 100%, 0% 100%);

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .5s ease-out;
    transform-origin: center;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--product-recommendation-hover-overlay);
    transition: opacity .2s ease-out;
    opacity: 0;
    border-bottom: 8px solid var(--product-recommendation-hover-border);
  }
}

.product-recommendations__slide-title {

  opacity: 0;
  transition: 1s var(--motion-easing);

  p {
    max-width: 60%;
    text-align: center;
    margin: 0 auto;
  }
}

.product-recommendations__slide.swiper-slide-active {
  .product-recommendations__slide-title {
    opacity: 1;
  }
}

.product-recommendations__view {

  opacity: 0;
  transition: opacity .2s ease-out;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;

  width: scale-1200(57.5px);
  height: scale-1200(57.5px);

  text-decoration: none;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    border: 1px solid var(--color-bone);

    transform: rotate(-45deg);
    transition: transform .2s var(--motion-easing);

    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    @include cta--xs();
    color:  var(--color-bone);
  }
}
