// ------------------------------
// Text
// ------------------------------

@include u-color('primary', $color-primary);
@include u-color('secondary', $color-secondary);

@include u-color('black-3', $color-black-3);
@include u-color('black-1', $color-black-1);

@include u-color('white', '#fff');
@include u-color('white-2', $color-white-2);
@include u-color('white-3', $color-white-3);

@include u-color('brown-3', $color-brown-3);
@include u-color('brown-4', $color-brown-4);

@include u-color('gray-1', $color-gray-1);
@include u-color('gray-2', $color-gray-2);
@include u-color('gray-3', $color-gray-3);
@include u-color('gray-4', $color-gray-4);
@include u-color('gray-7', $color-gray-7);
@include u-color('gray-8', $color-gray-8);
@include u-color('gray-9', $color-gray-9);
@include u-color('gray-10', $color-gray-10);
@include u-color('gray-11', $color-gray-11);
@include u-color('gray-12', $color-gray-12);
@include u-color('gray-13', $color-gray-13);

@include u-color('gold-1', $color-gold-1);
@include u-color('gold-2', $color-brand-gold);
@include u-color('gold-8', $color-gold-8);

@include u-color('red-1', $color-brand-red);
@include u-color('red-2', $color-red-2);

@include u-color('pink-1', $color-pink-1);

// to do update mixin to include hover states
a.u-color-pink-1 {
  @include hover {
    color: $color-pink-1;
    transition: opacity 0.25s ease;
    opacity: 0.85;
  }
}

// ------------------------------
// Links
// ------------------------------

// todo: is there a better way to handle this - currently using on new-detail
.u-color-primary--links a {
  color: $color-primary;
}
