@use "sass:math";

// TODO: Move to mode-front-end

/**
 * Wrap overlapping elements to avoid extra horizontal space in Safari—the
 * only browser that doesn't correctly hide `overflow-x` on the `body`.
 */
._fix-safari-overflow {
  @include scroll-disabled;
}

/**
 * Wrap inline SVGs with an `object` to fix Safari's broken height calculations.
 * http://benfrain.com/attempting-to-fix-responsive-svgs-in-desktop-safari
 */
._fix-safari-svg-height {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

._fix-safari-svg-height__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-zorro-svg {
  position: relative;
  width: 100%;
  height: 0;
  margin: -2px 0;
  padding: 0 0 100%;
  overflow: visible;
  z-index: 1;
}

.o-zorro-svg__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  pointer-events: none;
}

.o-zorro-svg__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * Full-width overlay backgrounds.
 * @param  {Number} $img-width
 * @param  {Number} $img-height
 * @param  {Number} $offset
 * @param  {String} $overlay-direction
 */
@mixin o-zorro-svg($img-width, $img-height, $offset, $overlay-direction, $is-responsive: false) {
  padding: 0 0 percentage(math.div($img-height, $img-width));

  // Note: `margin` is based on parent width but `height` is based on parent `height`
  // HACK: Remove 2px to avoid whitespace showing up in Chrome (and other browsers too, I assume)
  @if $overlay-direction == 'bottom' {
    margin-bottom: calc(#{percentage(math.div(-1 * $offset, $img-width))} - 2px);
  } @else {
    margin-top: calc(#{percentage(math.div(-1 * $offset, $img-width))} - 2px);
  }

  .o-zorro-svg__image {
    height: 100%;
    padding: 0;
  }

  .o-zorro-svg__content {
    position: absolute;
    left: 0;
    width: 100%;
    // TODO: Use math.abs() for offset
    height: percentage(math.div(($img-height - $offset), $img-height));

    @if $overlay-direction == 'bottom' {
      top: 0;
    } @else {
      top: percentage(math.div($offset, $img-height));
    }
  }

  // TODO: Use `breakpoints` to set responsive styles instead of hard-coded query
  // @if ($is-responsive) {
  //   @include mq('lt-md') {
  //     height: auto;
  //     margin: -2px 0;
  //     padding-bottom: 0;
  //
  //     .o-zorro-svg__image {
  //       height: percentage($img-height / ($img-height - $offset));
  //
  //       @if ($overlay-direction != 'bottom') {
  //         top: auto;
  //         bottom: 0;
  //       }
  //     }
  //
  //     .o-zorro-svg__content {
  //       position: relative;
  //       height: auto;
  //     }
  //   }
  // }
}
