@use "sass:math";

@include o-video();
@include o-video__wrapper();
@include o-video__element();
@include o-video__close();

.o-video {
  position: relative;

  iframe {
    // display:none;
    visibility: hidden;
  }

  &.is-active iframe {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  >a.o-video__full-link {
    @include mq('md'){
    @include full-area-cta();

    }
    cursor: none;
  }
}

.o-video__preserve-aspect-ratio {
  width: 100%;
  height: 0;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include mq('lt-xl') {
  .o-video__preserve-aspect-ratio {
    padding: 0 0 100%;
  }
}

@include mq('xl') {
  .o-video__preserve-aspect-ratio {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

.o-video__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.o-video__figure {
  background: $color-black-1;
}

.o-video__figure .c-video-controls__button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

// http://embedresponsively.com/
.o-video__media {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  // Note: Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio here
  height: 100%;
  transform: translateY(-50%);
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// .o-video__play-button {
// }

// TODO: Convert to a separate centering object
.o-video__play-button--center {
  // scss-lint:disable ImportantRule
  position: absolute !important; // TODO: Remove position from `.c-button--play`
  // scss-lint:enable ImportantRule
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    display: none;
  }
}



// ------------------------------
// Themes
// ------------------------------

.o-video--overlay {
  .o-video__full-link {
    span:not(.screen-reader-only) {
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // ada - hide until video plays
  #hero-video {
    // display: none;
    visibility: hidden;
  }

  .o-video__figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50; // Keep above `.c-hero__scroll-button`
  }

  .o-video__media {
    z-index: 100;
  }

  // Hide play button until YouTube is ready; hide figure until video is loading
  // Note: Video should always be `visible` so that touch devices only have to tap once to play videos
  .o-video__play-button,
  .o-video__figure {
    transition: opacity 300ms ease;
    opacity: 0;
  }

  // HACK: Add extra color transition to play button when switching to loading animation
  .o-video__play-button {
    transition: opacity 300ms ease,
      color 300ms ease;
  }

  // State
  @include mq('lt-md') {
    .o-video__play-button {
      opacity: 1;
      z-index: 100;
    }
  }

  // State
  &.is-ready {
    .o-video__play-button {
      opacity: 1;
      z-index: 100;
    }
  }

  // Hide media until video is playing
  &.is-loading {
    .o-video__media {
      transition: none;
      opacity: 0;
    }
  }

  &:not(.is-loading) {
    .o-video__media {
      transition: opacity 300ms ease;
      opacity: 1;
    }
  }

  &:not(.is-active) {

    // ADA hide video container completely when not active
    .o-video__figure {
      // display: none;
      visibility: hidden;
    }

    .o-video__controls {
      visibility: hidden;
    }
  }

  &.is-active {

    // ada - show whem video plays
    #hero-video {
      display: block;
      visibility: visible;
    }

    .o-video__play-button {
      opacity: 1;
      z-index: 0;
    }

    .o-video__figure {
      opacity: 1;
      display: block;
      visibility: visible;
    }

    // ADA show video container when active and hide sibling elements - this breaks recipes detail
    >*:not(.o-video__figure, .c-hero__preserve-aspect-ratio, .o-preserve-aspect-ratio, .o-carousel) {
      // display: none;
      visibility: hidden;
    }
  }
}


// Overrides for /everroast:
// Hover state variation where the button only changes when you hover over it,
// instead of the entire area
@include mq('md') {
  .o-video--condensed-hover {
    .c-hero__content {
      z-index: 50;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .c-hero__header,
      .c-button--play {
        width: 50%;
        margin-left: auto;
        margin-right: 0;
      }

      .c-button--play {
        span {
          max-width: 80px;
        }
      }
    }

    &.is-active {
      .c-hero__content {
        z-index: 10;
      }
    }

    .c-button--play.is-active {
      color: $color-gold-1  !important;

      // &::after {
      //   color: $color-brand-gold !important;
      //   transform: translate(-50%, -50%) scale(1) !important;
      // }
      // &:hover::after  {
      //   color: $color-gold-1 !important;
      //   $size: em(80, 18);
      //   $size-hover: em(94, 18);
      //   transform: translate(-50%, -50%) scale(#{math.div($size-hover, $size)});
      // }
    }
  }
}

@include mq('lt-lg') {
  .o-video--condensed-hover {
    .c-hero__header {
      font-size: 12px;
    }
  }
}

// end overrides for everroast

// Loading animation
// Based on https://github.com/tobiasahlin/SpinKit/blob/384fbc3f654f7ef7349134e116f9b6d0a4d7d852/examples/5-pulse.html
.o-video--overlay.is-active {
  $size: em(80, 18);
  $size-hover: em(94, 18);

  .o-video__play-button {
    color: transparent;
  }

  // .o-video__play-button::before {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: $size;
  //   height: $size;
  //   transform: translate(-50%, -50%) scale(1);
  //   border-radius: 50%;
  //   background-color: $color-gold-1;
  //   content: '';
  //   animation: pulse-scale-center 1.5s infinite ease-in-out;
  // }
  // HACK: Extra hover states
  .o-video__play-button:hover::before,
  .o-video__play-button:focus::before,
  .o-video__play-button:active::before,
  .o-video__play-button.is-active::before {
    width: $size-hover;
    height: $size-hover;
  }
}

@keyframes pulse-scale-center {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}



// Backdrop Settings
$nav-offset : 100px;
$nav-transition : 300ms;

.o-video__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: -20vh;
  left: 0;
  transition: opacity $nav-transition ease,
    visibility $nav-transition linear 0s;
  background: $color-black-1;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

// scss-lint:disable QualifyingElement
body.has-video-backdrop {
  .o-video__backdrop {
    opacity: 0.5;
    visibility: visible;
  }

  // TODO: Merge with `.c-off-canvas-nav__backdrop`
  // &::after {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: -20vh;
  //   left: 0;
  //   transition: opacity 300ms ease,
  //     visibility 300ms linear 0s;
  //   background: $color-black-1;
  //   opacity: 0;
  //   visibility: hidden;
  //   z-index: 30;
  //   content: '';
  //
  //   opacity: 0.5;
  //   visibility: visible;
  // }
}

// scss-lint:disable ImportantRule
body.is-fullscreen {

  .c-header,
  .c-footer {
    z-index: -1 !important;
  }

  [class*="js-fade"] {
    animation: none !important;
  }
}

// scss-lint:enable ImportantRule
// scss-lint:enable QualifyingElement



// ------------------------------
// Lazyload
// ------------------------------

video[data-lazyload] {
  transition:
    opacity 300ms ease,
    visibility 300ms linear 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.has-loaded {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}
