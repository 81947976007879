@import '../settings/colors';

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px,1px,1px,1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}

// These should be handled by their respective components
// form input:active,
// form input:focus-visible,
// form select:active,
// form select:focus-visible,
// form textarea:active,
// form textarea:focus-visible {
//   outline: 1px solid $color-brand-gold;
// }
// button:focus-visible {
//   outline: 1px solid $color-brand-gold;
// }


#skip-to-main-content.screen-reader-only:focus-visible,
#disable-autoplaying-media.screen-reader-only:focus-visible {
  overflow: auto !important;
  clip-path: unset;
  width: auto;
  height: auto;
  z-index: 2000;
  left: 0;
  top: 0;
  clip: unset;
  padding: 10px;
  background: $color-black-2;
}
