// TODO: Should this be a margin utility instead?
.o-center-block {
  margin-right: auto;
  margin-left: auto;
}

.o-center-block\@lt-900 {
  @include mq('lt-900') {
    margin-right: auto;
    margin-left: auto;
  }
}

.o-center-block\@lt-md {
  @include mq('lt-md') {
    margin-right: auto;
    margin-left: auto;
  }
}

.o-center-block\@lt-620 {
  @include mq('lt-620') {
    margin-right: auto;
    margin-left: auto;
  }
}

.o-center-block\@lt-sm {
  @include mq('lt-sm') {
    margin-right: auto;
    margin-left: auto;
  }
}
