@use "sass:math";

$content-width: 1200px;

.c-ornament {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
}

.c-ornament__figure {
  height: 0;
  background: center / cover no-repeat;

  // HACK: Force background removal on mobile
  // scss-lint:disable ImportantRule
  @include mq('lt-md') {
    background-image: none !important;
  }
  // scss-lint:enable ImportantRule
}

// TODO: Mixin
// @mixin c-ornament() {
// }

// .c-ornament--salami {
//   $width: 459px;
//   $height: 309px;
//   width: percentage($width / $content-width);
//   max-width: $width;
//   transform: translate(
//     #{percentage(-699px / $width)},
//     #{percentage(-66px / $height)}
//   );
//
//   > .c-ornament__figure {
//     padding: 0 0 percentage($height / $width);
//     background-image: url('/img/views/product-landing/background-ingredients/salami.jpg');
//   }
// }

.c-ornament--salami-new {
  $width: 409px;
  $height: 276px;
  width: percentage(math.div($width, $content-width));
  max-width: $width;
  transform: translate(
    #{percentage(math.div(-668px, $width))},
    #{percentage(math.div(1px, $height))}
  );
  // -99 => -68
  // 18  => 85

  > .c-ornament__figure {
    padding: 0 0 percentage(math.div($height, $width));
    background-image: url(cache-ts-url('/img/views/product-landing/background-ingredients/salami-new.jpg'));
  }
}

// .c-ornament--olives {
//   $width: 448px;
//   $height: 484px;
//   width: percentage($width / $content-width);
//   max-width: $width;
//   transform: translate(
//     #{percentage(275px / $width)},
//     #{percentage(-165px / $height)}
//   );
//
//   > .c-ornament__figure {
//     padding: 0 0 percentage($height / $width);
//     background-image: url('/img/views/product-landing/background-ingredients/olives.jpg');
//   }
// }

.c-ornament--cheese-new {
  $width: 376px;
  $height: 344px;
  width: percentage(math.div($width, $content-width));
  max-width: $width;
  transform: translate(
    #{percentage(math.div(243px, $width))},
    #{percentage(math.div(6px, $height))}
  );
  // 875 => 843
  // 449 => 620

  > .c-ornament__figure {
    padding: 0 0 percentage(math.div($height, $width));
    background-image: url('/img/views/product-landing/background-ingredients/cheese-new.jpg');
  }
}

.c-ornament--heart-new{
    $width: 470px;
    $height: 500px;
    width: percentage(math.div($width, $content-width));
    max-width: $width;
    left:0;
    top:0;
    // 875 => 843
    // 449 => 620

    > .c-ornament__figure {
      padding: 0 0 percentage(math.div($height, $width));
      background-image: url(cache-ts-url('/img/landing/backtoschool/sandwichoffer.png'));
    }
}
.c-ornament--bento-new{
    $width: 506px;
    $height: 600px;
    width: percentage(math.div($width, $content-width));
    max-width: $width;
    left:auto;
    right:0;
    top:0;
    // 875 => 843
    // 449 => 620

    > .c-ornament__figure {
      padding: 0 0 percentage(math.div($height, $width));
      background-image: url('/img/landing/backtoschool/bentobox.png');
    }
}
.c-ornament--paperclip-new{
    $width: 300px;
    $height: 500px;
    width: percentage(math.div($width, $content-width));
    max-width: $width;
    left:0;
    bottom:0;
    top: -9em;
    // 875 => 843
    // 449 => 620

    > .c-ornament__figure {
      padding: 0 0 percentage(math.div($height, $width));
      background-image: url(cache-ts-url('/img/landing/backtoschool/paperclips.png'));
    }
}
.c-ornament--crayons-new{
    $width: 250px;
    $height: 500px;
    width: percentage(math.div($width, $content-width));
    max-width: $width;
    left:auto;
    right:0;
    top:-250px;
    // 875 => 843
    // 449 => 620

    > .c-ornament__figure {
      padding: 0 0 percentage(math.div($height, $width));
      background-image: url(cache-ts-url('/img/landing/backtoschool/crayons.png'));
    }
}
