// Reset font size (useful for headings)
.u-font-size-100\% {
  font-size: 100%;
}

.u-font-size-xs {
  font-size: em(10);
}

.u-font-size-13 {
  font-size: em(13);
}
.u-font-size-14 {
  font-size: em(14);
}
.u-font-size-18 {
  font-size: em(18);
}
.u-font-size-21 {
  font-size: em(21);
}
.u-font-size-25 {
  font-size: em(25);
  letter-spacing: tracking(30);
}
.u-font-size-30 {
  font-size: 30px;
}
