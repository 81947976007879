@use "sass:math";

// ------------------------------
// Did You Know?
// ------------------------------

// Shrink icon on mobile
// scss-lint:disable IdSelector
#did-you-know {
  @include mq('lt-sm') {
    .c-stamp-icon {
      width: 100%;
    }
    .c-stamp-icon::before {
      background-size: 100% auto;
    }
    .c-icon--health-md {
      width: 70%;
    }
  }
}
// scss-lint:enable IdSelector



// ------------------------------
// Table
// ------------------------------

// TODO: Store colors in variables; normalize if close enough
// TODO: Separate table styles from `.o-grid` (objects should be mutually exclusive)

._aha-table__heading {
  max-width: em(500, 18);
  margin-bottom: 0.25em;
  font-size: em(18);
  line-height: math.div(16, 14);
}

// Add a bunch of space to the end of the heading so text can flow to full width without overlapping the table logo
._aha-table__logo-spacer {
  display: inline;

  &::after {
    white-space: pre;
    content: '\00a0          \00a0          \00a0';
  }
}

._aha-table__logo {
  position: absolute;
  top: em(-40);
  right: 0;
  z-index: 10;
}

._aha-table__disclaimer {
  display: block;
  // color: #787167;
  // color: #ccc;
  font-size: em(14);
}

// scss-lint:disable all
._aha-table {
  position: relative;
  border-top: 1px solid $color-brand-red;

  .o-grid {
    justify-content: center;
    > .o-grid__item {
      padding: em(10) em(15);
      border-bottom: 1px solid #282624;
    }
    > dt.o-grid__item {
      color: $color-gold-1;
      font-style: italic;
      font-size: em(14);
      text-align: center;
      border-right: 1px solid #282624;
    }
    > dd.o-grid__item {
      font-size: em(14);
      border-bottom: none;
    }
  }

  .o-grid:nth-of-type(odd) {
    > dt.o-grid__item {
      background: #2f2c2a;
    }
    > dd.o-grid__item {
      background: #1f1d1b;
    }
  }

  .o-grid:nth-of-type(even) {
    > dt.o-grid__item {
      background: #322f2d;
    }
    > dd.o-grid__item {
      background: #211f1d;
    }
  }
}

// HACK: Start shrinking the logo
@media (max-width: em(660, 16)) {
  ._aha-table__logo {
    transform: scale(0.75);
    transform-origin: 100% 50%;
  }
}

// HACK: Force first two rows of table to wrap before going underneath the table logo
@media (min-width: em(430, 16)) {
  ._aha-table > dl :nth-child(2) :last-child {
    padding-right: em(100);
  }
  ._aha-table > dl :nth-child(3) :last-child {
    padding-right: em(80);
  }
}
@media (max-width: em(430 - 1, 16)) {
  // Shrink logo
  ._aha-table__logo {
    transform: scale(0.667);
    transform-origin: 100% 33.333%;
  }
  // Pad first row
  ._aha-table > dl :nth-child(2) :last-child {
    padding-right: em(70);
  }
}
