// Custom Flickity dots
.o-carousel .flickity-page-dots {
  display: flex;
  justify-content: flex-end;
  margin: em(20) 0 0;
  height: 12px;

  .dot {
    $color: $color-brand-gold;
    $size: 10px;
    width: $size;
    height: $size;
    margin: 0 em(5);
    transform: rotate(45deg);
    border-radius: 0;  // override flickity
    background: $color;
    cursor: pointer;
    opacity: 0.5;

    @include hover() {
      opacity: 0.75;
    }

    &.is-selected {
      border: 2px $color solid;
      background: none;
      cursor: default;
      opacity: 1;
    }
  }
}

// HACK: Hide secondary images on load (assumes all are figures)
.o-carousel > figure:not(:first-child) {
  position: absolute;
  left: -999em;
}
