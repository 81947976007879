.c-view-nav__item {
  font-style: italic;
  text-decoration: none;

  &.c-view-nav__previous::before {
    content: '‹ ';
  }

  &.c-view-nav__next::after {
    content: ' ›';
  }
}

.c-view-nav__item-divider {
  margin: 0 em(8);
  color: $color-brown-3;
}
