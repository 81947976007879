@include o-grid();
@include o-grid__item();
@include o-grid('md');
@include o-grid('1040');
@include o-grid('1024');
// TODO: Replace grid item defaults with utilities
.o-grid__item {
    display: block;
    position: relative;
}
// Gutters
@include o-grid--gutters('xxs', $gutter-xxs);
@include o-grid--gutters('xs', $gutter-xs);
@include o-grid--gutters('sm', $gutter-sm);
@include o-grid--gutters('md', $gutter-md);
@include o-grid--gutters('lg', $gutter-lg);
@include o-grid--gutters('xl', $gutter-xl);
@include o-grid--gutters('sm', $gutter-sm, 'md');
@include o-grid--gutters('0', 0);

// TODO: Replace with utility

.o-grid--center {
    justify-content: center;
}

/**
 * Reverse grid.
 * @param  {String}  $at-breakpoint
 */
@mixin o-grid--reverse($at-breakpoint: null) {
    $class-name: 'o-grid--reverse';
    @include breakpoints($class-name, $at-breakpoint) {
        flex-direction: row-reverse;
    }
}
// Reverse
@include o-grid--reverse();
@include o-grid--reverse('sm');
/**
 * Grid with inner borders (and vertical).
 * Based on http://codepen.io/dalgard/pen/Dbnus?editors=0100
 *
 * Note: Requires an extra div with overflow hidden to hide the right borders:
 * ```
 * <div class="u-padding-horizontal-md">
 *     <div class="o-section  /  u-overflow-hidden">
 *         <div class="o-grid  o-grid--inner-border">
 *             ...
 *         </div>
 *     </div>
 * </div>
 * ```
 */
@mixin o-grid--inner-border($size: $border-width-md) {
    margin: 0 (-$size) 0 0;
    border-top: $size solid $color-brand-red;

    > .o-grid__item {
        border-right: $size solid $color-brand-red;
        border-bottom: $size solid $color-brand-red;
    }
}

.o-grid--inner-border {
    @include o-grid--inner-border($border-width-md);
    // TODO: Refactor into an `@breakpoint` modifier
    @include mq('lt-md') {
        border-width: $border-width-sm;

        > .o-grid__item {
            border-width: $border-width-sm;
        }
    }
}
// TODO: Refactor or rename
.o-grid--inner-border--sm {
    // @include o-grid--inner-border(1px);
    $border-width: 1px;
    $border: $border-width $color-brand-red solid;
    padding: 0;
    border: $border;
    border-width: $border-width 0;

    > .o-grid__item {
        border-top: $border;

        &:first-child {
            border-top: 0;
        }
    }
    // scss-lint:disable DeclarationOrder
    @include mq('620') {
        > .o-grid__item {
            border-top: 0;

            + .o-grid__item {
                border-left: $border;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
    // scss-lint:enable DeclarationOrder
}

.gold-grid {
    background-color: rgba(138, 116, 73, .1);

    svg {
        height: 67px;
    }

    &.u-width-1\/1{
      margin:.5% 0;
    }
    @include mq('sm'){
    &.u-width-1\/2\@sm {
      width: 48%;
      margin: 0.5%;
    }
  }
    @include mq('md'){
    &.u-width-1\/3\@md {
      width: 30.5%;
      margin: 0.5%;
    }
  }
    @include mq('lg'){
    &.u-width-1\/4\@lg {
      width: 22.75%;
      margin: 0.5%;
    }
  }
}
