.c-video-badge {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: em(5) em(4);
  color: $color-brand-gold;
  z-index: 1;
}

.c-video-badge::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: em(45) solid $color-black-2;
  border-left: em(53) solid transparent;
  content: '';
  opacity: 0.8;
  z-index: -1;
}

.c-video-badge__icon {
  display: block;
  width: em(23);
  height: em(14);
}
