.contact {
  main{
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/mobile-dark-map-short.webp'));
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    padding-top:scale-vw-mobile(42px);
    @include mq('md'){
        background-position: right -3%;
      background-image: url(cache-ts-url('/img/pinnacle/backgrounds/map.webp'));
      padding-top:scale-1200(141px);
    }

  }
  #m-wrapper {
    padding-top: var(--header-height);
    @include mq('lt-md'){
      background-image: url(cache-ts-url('/img/pinnacle/backgrounds/page-bg-map.webp'));
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 164%;
    }

    .section-header--primary-short{
      padding-bottom:scale-1200(60px);
      .section-header__copy{
        @include mq('lt-lg'){
          margin-bottom:scale-vw-mobile(28px);
        }
      }
      .section-header__disclaimer{
        p:first-of-type{
          @include sans-serif--md();
          letter-spacing: 1.25px;
          padding-bottom:8px;
        }
        p{
          @include body--sm();
        }
      }
    }
    .o-sidebar-layout {
      align-items: start;
      padding-top:0;
      .content-header {
        @include mq('lg'){
        grid-column: span 2;
        }
        h2 {
          @include heading--lg();
          color: $color-brand-gold;
          margin-bottom:scale-1200(10px);
          text-transform:none;
          font-variant: initial;
        }

        p {
          @include body--lg();
          line-height: 1.4em !important;
        }

      }

      .content-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 50px;
        @include mq('lg'){
          grid-template-columns: 1fr 1fr;
          grid-row-gap: 5vw;
          grid-column-gap: 8vw;
        }
        h3 {
          @include heading--sm();
          color: $color-brand-gold;
        }

        .blurb {
          padding: scale-vw-mobile(10px) 0 scale-vw-mobile(30px) 0;
          @include mq('lg'){
          padding: scale-1200(25px) 0 scale-1200(40px) 0;
          }
          @include body--sm();
        }

        a {
          display: inline-flex;

        }
      }

      .o-sidebar-layout__sidebar {
        display: grid;
        grid-row-gap: 2vw;
        grid-template-columns: 1fr;
        justify-items: center;
        @include mq('lg'){
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 7vw;
          justify-items: unset;
          align-content: flex-start;
        }
        @include mq('lt-lg'){
          .content-header{
            padding-bottom:36px;
          }
        }
        .o-grid__item {
          color: $color-brand-gold;
          @include sans-serif--lg();
          a{
            flex-direction: row;
            display: flex;
            align-items: center;
            text-decoration:none;
          }
          .c-icon{
            background-color: $color-burnt-cacao--50-opacity;
            border-radius: 50%;
            height: 50px;
            margin-right: 20px;
            width: 50px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content:center;

            svg{
              max-height:27px;
              max-width:30px;
            }
          }
        }
      }
    }

    .grid--contact{
      display:grid;
      grid-template-columns:1fr;
      // grid-template-rows: 44fr 25fr 25fr;

      grid-row-gap: scale-vw-mobile(18px);
      margin: 0 scale-vw-mobile(24px) scale-vw-mobile(69px) scale-vw-mobile(24px);

      @include mq('md'){
        display:grid;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        grid-template-columns:1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin: 0 scale-1200(136px) scale-1200(69px) scale-1200(136px);
      }

      .full, .half{


        h2{
          @include body--xl();
        }
        p{
          @include body--md();
          padding:scale-vw-mobile(22px) 0 scale-vw-mobile(18px) 0;
          @include mq('md'){
          padding:scale-1200(10px) 0 scale-1200(15px) 0;
          }
        }
        picture{
          z-index:0;
        }
        img{
          position: absolute;
          bottom: 0;
          z-index:0;
          top:50%;
          max-width:none;
          left:50%;
          right:0;
          transform:translate(-50%, -50%);
        }

        a{
          .btn__content::after{
            border:0;
          }
        }

        .grid--copy{
          position:relative;
          z-index:1;
          max-width: 269px;
          margin: 0 0 scale-vw-mobile(18px) scale-vw-mobile(18px);

          @include mq('md'){
            max-width: 367px;

          }
        }
      }
      .full{
        min-height: 439px;
        overflow:hidden;

        .grid--copy{
          margin: scale-vw-mobile(287px) 0 scale-vw-mobile(18px) scale-vw-mobile(18px);
        }
      }
      .half{
        min-height:256px;
        overflow:hidden;

        .grid--copy{
          margin: scale-vw-mobile(122px) 0 scale-vw-mobile(18px) scale-vw-mobile(18px);
        }
      }
      @include mq('md'){
      .full{
        grid-row: 1 / span 1;
        grid-column: 1 / span 2;
        min-height:452px;

        .grid--copy{
          margin: scale-1200(254px) 0 scale-1200(41px) scale-1200(50px);
        }
      }

      .half {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
        min-height:452px;

        .grid--copy{
          margin: scale-1200(274px) 0 scale-1200(41px) scale-1200(50px);
        }
        &:last-of-type{
          grid-column: 2 / span 1;
        }
      }
    }

    }
  }

  .o-sidebar-layout__sidebar{

    p{
      width:86vw;
      margin:0 auto;
    }
    padding-bottom:scale-1200(152px);
    .content-header{
      @include body--xl();
      text-align:center;
      padding-bottom: scale-1200(48px);
    }

    .social{
      display:flex;
      align-items:center;
      justify-content: center;

      .social-nav {

        gap: 20px;

        a {
          &:hover {
            color: $color-brand-gold;
          }
        }

        .social-pinterest {
          width: scale-1200(32px);
          height: scale-1200(32px);
        }

        .social-instagram {
          width: scale-1200(32px);
          height: scale-1200(32px);
        }

        .social-facebook {
          width: scale-1200(19.546px);
          height: scale-1200(31.994px);
        }

        .social-x {
          width: scale-1200(30px);
          height: scale-1200(30px);
        }

        .social-tiktok {
          width: scale-1200(32px);
          height: scale-1200(32px);
        }
      }
    }
  }


  .accordion--container{
    margin: 0 scale-vw-mobile(24px);

    @include mq('md'){
      margin: 0 scale-1200(136px);
    }
  }

}
